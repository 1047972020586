import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  Appliance,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';
import * as ApplianceDetailsActions from './appliance-details.actions';

export interface AppliancesState extends EntityState<Appliance> {
  remoteState: RemoteData<null>;
  selectedAppliance?: Appliance;
  correlationId: string | undefined;
}

export const APPLIANCES_KEY = 'appliances';

export const appliancesAdapter: EntityAdapter<Appliance> = createEntityAdapter<Appliance>(
  {
    selectId: (appliance) => appliance.code,
  }
);

export const initialState: AppliancesState = appliancesAdapter.getInitialState({
  remoteState: remoteDataNotFetched(),
  selectedAppliance: undefined,
  correlationId: undefined,
});

export const appliancesReducer = createReducer(
  initialState,
  on(ApplianceDetailsActions.loadAppliances, (state) => ({
    ...state,
    remoteState: remoteDataLoading(),
  })),

  on(
    ApplianceDetailsActions.loadAppliancesSuccess,
    (state, { appliances, correlationId }) =>
      appliancesAdapter.setAll(appliances, {
        ...state,
        remoteState: remoteDataOK(null),
        correlationId,
      })
  ),

  on(ApplianceDetailsActions.loadAppliancesFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  })),

  on(ApplianceDetailsActions.applianceSelected, (state, { code }) => {
    return {
      ...state,
      selectedAppliance: state.entities[code],
    };
  })
);
