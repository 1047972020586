import { GoogleTagConfig } from '@common/util-models';

export const gaTagsConfig: GoogleTagConfig = {
  config: {
    showLegacyTags: true,
  },
  applianceDetailsLandingTags: {
    genericpagename: 'goal-search',
    category: 'sales-quote',
    pagename: 'get-protected-appliance-details',
    event: 'salesGA',
    eventSales: 'ApplianceDetailsLanding',
  },
  quoteLandingTags: {
    event: 'salesGA',
    eventSales: 'eecCheckoutButton',
    pagename: 'quote-gen',
    genericpagename: 'quote-gen',
  },
  checkoutPersonalDetails: {
    event: 'salesGA',
    pagename: 'policy-details-guest',
    genericpagename: 'checkout',
    category: 'sales-quote',
    checkoutOption: 'normal',
    eventSales: 'eecCheckout',
  },
  applianceFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceDetails',
    pagename: 'get-protected-appliance-details',
  },
  brandFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceDetails',
    pagename: 'get-protected-appliance-details',
  },
  workingOrderFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GoodWorkingOrder',
  },
  purchaseDateFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'PurchaseDate',
  },
  underGuaranteeFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceGuarantee',
  },
  purchasePriceFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'PurchasePrice',
  },
  onApplianceFormSubmission: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GetQuote',
  },
  invalidApplianceFormSubmission: {
    event: 'salesGA',
    eventSales: 'errorForm',
    pagename: 'get-protected-appliance-details-error',
    eventCategory: 'Form - GoalSearch',
    eventAction: 'error',
  },
  personalDetailsFieldCompletionData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'completed',
  },
  personalDetailsCityCompletionData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
  },
  marketingPreferencesData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'marketing-preferences',
  },
  planDocumentPreferencesData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'document plan',
    eventLabel: 'post',
  },
  checkoutSelectPaymentCommonData: {
    event: 'salesGA',
    eventSales: 'payment-options',
    pagename: 'payment-options',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  checkoutDirectDebitCommonData: {
    event: 'salesGA',
    eventSales: 'eecCheckout',
    pagename: 'payment',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  checkoutCardCommonData: {
    event: 'salesGA',
    eventSales: 'eecCheckout',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  paymentDetailsFormFieldCompletion: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - payment',
    eventAction: 'completed',
  },
  paymentDetailsFormBuyNowData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutFormCTA',
    eventCategory: 'Form - payment',
    eventAction: 'buy now',
  },
  orderConfirmationTaggingData: {
    event: 'salesGA',
    eventSales: 'eecPurchase',
    pagename: 'order-confirmation',
    genericpagename: 'order-confirmation',
    category: 'sales-quote',
  },
  inclusionExclusionsData: {
    eventCategory: 'quote-summary',
    eventAction: 'quote tab clicked',
  },
};
