<div class="dialog">
  <div class="dialog__content flex">
    <div class="dialog__content--icon">
      <div class="dialog__icon"><span class="icon icon-alert"></span></div>
    </div>
    <div class="dialog__content--main">
      <h4 class="dialog__content--title pb-1">
        Sorry, there was a problem signing you in
      </h4>
      <p class="dialog__content--body">
        Don't worry, you can try again or continue as a guest and add your plan
        to your account later.
      </p>
    </div>
  </div>

  <div class="dialog__footer">
    <button class="btn btn--primary mt-2" (click)="handleClose()">
      <span class="label">Try again</span>
      <i class="icon icon-arrow-right"></i>
    </button>
    <button class="btn btn--secondary mt-1 mb-1" (click)="handleContinue()">
      <span class="label">Continue as guest</span>
    </button>
  </div>
</div>
