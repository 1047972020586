import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  RadioDef,
  YesNoDef,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

@Component({
  selector: 'dgx-dfb-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor {
  @Input() field!: RadioDef | YesNoDef;

  value: string | undefined;

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.value = this.field?.initialValue;
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string, emitAnalytics: boolean = false) {
    this.value = val;
    super.writeValue(val, emitAnalytics);
  }

  handleClick(event: Event) {
    event.stopPropagation();
  }
}
