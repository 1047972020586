import { createReducer, on, Action } from '@ngrx/store';
import {
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';

import * as CardPaymentActions from './card-payment.actions';

export const CARD_PAYMENT_FEATURE_KEY = 'cardPayment';

export interface CardPaymentState {
  remoteStateSetupCardPayment: RemoteData<null>;
  cardPaymentOrderNumber: string | undefined;
  cardPaymentProviderMetadata: string | undefined;
}

export interface CardPaymentPartialState {
  readonly [CARD_PAYMENT_FEATURE_KEY]: CardPaymentState;
}

export const initialState: CardPaymentState = {
  remoteStateSetupCardPayment: remoteDataNotFetched(),
  cardPaymentOrderNumber: undefined,
  cardPaymentProviderMetadata: undefined,
};

const cardPaymentReducer = createReducer(
  initialState,
  on(CardPaymentActions.setupCardPayment, (state) => ({
    ...state,
    remoteStateSetupCardPayment: remoteDataLoading(),
  })),
  on(CardPaymentActions.cardPaymentResolverEnter, (state) => ({
    ...state,
    remoteStateSetupCardPayment: remoteDataNotFetched(),
    cardPaymentOrderNumber: undefined,
    cardPaymentProviderMetadata: undefined,
  })),
  on(
    CardPaymentActions.setupCardPaymentSuccess,
    (state, { cardPaymentSetupInformation }) => ({
      ...state,
      remoteStateSetupCardPayment: remoteDataOK(null),
      cardPaymentOrderNumber: cardPaymentSetupInformation.order_number,
      cardPaymentProviderMetadata: cardPaymentSetupInformation.url,
    })
  ),
  on(CardPaymentActions.setupCardPaymentFailure, (state, { error }) => ({
    ...state,
    remoteStateSetupCardPayment: remoteDataError(error),
    cardPaymentOrderNumber: undefined,
    cardPaymentProviderMetadata: undefined,
  }))
);

export function reducer(state: CardPaymentState | undefined, action: Action) {
  return cardPaymentReducer(state, action);
}
