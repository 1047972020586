import { MarketingPreferences } from '@domgen/dgx-fe-business-models';

export interface CorrespondenceAddress {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postalCode: string;
  countryCode: string;
}
export interface Contact {
  mobilePhoneNumber?: string;
  landlinePhoneNumber?: string;
  email?: string;
}

export interface Details {
  initial?: string;
  firstName?: string;
  surname: string;
  title: string;
  gender?: string;
}

export interface Person {
  isLandlord: boolean;
  correspondenceAddress: CorrespondenceAddress;
  marketingPreferences?: MarketingPreferences;
  contact: Contact;
  personalDetails: Details;
}

export interface BankDetails {
  nominatedPerDay?: number;
  accountName: string;
  bank: string;
  sortCode: string;
  accountNo: string;
  iban: string;
}

export interface DirectDebitDetails {
  bankDetails: BankDetails;
  paymentToken: string | undefined | null;
}

export enum PreferredContact {
  Email = 'EMAIL',
  Post = 'POST',
}
