import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CheckoutCmsData,
  QuoteSummaryConfigInput,
  QuoteSummaryDataInput,
} from '@common/util-models';

@Component({
  selector: 'sales-checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrls: ['./checkout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutHeaderComponent {
  @Input() quoteSummary: QuoteSummaryDataInput | null = null;
  @Input() quoteSummaryConfig: QuoteSummaryConfigInput | null = null;
  @Input() cmsData: CheckoutCmsData | null = null;
}
