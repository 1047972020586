import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuotesEffects } from './+state/quotes.effects';
import { QuotesFacade } from './+state/quotes.facade';
import * as fromQuotes from './+state/quotes.reducer';
import { QuotesApiService } from './services/quotes-api.service';
import { QuotesMapperService } from './services/quotes-mapper.service';
import { SelectCoverTypeFromWhitelistGuard } from './services/select-cover-type-from-white-list.guard';
import { SelectQuotePaymentOptionsFacade } from './+state/select-quote-payment-options.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromQuotes.QUOTES_FEATURE_KEY, fromQuotes.reducer),
    EffectsModule.forFeature([QuotesEffects]),
  ],
  providers: [
    QuotesFacade,
    SelectQuotePaymentOptionsFacade,
    QuotesApiService,
    SelectCoverTypeFromWhitelistGuard,
    QuotesMapperService,
  ],
})
export class CommonDataAccessQuotesModule {}
