/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { isDevMode } from '@angular/core';
@Injectable()
/**
 * prevents modules from loading in production (useful for pages used only during development)
 */
export class DevModeGuardService implements CanLoad {
  canLoad(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: Route,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return isDevMode();
  }
}
