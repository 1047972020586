export const DEFAULT_ENVIRONMENT = {
  header: {
    links: {
      home: 'https://www.domesticandgeneral.com',
      repair: 'https://www.domesticandgeneral.com/repairs',
      faq: 'https://www.domesticandgeneral.com/content/help-advice-section/faq',
    },
  },
  footer: {
    links: {
      contact_us: 'https://www.domesticandgeneral.com/content/contact-2',
      terms: 'https://www.domesticandgeneral.com/web-terms-conditions',
      privacy: 'https://www.domesticandgeneral.com/privacy-cookies-policy',
      accessibility: 'https://www.domesticandgeneral.com/accessibility',
    },
  },
  dd_guarantee: 'https://www.domesticandgeneral.com/direct-debit-guarantee',
  catalogueStore: 'DandGUK',
  cookieProSrc: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
  action: 'QandB',
  quotePage: '/quote',
  checkoutLandingPage: '/checkout',
  checkoutOrderConfirmationPage: '/checkout/order-confirmation',
  applianceDetailsPage: '/products',
  checkoutPersonalDetailsPage: '/checkout/personal-details',
  checkoutDirectDebitPage: '/checkout/direct-debit-payment',
  checkoutCardPaymentPage: '/checkout/card-payment',
  checkoutLoggedInUserPage: '/checkout/logged-in',
  selectPaymentPage: '/checkout/select-payment',
  forgotPasswordPage: '/forgot-password',
  cms_config: {
    pagesRoot: '/dgx-oren',
    insideCmsEditor: false,
    logCmsRequests: false,
    useCachedCmsOutput: false,
    useFallbackCmsContent: false,
    cachedRouteBase: 'assets/cached-cms/pages',
    cachedContentAppBase: 'assets/cached-cms/content-apps/',
  },
  trustPilot: {
    businessUnitId: '4cb3113600006400050dfb02',
    templates: [
      {
        id: '5419b6ffb0d04a076446a9af',
        name: 'micro',
      },
      {
        id: '54ad5defc6454f065c28af8b',
        name: 'slider',
      },
    ],
  },
  cardPayment: {
    description: 'Sales',
    transactionSource: 'W',
    transactionType: 'W',
  },
  retryBackOffConfig: {
    maxRetries: 1,
    initialInterval: 3000,
    maxInterval: 3000,
  },
  postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
  postcodeServiceFind:
    'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
  postcodeServiceRetrieve:
    'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  worldpay: {
    customisation: {
      fonts: {
        family: ['VAG Rounded LT Pro Thin'],
      },
      color: '#757575',
      buttons: {
        backgroundColor: '#0a93d3',
        color: '#fff',
        border: {
          style: 'solid',
          color: '#0a93d3',
          width: '5px',
          radius: '2px',
        },
        hover: {
          backgroundColor: '#0071bb',
          border: {
            color: '#0071bb',
          },
        },
      },
      inputs: {
        backgroundColor: '#ffffff',
        border: {
          style: 'solid',
          width: '1px',
          radius: '2px',
          color: '#757575',
        },
        placeholder: {
          color: '#001',
        },
        validation: {
          ok: {
            color: '#228415',
          },
          error: {
            color: '#af2c2b',
          },
        },
      },
      page: {
        backgroundColor: '#fff',
        color: '#333',
        border: {
          style: 'solid',
          color: '#ddd',
          width: '1px',
          radius: '2px',
        },
      },
    },
  },
};
