import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  Basket,
  BasketItem,
  CoverType,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';
import * as QuotesActions from './quotes.actions';
import { ApplianceDetailsActions } from '@common/data-access-appliance-details';
export const QUOTES_FEATURE_KEY = 'quotes';

export interface QuotesState
  extends Partial<Omit<Basket, 'items'>>,
    EntityState<BasketItem> {
  remoteState: RemoteData<null>;
  selectedCoverType: CoverType;
  initialCoverType: CoverType;
  selectedExcess: number;
  enableAutoSelectCover?: boolean;
}

export interface QuotesPartialState {
  readonly [QUOTES_FEATURE_KEY]: QuotesState;
}

export const quotesAdapter: EntityAdapter<BasketItem> = createEntityAdapter<BasketItem>(
  {
    selectId: (basketItem) => basketItem.itemId,
  }
);

export const initialState: QuotesState = quotesAdapter.getInitialState({
  remoteState: remoteDataNotFetched(),
  basketId: undefined,
  created: undefined,
  status: undefined,
  updated: undefined,
  digitalId: undefined,
  expiry: undefined,
  initialCoverType: CoverType.Standard,
  selectedCoverType: CoverType.Standard,
  enableAutoSelectCover: false,
  selectedExcess: 0,
  config: {
    showAllCoverTypes: true,
    coverTypeUpgrades: {},
    coverTypesWhitelist: false,
  },
});

const quotesReducer = createReducer(
  initialState,

  on(ApplianceDetailsActions.createQuote, (state) => ({
    ...state,
    remoteState: remoteDataLoading(),
    enableAutoSelectCover: true,
  })),

  on(QuotesActions.createQuoteSuccess, (state, { quoteResponse: { basket } }) =>
    quotesAdapter.setAll(basket.items, {
      ...state,
      ...pluckBasketPropertiesForState(basket),
      remoteState: remoteDataOK(null),
    })
  ),

  on(QuotesActions.createQuoteFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  })),

  on(QuotesActions.selectCoverType, (state, { coverType }) => ({
    ...state,
    selectedCoverType: coverType,
  })),

  on(
    QuotesActions.selectFirstCoverType,
    QuotesActions.autoSelectCoverType,
    (state, { coverType }) => ({
      ...state,
      initialCoverType: coverType,
      selectedCoverType: coverType,
      enableAutoSelectCover: false,
    })
  ),

  on(QuotesActions.selectExcessValue, (state, { excessValue }) => ({
    ...state,
    selectedExcess: excessValue,
  }))
);

export function reducer(state: QuotesState | undefined, action: Action) {
  return quotesReducer(state, action);
}

function pluckBasketPropertiesForState(basket: Basket) {
  return {
    basketId: basket.basketId,
    created: basket.created,
    digitalId: basket.digitalId,
    status: basket.status,
    expiry: basket.expiry,
    updated: basket.updated,
    requestSource: basket.requestSource,
  };
}
