import { Component, Input } from '@angular/core';

@Component({
  selector: 'sales-got-a-question',
  templateUrl: './got-a-question.component.html',
  styleUrls: ['./got-a-question.component.scss'],
})
export class GotAQuestionComponent {
  @Input() faqLink!: string;
}
