import { CommonModule } from '@angular/common';
import {
  InjectionToken,
  Injector,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcceptComponent } from './form-elements/accept/accept.component';
import { AddressLookupComponent } from './form-elements/address-lookup/address-lookup.component';
import { CheckboxComponent } from './form-elements/checkbox/checkbox.component';
import { FormElementBaseComponent } from './form-elements/form-element-base.component';
import { InputComponent } from './form-elements/input/input.component';
import { PasswordComponent } from './form-elements/password/password.component';
import { ManualAddressEntryComponent } from './form-elements/manual-address-entry/manual-address-entry.component';
import { MonthYearDateComponent } from './form-elements/month-year-date/month-year-date.component';
import { YearDateComponent } from './form-elements/year-date/year-date.component';
import { RadioComponent } from './form-elements/radio/radio.component';
import { SelectComponent } from './form-elements/select/select.component';
import { SortcodeComponent } from './form-elements/sortcode/sortcode.component';
import { SubmitButtonComponent } from './form-elements/submit-button/submit-button.component';
import { ToggleSwitchComponent } from './form-elements/toggle-switch/toggle-switch.component';
import { TypeaheadComponent } from './form-elements/typeahead/typeahead.component';
import { YesNoComponent } from './form-elements/yes-no/yes-no.component';
import { FormComponent } from './form/form.component';
import { TooltipComponent } from './_shared/components/tooltip/tooltip.component';
import { ValidationErrorMessageComponent } from './_shared/components/validation-error-message/validation-error-message.component';
import { NumericMaskDirective } from './_shared/directives/number-mask/number-mask.directive';
import { TooltipTargetDirective } from './_shared/directives/tooltip/tooltip-target.directive';
import { TooltipDirective } from './_shared/directives/tooltip/tooltip.directive';
import { FormbuilderConfig } from './_shared/interfaces/form-builder-config.interface';
import { AddressLookupService } from './_shared/services/address-lookup.service';
import { FormServicesModule } from './_shared/services/form-services.module';
import { FormbuilderConfigService } from './_shared/services/formbuilder-config.service';
import {
  formbuilderConfigToken,
  providedFormbuilderConfigToken,
} from './_shared/tokens/formbuilder-config.token';
import { PasswordStrengthCheckerComponent } from './form-elements/password/password-strength-checker/password-strength-checker.component';

export const components = [
  FormComponent,
  CheckboxComponent,
  ToggleSwitchComponent,
  AcceptComponent,
  SubmitButtonComponent,
  ValidationErrorMessageComponent,
  FormElementBaseComponent,
  RadioComponent,
  MonthYearDateComponent,
  YearDateComponent,
  YesNoComponent,
  InputComponent,
  PasswordComponent,
  PasswordStrengthCheckerComponent,
  SelectComponent,
  TooltipDirective,
  TooltipTargetDirective,
  TooltipComponent,
  NumericMaskDirective,
  TypeaheadComponent,
  AddressLookupComponent,
  ManualAddressEntryComponent,
  SortcodeComponent
];

export function createFormbuilderConfig(
  injector: Injector,
  config: InjectionToken<FormbuilderConfig> | FormbuilderConfig
) {
  return config instanceof InjectionToken ? injector.get(config) : config;
}

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormServicesModule],
  declarations: [...components],
  exports: [...components],
})
export class SharedUiDynamicFormBuilderModule {
  static forRoot(
    config: InjectionToken<FormbuilderConfig> | FormbuilderConfig
  ): ModuleWithProviders<SharedUiDynamicFormBuilderModule> {
    return {
      ngModule: SharedUiDynamicFormBuilderModule,
      providers: [
        AddressLookupService,
        FormbuilderConfigService,
        {
          provide: providedFormbuilderConfigToken,
          useValue: config,
        },
        {
          provide: formbuilderConfigToken,
          deps: [Injector, providedFormbuilderConfigToken],
          useFactory: createFormbuilderConfig,
        },
      ],
    };
  }
}
