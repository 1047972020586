import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageData } from '@common/util-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private errorPageDataSubject = new BehaviorSubject<Partial<ErrorPageData>>(
    ({} as unknown) as ErrorPageData
  );
  errorPageData$ = this.errorPageDataSubject.asObservable();

  constructor(private router: Router) {}

  handleError(errorPageData?: Partial<ErrorPageData>) {
    this.errorPageDataSubject.next(errorPageData ?? {});
    this.router.navigate(['/error']);
  }
}
