import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorService } from '@common/util-foundation';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppliancesApiService } from '../services/appliances-api.service';
import * as ApplianceDetailsActions from './appliance-details.actions';

@Injectable()
export class ApplianceDetailsEffects {
  loadAppliances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.loadAppliances),
      switchMap(() =>
        this.appliancesService.getAppliancesWithCorrelationId().pipe(
          map(({ appliances, correlationId }) =>
            ApplianceDetailsActions.loadAppliancesSuccess({
              appliances,
              correlationId,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            this.handleError();
            return of(ApplianceDetailsActions.loadAppliancesFailure({ error }));
          })
        )
      )
    )
  );

  applianceSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.applianceSelected),
      map(({ code }) => ApplianceDetailsActions.loadBrands({ code }))
    )
  );

  loadBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.loadBrands),
      switchMap(({ code }) =>
        this.appliancesService.getBrandsForAppliance(code).pipe(
          map((brands) =>
            ApplianceDetailsActions.loadBrandsSuccess({ brands })
          ),
          catchError((error: HttpErrorResponse) => {
            this.handleError();
            return of(ApplianceDetailsActions.loadBrandsFailure({ error }));
          })
        )
      )
    )
  );

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    private actions$: Actions,
    private appliancesService: AppliancesApiService,
    private errorService: ErrorService
  ) {}
}
