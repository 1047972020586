import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import {
  CheckoutRequest,
  CheckoutResponse,
  ValidateDirectDebitDetailsRequest,
  ValidateDirectDebitDetailsResponse,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';

@Injectable()
export class CheckoutApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {}

  validateDirectDebitDetails(
    validateDirectDebitRequest: ValidateDirectDebitDetailsRequest
  ): Observable<ValidateDirectDebitDetailsResponse> {
    const config = this.buildConfigService.config;
    const validateDirectDebitUrl = `${config.common}/directdebit/validate`;
    return this.httpClient.post<ValidateDirectDebitDetailsResponse>(
      validateDirectDebitUrl,
      {
        ...validateDirectDebitRequest,
        request_source: this.buildConfigService.config.catalogueStore,
      }
    );
  }

  checkout(checkoutRequest: CheckoutRequest): Observable<CheckoutResponse> {
    const config = this.buildConfigService.config;
    const checkoutUrl = `${config.basketApi}/checkout`;
    return this.httpClient
      .post<{ result: CheckoutResponse }>(checkoutUrl, checkoutRequest, {
        headers: {
          ...this.getHeaders(),
        },
      })
      .pipe(
        retryBackoff({
          initialInterval: config.retryBackOffConfig.initialInterval,
          maxRetries: config.retryBackOffConfig.maxRetries,
          maxInterval: config.retryBackOffConfig.maxInterval,
        }),
        map((checkoutResponse) => checkoutResponse.result)
      );
  }

  private getHeaders() {
    return {
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }
}
