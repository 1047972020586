<div *ngIf="quote" class="quote-card">
  <h4>{{ heading }}</h4>
  <div class="quote-flex-box quote-summary" *ngIf="!isHeating; else heating">
    <!-- for non-heating appliances -->
    <span class="quote-price">&pound;{{ price }}</span
    >&nbsp;<span>per month continuous cover</span>
  </div>

  <!-- for heating appliances -->
  <ng-template #heating>
    <!-- for direct debit payment -->
    <div *ngIf="isDirectDebitPayment; else isCardPayment">
      <!-- for direct debit payment -->
      <div
        class="quote-summary"
        *ngIf="isAllInstalmentsAreSame; else isNotAllInstalmentAreSame"
      >
        <!-- 12 equal instalments -->
        <div>
          <span class="quote-price">&pound;{{ firstMonthlyPayment }}</span
          >&nbsp;
          <span>per month</span>
        </div>
        <span>{{ quote.cover.periodOfCover }}</span
        >&nbsp;
        <span>months for &pound;{{ price }}</span>
        <div class="quote-price quote-discount">
          Includes &pound;{{ discount }} Direct Debit discount
        </div>
      </div>
      <!-- 1st instalment differnt then subsequent instalments -->
      <ng-template #isNotAllInstalmentAreSame>
        <div class="quote-summary">
          <span class="quote-price quote-actual">&pound;{{ actualPrice }}</span
          >&nbsp; <span class="quote-price">&pound;{{ price }}</span
          >&nbsp;<span>total</span>
          <div>1st payment of &pound;{{ firstMonthlyPayment }} then</div>
          <div>
            {{ quote.cover.periodOfCover - 1 }} monthly payments of &pound;{{
              subsequentPayments
            }}
          </div>
          <div class="quote-price quote-discount">
            Includes &pound;{{ discount }} Direct Debit discount
          </div>
        </div>
      </ng-template>
    </div>
    <!-- card payment -->
    <ng-template #isCardPayment>
      <div class="quote-summary">
        <span class="quote-price">&pound;{{ actualPrice }}</span>
        &nbsp;<span>total</span>
        <div>One-off payment</div>
      </div>
    </ng-template>
  </ng-template>
  <ul>
    <li class="item icon-large-tick">
      <img
        *ngIf="quote?.applianceIcon"
        [src]="quote?.applianceIcon"
        class="appliance-icon"
        alt="{{ quote?.applianceName }}"
      />
      <span class="brand">{{ quote?.brandName }}</span>
      <span class="appliance">{{ quote?.applianceName }}</span>
    </li>
  </ul>
</div>
