import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  isRemoteDataError,
  isRemoteDataNotFetched,
  isRemoteDataOK,
  RemoteData,
} from '@common/util-models';
import { filter, take, tap } from 'rxjs/operators';
import * as ApplianceDetailsActions from '../+state/appliance-details.actions';
import * as ApplianceDetailsSelectors from '../+state/appliance-details.selectors';
import { AppliancesState } from '../+state/appliances.reducer';

@Injectable()
export class AppliancesResolver implements Resolve<unknown> {
  constructor(private store: Store<AppliancesState>) {}

  resolve() {
    return this.store.pipe(
      select(ApplianceDetailsSelectors.getAppliancesLoaded),
      tap((itemsLoadedState) => this.dispatchOnNotFetched(itemsLoadedState)),
      filter((itemsLoadedState) => this.filterSuccessOrError(itemsLoadedState)),
      take(1)
    );
  }

  private dispatchOnNotFetched(itemsLoadedState: RemoteData) {
    if (isRemoteDataNotFetched(itemsLoadedState)) {
      this.store.dispatch(ApplianceDetailsActions.loadAppliances());
    }
  }

  private filterSuccessOrError(itemsLoadedState: RemoteData) {
    if (
      isRemoteDataOK(itemsLoadedState) ||
      isRemoteDataError(itemsLoadedState)
    ) {
      return true;
    }
    return false;
  }
}
