import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { CreateQuoteRequest, CreateQuoteResponse } from '@common/util-models';
import { map } from 'rxjs/operators';
import { QuotesMapperService } from './quotes-mapper.service';

@Injectable()
export class QuotesApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService,
    private quotesMapper: QuotesMapperService
  ) {}

  createQuote(createQuoteParams: CreateQuoteRequest) {
    const config = this.buildConfigService.config;
    const createQuoteUrl = `${config.basketApi}/quote`;
    return this.httpClient
      .post<{ result: CreateQuoteResponse }>(
        createQuoteUrl,
        createQuoteParams,
        {
          headers: {
            ...this.getHeaders(),
          },
        }
      )
      .pipe(
        map((createQuoteResponse) =>
          this.quotesMapper.addCategoryToApplianceDetails(
            createQuoteResponse.result,
            createQuoteParams.applianceCategory
          )
        )
      );
  }

  private getHeaders() {
    return {
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }
}
