<div class="form-group">
  <div class="select">
    <div class="flex">
      <label class="label" [for]="field?.controlName">{{
        field?.label?.text
      }}</label>
      <dgx-dfb-tooltip
        [tooltip]="field?.tooltip"
        tooltipName="tooltip"
        *ngIf="field?.tooltip"
      ></dgx-dfb-tooltip>
    </div>
    <ng-container dgxDfbTooltipTarget tooltipName="tooltip"></ng-container>
    <div class="wrapper">
      <select
        #exampleSelect
        class="form-control"
        [class.select--empty]="value === ''"
        [name]="field?.controlName"
        (change)="onChange(exampleSelect.value)"
        (focus)="onChange(exampleSelect.value)"
        (blur)="onChange(exampleSelect.value)"
        [disabled]="disabled$ | async"
      >
        <option disabled [selected]="!value">
          {{ field?.placeholder }}
        </option>
        <option
          *ngFor="let item of $any(options)"
          [value]="item.value || item"
          [selected]="item.value === value || item === value"
        >
          {{ item.label || item }}
        </option>
      </select>
      <i class="icon icon-arrow-down"></i>
      <i class="icon icon-tick-circle icon--xxs"></i>
      <i class="icon icon-error-circle icon--xxs"></i>
    </div>
  </div>

  <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
  <div *ngIf="field?.hint" class="form-group__hint">{{ field?.hint }}</div>
</div>
