import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as OrderConfirmationPageSelectors from './order-confirmation-page.selectors';
import { UserProfilePartialState } from '@common/data-access-user-profile';

@Injectable()
export class CheckoutSelectPaymentOptionsFacade {
  personalDetails$ = this.store.pipe(
    select(OrderConfirmationPageSelectors.getPersonalDetails)
  );

  constructor(private store: Store<UserProfilePartialState>) {}
}
