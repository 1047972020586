import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-terms-and-conditions-checkbox-text',
  templateUrl: './terms-and-conditions-checkbox-text.component.html',
  styleUrls: ['./terms-and-conditions-checkbox-text.component.scss'],
})
export class TermsAndConditionsCheckboxTextComponent {
  @Input() productInfoUrl: string | undefined = undefined;
}
