<div class="you-are-in-safe-hands">
  <div class="md-row sm-col">
    <!-- Banner -->
    <div class="xs-12 md-6 lg-6 ml-0 no-gutter">
      <img
        class="banner"
        alt="You are in safe hands"
        src="./assets/images/img-son-and-father-with-oven.jpg"
      />
    </div>
    <div class="xs-12 md-6 lg-6 ml-0 mr-0 no-gutter pb-1 stats-container">
      <!-- Outer wrapper -->
      <div class="m-4">
        <h3 class="protect-things">
          Count on us to protect the things you need
        </h3>
        <div class="md-row">
          <!-- Number of UK customers -->
          <div class="xs-12 md-6 lg-6 stats">
            <div>
              <p class="stats-label">Number of UK customers</p>
            </div>
            <div>
              <h4 class="stats-amount mt-1">8m+</h4>
            </div>
          </div>
          <!-- Appliance repairs per year -->
          <div class="xs-12 md-6 lg-6 stats">
            <div>
              <p class="stats-label">Appliance repairs per year</p>
            </div>
            <div>
              <h4 class="stats-amount mt-1">2.4m</h4>
            </div>
          </div>
          <!-- Appliances repaired everyday -->
          <div class="xs-12 md-6 lg-6 stats">
            <div>
              <p class="stats-label">Appliances repaired everyday</p>
            </div>
            <div>
              <h4 class="stats-amount mt-1">6.4k</h4>
            </div>
          </div>
          <!-- Appliances replaced last year -->
          <div class="xs-12 md-6 lg-6 stats">
            <div>
              <p class="stats-label">Appliances replaced last year</p>
            </div>
            <div>
              <h4 class="stats-amount mt-1">500k</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
