import { Inject, Injectable } from '@angular/core';
import {
  CmsConfig,
  ConfigAdapter,
  Environment,
  RuntimeConfig,
} from '@common/util-models';
import { RUNTIME_CONFIG, ENVIRONMENT } from '../tokens';
@Injectable()
export class CmsConfigAdapter implements ConfigAdapter {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(RUNTIME_CONFIG) private runtimeConfig: RuntimeConfig
  ) {}

  getConfig(): { [key: string]: unknown } {
    return {
      cms_config: {
        ...this.environment.cms_config,
        damRawUrl: this.runtimeConfig.cmsDamRawUrl,
        contentAppBase: this.runtimeConfig.cmsContentAppBase,
        restUrlBase: this.runtimeConfig.cmsRestUrlBase,
        templateDefinitionBase: this.runtimeConfig.cmsTemplateDefinitionBase,
      } as CmsConfig,
    };
  }
}
