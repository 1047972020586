import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PersonalDetailsPartialState } from '@common/data-access-personal-details';
import {
  QuotesPageSelectors,
  QuotesPartialState,
  QuotesSelectors,
} from '@common/data-access-quotes';
import { UserProfilePartialState } from '@common/data-access-user-profile';
import { Quote, RemoteData, TaggingMetadata } from '@common/util-models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CardPaymentPartialState } from './card-payment.reducer';
import * as CardPaymentSelectors from './card-payment.selectors';
import { retryPayingByDirectDebit } from '@common/data-access-shared';
import { selectUser } from '@domgen/dgx-fe-auth';

@Injectable()
export class CardPaymentFacade {
  cardPaymentProviderMetadata$ = this.store$.pipe(
    select(CardPaymentSelectors.getCardPaymentProviderMetadata),
    filter((cardPaymentProviderMetadata) => !!cardPaymentProviderMetadata)
  ) as Observable<string>;

  cardPaymentOrderNumber$ = this.store$.pipe(
    select(CardPaymentSelectors.getCardPaymentOrderNumber),
    filter((cardPaymentOrderNumber) => !!cardPaymentOrderNumber)
  ) as Observable<string>;

  cardPaymentSetupRemoteState$ = this.store$.pipe(
    select(CardPaymentSelectors.getCardPaymentSetupRemoteState)
  ) as Observable<RemoteData>;

  quote$ = this.store$.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  taggingMetadata$ = this.store$.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  basketId$ = this.store$.pipe(
    select(QuotesSelectors.getFirstItemQuote),
    filter((basketItem) => !!basketItem),
    map((basketItem) => basketItem?.basketId)
  ) as Observable<string>;

  user$ = this.store$.pipe(select(selectUser));

  constructor(
    private store$: Store<
      PersonalDetailsPartialState &
        QuotesPartialState &
        UserProfilePartialState &
        CardPaymentPartialState
    >
  ) {}

  retryDirectDebitPayment() {
    this.store$.dispatch(retryPayingByDirectDebit());
  }
}
