<div class="form-group form-group__input form-group__sortcode">
  <label [for]="field?.controlName">
    {{ field?.label?.text }}
    <dgx-dfb-tooltip
      [tooltip]="field?.tooltip"
      [tooltipName]="tooltipName"
      *ngIf="field?.tooltip"
    ></dgx-dfb-tooltip>
  </label>
  <ng-container dgxDfbTooltipTarget [tooltipName]="tooltipName"></ng-container>
  <div class="sortcode-wrap">
    <input
      autocomplete="off"
      class="form-control"
      (input)="onInputChange($any($event.target))"
      (blur)="onInputBlur($any($event.target))"
      (mousedown)="onMousedown($any($event.target))"
      (keydown)="onKeyDown($event, $any($event.target))"
      maxlength="2"
      name="sort1"
      #sort1
      type="text"
      [value]="inputs.sort1.value"
      [class.segment-touched]="inputs.sort1.touched"
    />
    <input
      autocomplete="off"
      class="form-control"
      (input)="onInputChange($any($event.target))"
      (blur)="onInputBlur($any($event.target))"
      (mousedown)="onMousedown($any($event.target))"
      (keydown)="onKeyDown($event, $any($event.target))"
      maxlength="2"
      name="sort2"
      #sort2
      type="text"
      [value]="inputs.sort2.value"
      [class.segment-touched]="inputs.sort2.touched"
    />
    <input
      autocomplete="off"
      class="form-control"
      (input)="onInputChange($any($event.target))"
      (keydown)="onKeyDown($event, $any($event.target))"
      (mousedown)="onMousedown($any($event.target))"
      (blur)="onInputBlur($any($event.target))"
      maxlength="2"
      name="sort3"
      #sort3
      type="text"
      [value]="inputs.sort3.value"
      [class.segment-touched]="inputs.sort3.touched"
    />
    <i class="icon icon-tick-circle icon--xxs"></i>
    <i class="icon icon-error-circle icon--xxs"></i>
  </div>
  <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
  <div *ngIf="field?.hint" class="form-group__hint">{{ field?.hint }}</div>
</div>
