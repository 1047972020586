<footer>
  <div class="footer__subsection links__wrapper">
    <div class="container md-row sm-col links">
      <div class="links__group xs-12 md-6 lg-3">
        <h4>Products we protect</h4>
        <a
          href="https://domesticandgeneral.com/shop/en/dg/products/household"
          target="_blank"
          >Household appliances</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/heating"
          target="_blank"
          >Heating</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/products/tv-audio"
          target="_blank"
          >TV & Audio</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/products/camera-mobile"
          target="_blank"
          >Photo & Video devices</a
        >
      </div>
      <div class="links__group xs-12 md-6 lg-3">
        <h4>More from us</h4>
        <a href="/products">Get protected</a>
        <a
          href="https://domesticandgeneral.com/appliance-repairs"
          target="_blank"
          >Book a repair</a
        >
        <a
          href="https://domesticandgeneral.com/corporate/about-us"
          target="_blank"
          >About us</a
        >
        <a
          href="https://domesticandgeneral.com/content/help-advice-section/faq"
          target="_blank"
          >Help and advice</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/content/contact-domestic-general"
          target="_blank"
          >Contact us</a
        >
        <a
          href="https://domesticandgeneral.com/content/help-advice-section/corporate-information/media-centre"
          target="_blank"
          >Media centre</a
        >
        <a href="http://careers.domesticandgeneral.com/" target="_blank"
          >Careers</a
        >
      </div>
      <div class="links__group xs-12 md-6 lg-3">
        <h4>Useful links</h4>
        <a href="https://investors.domesticandgeneral.com/" target="_blank"
          >Investor relations</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/company-information-legal"
          target="_blank"
          >Regulatory information</a
        >
        <a
          href="https://domesticandgeneral.com/content/help-advice-section/corporate-information/charity-partnerships"
          target="_blank"
          >Charity Partnerships</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/modern-slavery"
          target="_blank"
          >Modern Slavery Statement</a
        >
        <a href="https://domesticandgeneral.com/PartVIITransfer" target="_blank"
          >Part VII Transfer</a
        >
        <a
          href="https://domesticandgeneral.com/content/help-advice-section/faq/received-a-strange-call-"
          target="_blank"
          >Rogue Trader Guidance</a
        >
        <a
          href="https://domesticandgeneral.com/customer-conduct"
          target="_blank"
          >Customer Conduct</a
        >
        <a
          href="https://domesticandgeneral.com/content/help-advice-section/faq/coronavirus-covid19-update"
          target="_blank"
          >Coronavirus update</a
        >
      </div>
      <div class="links__group xs-12 md-6 lg-3">
        <h4>Legal</h4>
        <a
          href="https://domesticandgeneral.com/shop/en/dg/web-terms-conditions"
          target="_blank"
          >Website Terms and Conditions</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/privacy-cookies-policy"
          target="_blank"
          >Privacy and Cookies policy</a
        >
        <a
          href="https://domesticandgeneral.com/shop/en/dg/accessibility"
          target="_blank"
          >Accessibility policy</a
        >
      </div>
    </div>
  </div>
  <div class="disclaimers footer__subsection">
    <div class="container">
      <p>
        Service plans, maintenance & support plans are provided by Domestic &
        General Services Limited. Insurance policies are provided by Domestic &
        General Insurance PLC. Domestic & General Insurance PLC is an insurance
        undertaking, not an intermediary. We are the underwriter of the
        insurance policies and do not provide a personal recommendation or
        advice. Domestic & General Insurance PLC is authorised by the Prudential
        Regulation Authority and regulated by the Financial Conduct Authority
        and the Prudential Regulation Authority (Financial Services Register
        Number 202111). Our address and details of our authorisation can be
        checked on the FCA website or by contacting the FCA on 0800 111 6768.
      </p>
      <p>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="_blank"
          >Privacy Policy</a
        >
        and
        <a href="https://policies.google.com/terms" target="_blank"
          >Terms of Service</a
        >
        apply.
      </p>
    </div>
  </div>
  <div class="container">
    <div
      class="bottom footer__subsection md-row xs--stack xs--middle sm--middle"
    >
      <div>
        <p>&copy; {{ currentYear }} Domestic & General Group Limited</p>
      </div>
      <div class="footer__social">
        <a
          href="https://www.facebook.com/DandGUK/"
          class="icon icon--xl icon-facebook footer__social-link"
          target="_blank"
        ></a>
        <a
          href="https://twitter.com/DandG_UK"
          class="icon icon--xl icon-twitter footer__social-link"
          target="_blank"
        ></a>
        <a
          href="https://www.instagram.com/domesticandgeneral/"
          class="icon icon--xl icon-instagram footer__social-link"
          target="_blank"
        ></a>
        <a
          href="https://www.linkedin.com/company/domestic-&-general/mycompany/"
          class="icon icon--xl icon-linkedin footer__social-link"
          target="_blank"
        ></a>
      </div>
    </div>
  </div>
</footer>
