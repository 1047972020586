import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { MonthYearDateDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';
import { BehaviorSubject } from 'rxjs';

interface MonthYearType {
  month: number | undefined;
  year: number | undefined;
}

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-month-year-date',
  templateUrl: './month-year-date.component.html',
  styleUrls: ['./month-year-date.component.scss'],
  providers: [TooltipService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthYearDateComponent
  extends FormElementBaseComponent
  implements OnChanges, OnInit, ControlValueAccessor {
  @Input() field!: MonthYearDateDef;

  valueSubject = new BehaviorSubject<MonthYearType>({
    month: undefined,
    year: undefined,
  });

  value$ = this.valueSubject.asObservable();

  value: MonthYearType = {
    month: undefined,
    year: undefined,
  };
  isInvalid: { month: boolean; year: boolean } = { month: false, year: false };
  isValid: { month: boolean; year: boolean } = { month: false, year: false };

  monthNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  tooltipName = 'tooltip';

  constructor(
    @Optional() public ngControl: NgControl,
    protected formGroupDir: FormGroupDirective
  ) {
    super(ngControl);
  }

  ngOnInit() {
    const control = this.ngControl.control;
    this.formGroupDir.form.statusChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.isInvalid.month =
            !!control?.errors && !control.errors['yearNotSelected'];

          this.isInvalid.year =
            !!control?.errors && !control.errors['monthNotSelected'];
        })
      )
      .subscribe();

    if (this.field?.initialValue) {
      this.valueSubject.next(this.field.initialValue as MonthYearType);
      this.value = this.field.initialValue;
    }
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  initValue() {
    this.value = this.value
      ? this.value
      : {
          month: undefined,
          year: undefined,
        };
  }

  /**
   * Modifies boolean flags that are used to assign 'is-valid' or 'is-invalid' classes
   * to month and year select elements.
   * This validation takes place when either month or year is chosen.
   */
  updateValidity(event: Event) {
    // validation errors
    const errors = this.ngControl.errors;

    // reset validity flags
    this.isInvalid = { month: false, year: false };
    this.isValid = { month: false, year: false };

    // do not put an error class on month if it's year that is NOT selected
    this.isInvalid.month = !!errors && !errors.yearNotSelected;
    // perform a check to avoid having both valid and invalid classes
    if (!this.isInvalid.month) {
      // month is valid when there are no errors (or at least there's no 'month is not selected' error)
      // and it has a value
      this.isValid.month =
        (!errors || !errors.monthNotSelected) && !!this.value.month;
    }

    // year is not valid when there are any validation errors except for 'month is not selected' error
    this.isInvalid.year = !!errors && !errors.monthNotSelected;
    // if year is NOT invalid then we can check whether it can have a green tick
    if (!this.isInvalid.year) {
      // the field has a value and there are no errors (or at least no error saying 'select year')
      this.isValid.year =
        (!errors || !errors.yearNotSelected) && !!this.value.year;
    }

    (event.target as HTMLInputElement).blur();
  }

  saveMonth(event: Event, val: string) {
    this.value.month = parseInt(val, 10); // /item/quote API accepts month as a number
    this.isInvalid.month = false;
    super.writeValue(this.value, false);
    this.updateValidity(event);
    if (this.value.year) {
      this.emitAnalyticsData(this.value);
    }
  }

  saveYear(event: Event, val: string) {
    this.value.year = parseInt(val, 10); // /item/quote API accepts year as a number
    this.isInvalid.year = false;
    super.writeValue(this.value, false);
    this.updateValidity(event);
    if (this.value.month) {
      this.emitAnalyticsData(this.value);
    }
  }
}
