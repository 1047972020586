import { Injectable } from '@angular/core';
import { selectUser } from '@domgen/dgx-fe-auth';
import { select, Store } from '@ngrx/store';
import {
  QuotesPageSelectors,
  QuotesPartialState,
  QuotesSelectors,
} from '@common/data-access-quotes';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import * as UserProfileSelectors from './user-profile.selectors';
import {
  DirectDebit,
  Quote,
  QuoteSummaryDataInput,
  TaggingMetadata,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as UserProfilePageSelectors from './user-profile-page.selectors';
import * as UserProfileActions from './user-profile.actions';
import { UserProfilePartialState } from './user-profile.reducer';

@Injectable()
export class UserProfileFacade {
  quote$ = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  taggingMetadata$ = this.store.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  basketId$ = this.store.pipe(
    select(QuotesSelectors.getFirstItemQuote),
    filter((basketItem) => !!basketItem),
    map((basketItem) => basketItem?.basketId)
  ) as Observable<string>;

  quoteSummary$ = this.store.pipe(
    select(QuotesPageSelectors.getQuoteSummary)
  ) as Observable<QuoteSummaryDataInput>;

  selectedContractType$ = this.store.pipe(
    select(QuotesPageSelectors.getSelectedContractType)
  );

  user$ = this.store.pipe(select(selectUser));

  yourDetails$ = this.store.pipe(
    select(UserProfilePageSelectors.getYourDetails)
  );

  personalDetails$ = this.store.pipe(
    select(UserProfilePageSelectors.personalDetails)
  );

  selectedPayment$ = this.store.pipe(
    select(SelectPaymentSelectors.getSelectedPaymentType)
  );

  paymentDetails$ = this.store.pipe(
    select(UserProfilePageSelectors.getPaymentDetails)
  );

  isPaperlessPlanDocumentPreferenceSet$ = this.store.pipe(
    select(UserProfileSelectors.isPaperlessPlanDocumentPreferenceSet)
  );

  constructor(
    private store: Store<
      UserProfilePartialState & QuotesPartialState & SelectPaymentPartialState
    >
  ) {}

  loginSuccess() {
    this.store.dispatch(UserProfileActions.loginSuccess());
  }

  proceedCheckoutAsGuest() {
    this.store.dispatch(UserProfileActions.checkoutAsGuest());
  }

  saveNewDirectDebitDetails(newDirectDebitDetails: DirectDebit | null) {
    this.store.dispatch(
      UserProfileActions.updateDirectDebit({ newDirectDebitDetails })
    );
  }

  changePaymentDay(paymentDay: number) {
    this.store.dispatch(UserProfileActions.changePaymentDay({ paymentDay }));
  }

  savePlanDocumentPreference(byPost: boolean) {
    this.store.dispatch(
      UserProfileActions.updatePlanDocumentPreference({ paperless: !byPost })
    );
  }
}
