<ng-container *ngIf="quote">
  <div class="appliance-details-card__wrapper">
    <div class="appliance-details-card">
      <div class="appliance-details-card__appliance-title-section">
        <div class="appliance-details-card__appliance-icon-section">
          <img
            src="{{ quote.applianceIcon }}"
            alt="{{ quote.applianceName }}"
            class="appliance-details-card__appliance-icon"
          />
        </div>
        <div class="appliance-details-card__appliance-name-section">
          <span class="appliance-details-card__brand-name"
            >{{ quote.brandName }}
          </span>
          <span class="appliance-details-card__appliance-name">{{
            quote.applianceName
          }}</span>
        </div>
      </div>
      <div class="appliance-details-card__appliance-detail-section">
        <div class="appliance-details-card__appliance-detail">
          <div class="appliance-details-card__detail-text">
            Good working order
          </div>
          <div
            class="appliance-details-card__detail-tick icon icon-tick icon--xs"
          ></div>
        </div>

        <div
          class="appliance-details-card__appliance-detail appliance-details-card__appliance-detail--last"
        >
          <div class="appliance-details-card__detail-text">
            Purchased on {{ quote.purchaseDate | date: 'MMM yyyy' }}
          </div>
          <div
            class="appliance-details-card__detail-tick icon icon-tick icon--xs"
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
