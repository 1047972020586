import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogInModule, ForgotPasswordModule } from '@domgen/dgx-fe-auth';
import { ApplianceDetailsCardComponent } from './appliance-details-card/appliance-details-card.component';
import { BoilerTotalMonthPlanComponent } from './boiler-total-month-plan/boiler-total-month-plan.component';
import { BuyNowQuoteButtonComponent } from './buy-now-quote-button/buy-now-quote-button.component';
import { CheckoutAsGuestComponent } from './checkout-as-guest/checkout-as-guest.component';
import { CheckoutUserLoginComponent } from './checkout-user-login/checkout-user-login.component';
import { CoversExclusionsComponent } from './covers-exclusions/covers-exclusions.component';
import { DocumentsComponent } from './documents/documents.component';
import { MinimumTermComponent } from './minimum-term/minimum-term.component';
import { NeedToKnowComponent } from './need-to-know/need-to-know.component';
import { QuoteCardComponent } from './quote-card/quote-card.component';
import { CommonUiSalesComponentsModule } from './common-ui-sales-components.module';
import { WelcomeBackMessageComponent } from './welcome-back-message/welcome-back-message.component';
import { TrustBoxUiModule } from './trust-box-ui.module';
import { FaqsComponent } from './faqs/faqs.component';
import { FaqComponent } from './faq/faq.component';
import { UpgradePlanProtectionComponent } from './upgrade-plan-protection/upgrade-plan-protection.component';
import { WhatYouWillGetComponent } from './what-you-will-get/what-you-will-get.component';
import { CommonUiWhitelabelComponentsModule } from '@common/ui-whitelabel-components';

@NgModule({
  imports: [
    CommonModule,
    CommonUiSalesComponentsModule,
    CommonUiWhitelabelComponentsModule,
    LogInModule,
    ForgotPasswordModule,
    TrustBoxUiModule,
  ],
  declarations: [
    BuyNowQuoteButtonComponent,
    CoversExclusionsComponent,
    DocumentsComponent,
    ApplianceDetailsCardComponent,
    QuoteCardComponent,
    MinimumTermComponent,
    NeedToKnowComponent,
    CheckoutAsGuestComponent,
    CheckoutUserLoginComponent,
    BoilerTotalMonthPlanComponent,
    WelcomeBackMessageComponent,
    FaqsComponent,
    FaqComponent,
    UpgradePlanProtectionComponent,
    WhatYouWillGetComponent,
  ],
  exports: [
    BuyNowQuoteButtonComponent,
    CoversExclusionsComponent,
    DocumentsComponent,
    ApplianceDetailsCardComponent,
    QuoteCardComponent,
    CheckoutAsGuestComponent,
    CheckoutUserLoginComponent,
    MinimumTermComponent,
    NeedToKnowComponent,
    BoilerTotalMonthPlanComponent,
    WelcomeBackMessageComponent,
    FaqsComponent,
    UpgradePlanProtectionComponent,
    WhatYouWillGetComponent,
  ],
})
export class QuoteUiModule {}
