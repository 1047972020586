<div class="restart-journey-dialog">
  <h3 class="restart-journey-dialog__title">Sorry, we lost you there.</h3>
  <p class="restart-journey-dialog__body">
    It looks like there’s been a technical issue but we’re working to fix it.
  </p>
  <p class="restart-journey-dialog__footer">
    <button class="btn btn--primary" (click)="handleClose()">
      <span class="label">Close and try again</span>
      <i class="icon icon-arrow-right"></i>
    </button>
  </p>
</div>
