import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrenAppComponent } from './components/oren-app/oren-app.component';
import { CommonUiSalesComponentsModule } from '@common/ui-sales-components';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { OrenFeatureShellRoutingModule } from './oren-feature-shell-router.module';
@NgModule({
  imports: [
    CommonModule,
    SharedUiBaseComponentsModule,
    CommonUiSalesComponentsModule,
    OrenFeatureShellRoutingModule,
  ],
  declarations: [OrenAppComponent],
  exports: [OrenAppComponent],
})
export class OrenFeatureShellModule {}
