import { Component, Input } from '@angular/core';
import { CmsDynamicComponentContent } from '@domgen/dgx-fe-content-management';

@Component({
  selector: 'common-cms-thumbnail-bullet-element',
  templateUrl: './thumbnail-bullet-element.component.html',
  styleUrls: ['./thumbnail-bullet-element.component.scss'],
})
export class ThumbnailBulletElementComponent {
  @Input() content!: CmsDynamicComponentContent;
}
