import { Component, Input, OnChanges } from '@angular/core';
import { BasketItemPaymentOption, Quote } from '@common/util-models';
import { PaymentType } from '@common/util-models';

export interface QuoteSummaryData {
  title: string;
  excLevel: string;
  excLevelPrice: number;
  monthlyPrice: number;
  totalPrice: number;
  yearlyPrice: number;
  footerText: string;
}

@Component({
  selector: 'wl-quote-panel',
  templateUrl: './quote-panel.component.html',
  styleUrls: ['./quote-panel.component.scss'],
})
export class QuotePanelComponent implements OnChanges {
  //todo: error if expandable changes after init (or ideally fix panel so it copes with expand button hiding)
  @Input() expandable = true;
  @Input() sticky: 'bottom' | false = false;
  @Input() stickyAutoExpand = false;
  @Input() title = '';
  @Input() quote!: Quote | null;
  @Input() selectedExcessLevel!: number | null;
  @Input() paymentType!: string;
  @Input() isInsurance: boolean | null = false;
  @Input() discountThreshold = 1;
  periodOfCover: number | undefined = 0;
  discount: number | undefined;
  isCard = false;
  DDPaymentOption: BasketItemPaymentOption | undefined;
  cardPaymentOption: BasketItemPaymentOption | undefined;
  isDDPaymentAvailable = true;
  isCardPaymentAvailable = true;
  isSameSubsequentPayment = true;

  ngOnChanges() {
    this.periodOfCover = this.quote?.cover?.periodOfCover;
    this.isCard = this.paymentType === PaymentType.Card;
    this.DDPaymentOption = this.quote?.cover?.paymentOptions?.directDebit;
    this.cardPaymentOption = this.quote?.cover?.paymentOptions?.card;
    this.isDDPaymentAvailable = this.quote?.cover?.paymentOptions?.directDebit
      ? true
      : false;
    this.isCardPaymentAvailable = this.quote?.cover?.paymentOptions?.card
      ? true
      : false;
    this.calculateDiscount();
    this.checkSubsequentPayment();
  }

  calculateDiscount() {
    if (
      this.paymentType === PaymentType.DirectDebit &&
      this.DDPaymentOption?.fee &&
      this.cardPaymentOption?.fee
    ) {
      const diff = this.cardPaymentOption.fee - this.DDPaymentOption.fee;
      if (diff >= this.discountThreshold) {
        this.discount = diff;
      }
    } else {
      this.discount = 0;
    }
  }
  checkSubsequentPayment() {
    if (
      this.paymentType === PaymentType.DirectDebit &&
      this.DDPaymentOption?.firstPayment &&
      this.DDPaymentOption?.subsequentPayment &&
      this.DDPaymentOption.firstPayment !==
        this.DDPaymentOption.subsequentPayment
    ) {
      this.isSameSubsequentPayment = false;
    }
  }
}
