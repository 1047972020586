import { Action, createReducer, on } from '@ngrx/store';
import { PaymentType } from '@common/util-models';
import * as SelectPaymentActions from './select-payment.actions';
import { retryPayingByDirectDebit } from '@common/data-access-shared';
import { QuotesActions } from '@common/data-access-quotes';

export const SELECTPAYMENT_FEATURE_KEY = 'selectPayment';

export interface SelectPaymentState {
  selectedPaymentType: PaymentType | undefined;
}

export interface SelectPaymentPartialState {
  readonly [SELECTPAYMENT_FEATURE_KEY]: SelectPaymentState;
}

export const initialState: SelectPaymentState = {
  selectedPaymentType: undefined,
};

const selectPaymentReducer = createReducer(
  initialState,
  on(SelectPaymentActions.selectPaymentOption, (state, { paymentType }) => ({
    ...state,
    selectedPaymentType: paymentType,
  })),
  on(retryPayingByDirectDebit, (state) => ({
    ...state,
    selectedPaymentType: PaymentType.DirectDebit,
  })),
  on(QuotesActions.createQuoteSuccess, (state) => ({
    ...state,
    selectedPaymentType: PaymentType.DirectDebit,
  }))
);

export function reducer(state: SelectPaymentState | undefined, action: Action) {
  return selectPaymentReducer(state, action);
}
