<div class="container my-4 single-column-limiter">
  <div class="flex md-row xs--stack sm--stack checkout__summary">
    <div class="xs-12 md-6">
      <sales-checkout-safe-fast-simple
        [cmsData]="cmsData"
      ></sales-checkout-safe-fast-simple>
    </div>
    <div class="xs--hidden md--block md-6">
      <wl-quote-summary
        [data]="quoteSummary"
        [config]="quoteSummaryConfig"
      ></wl-quote-summary>
    </div>
  </div>
</div>
