import { createAction, props } from '@ngrx/store';
import { CoverType, CreateQuoteResponse } from '@common/util-models';

export const createQuoteSuccess = createAction(
  '[QuotesEffects] Load Quotes Success',
  props<{
    quoteResponse: CreateQuoteResponse;
  }>()
);

export const createQuoteFailure = createAction(
  '[QuotesEffects] Load Quotes Failure',
  props<{ error: Error }>()
);

export const selectExcessValue = createAction(
  '[QuoteComponent] Select Excess Amount',
  props<{ excessValue: number }>()
);

export const selectCoverType = createAction(
  '[QuoteComponent] Select Cover Type',
  props<{ coverType: CoverType }>()
);

export const selectFirstCoverType = createAction(
  '[QuoteComponent] Select First Cover Type',
  props<{ coverType: CoverType }>()
);

export const autoSelectCoverType = createAction(
  '[AutoSelectCoverTypeGuard] Preselect the initial and selected cover types',
  props<{ coverType: CoverType }>()
);
