import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { CommonUiSalesComponentsModule } from './common-ui-sales-components.module';
import { CommonUiWhitelabelComponentsModule } from '@common/ui-whitelabel-components';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CommonUiSalesComponentsModule,
    CommonUiWhitelabelComponentsModule,
  ],
  declarations: [PaymentOptionsComponent],
  exports: [PaymentOptionsComponent],
})
export class SelectPaymentUiModule {}
