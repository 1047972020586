import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  FEATURE_CONFIG_FEATURE_KEY,
  FeatureConfigState,
} from './feature-config.reducers';
import {
  ApplianceDetailsConfig,
  CheckoutConfig,
  QuotesConfig,
} from '@common/util-models';

export const getFeatureConfigState = createFeatureSelector<FeatureConfigState>(
  FEATURE_CONFIG_FEATURE_KEY
);

export const getApplianceDetailsConfig = createSelector(
  getFeatureConfigState,
  ({
    applianceDetails,
  }: FeatureConfigState): ApplianceDetailsConfig | undefined => applianceDetails
);
export const getCheckoutConfig = createSelector(
  getFeatureConfigState,
  ({ checkout }: FeatureConfigState): CheckoutConfig | undefined => checkout
);
export const getQuotesConfig = createSelector(
  getFeatureConfigState,
  ({ quotes }: FeatureConfigState): QuotesConfig | undefined => quotes
);
export const getRemoteState = createSelector(
  getFeatureConfigState,
  ({ remoteState }: FeatureConfigState) => remoteState
);
