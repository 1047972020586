import { CmsDataUrl, CmsPage } from '@common/util-models';
import {
  mapCheckoutPageCmsData,
  mapCmsFormData,
} from '@common/data-access-shared';

const pageName = 'dgx-forms';

export const cmsDataUrl: CmsDataUrl = {
  [CmsPage.OrenLandingOffer]: {
    id: CmsPage.Appliance,
    url: `${pageName}-offersForm.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.OrenLandingRenewal]: {
    id: CmsPage.Appliance,
    url: `${pageName}-renewalsForm.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.Quote]: {
    id: CmsPage.Quote,
    url: `${CmsPage.Quote}.json`,
  },
  [CmsPage.FAQs]: {
    id: CmsPage.FAQs,
    url: CmsPage.FAQs,
  },
  [CmsPage.Checkout]: {
    id: CmsPage.Checkout,
    url: `dgx-checkout/`,
    mapper: mapCheckoutPageCmsData,
  },
  [CmsPage.CheckoutPersonalDetails]: {
    id: CmsPage.CheckoutPersonalDetails,
    url: `${pageName}-checkoutPersonalDetailsForm.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutDirectDebit]: {
    id: CmsPage.CheckoutDirectDebit,
    url: `${pageName}-checkoutDirectDebitForm.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutSelectPayment]: {
    id: CmsPage.CheckoutSelectPayment,
    url: `${pageName}-checkoutSelectPayment.json`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutLoggedInUser]: {
    id: CmsPage.CheckoutLoggedInUser,
    url: `${pageName}-checkoutLoggedInUser.json`,
  },
};
