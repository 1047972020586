import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorService } from '@common/util-foundation';
import { ErrorPageData } from '@common/util-models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sales-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  defaultErrorPageCopy: ErrorPageData = {
    header: 'Sorry, something’s not right.',
    additionalSupport: '',
    support:
      'It looks like there’s been a technical issue but we’re working to fix it',
    contactCustomerService:
      'Try calling our friendly team to receive your quote on:',
  };

  errorPageData$ = this.errorService.errorPageData$.pipe(
    map((errorPageCopy) => ({ ...this.defaultErrorPageCopy, ...errorPageCopy }))
  );

  constructor(private errorService: ErrorService) {}
}
