<ng-container *ngIf="quote">
  <div class="buy-now container">
    <button class="btn btn--primary buy-now__button" (click)="handleClick()">
      <div class="buy-now__button-text-section">
        <span class="buy-now__button-text buy-now__button-text--primary"
          >Buy now</span
        >
        <span class="buy-now__button-text buy-now__button-text--secondary fee">
          &pound;{{ fee | number: '1.2-2' }}
          <ng-container *ngIf="isDDPaymentAvailable">per month</ng-container>
        </span>
      </div>
      <i class="icon icon-arrow-right"></i>
    </button>

    <div class="buy-now__payment-section">
      <p class="buy-now__direct-debit-message">
        Fast & secure payment
        <span
          *ngIf="!isHeating && isDDPaymentAvailable"
          class="logo icon-direct-debit inline"
          title="Direct Debit"
        ></span>
      </p>
      <ng-container *ngIf="isHeating">
        <wl-payment-icons
          [showDirectDebitIcon]="isDDPaymentAvailable"
          [showCardIcons]="isCardPaymentAvailable"
        ></wl-payment-icons>
      </ng-container>
    </div>
  </div>
</ng-container>
