<div class="direct-debit-guarantee">
  <div class="direct-debit-guarantee__wrapper guarantee content-box">
    <h4>{{ title }}</h4>
    <ng-content></ng-content>
    <p>
      By submitting your payment details, you are confirming that you are the
      account holder and no authorisation other than your own is required.
    </p>
    <p class="direct-debit-guarantee__link">
      <a
        href="https://www.domesticandgeneral.com/direct-debit-guarantee"
        target="_blank"
        class="link--standalone"
        >The Direct Debit Guarantee</a
      >
    </p>
  </div>
</div>
