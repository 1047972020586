import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromPersonalDetails from '../../../data-access-personal-details/src/lib/+state/personal-details.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PersonalDetailsEffects } from './+state/personal-details.effects';
import { PersonalDetailsFacade } from './+state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPersonalDetails.PERSONAL_DETAILS_FEATURE_KEY,
      fromPersonalDetails.personalDetailsReducer
    ),
    EffectsModule.forFeature([PersonalDetailsEffects]),
  ],
  providers: [PersonalDetailsFacade],
})
export class CommonDataAccessPersonalDetailsModule {}
