import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BasketItemPaymentOption,
  CoverPaymentOptions,
  PaymentType,
} from '@common/util-models';
import { DynamicFormbuilderService } from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'sales-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOptionsComponent implements OnChanges {
  private paymentOptionsFormSubject = new ReplaySubject<FormGroup>(1);
  @Input() paymentOptions!: CoverPaymentOptions | null;
  @Output()
  form = this.paymentOptionsFormSubject.asObservable();
  @Output()
  selectedPaymentType = this.form.pipe(
    switchMap((formGroup) =>
      this.dynamicFormBuilderService
        .selectValidFormValue$<{
          paymentOption: BasketItemPaymentOption;
        }>(formGroup)
        .pipe(startWith({ paymentOption: this.getDirectDebitPaymentOption() }))
    ),
    map((formValue) => formValue.paymentOption?.paymentType),
    filter((paymentOption) => !!paymentOption)
  ) as Observable<PaymentType>;

  constructor(private dynamicFormBuilderService: DynamicFormbuilderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // retrigger build
    const { paymentOptions } = changes;
    if (paymentOptions) {
      this.paymentOptionsFormSubject.next(this.getFormGroup());
    }
  }

  get isDirectDebitCheaperThanCreditCard() {
    return (
      this.paymentOptions?.directDebit &&
      this.paymentOptions?.card &&
      this.paymentOptions?.directDebit.fee < this.paymentOptions?.card.fee
    );
  }

  get isFirstPaymentAndSubsequentPaymentsSame() {
    return (
      this.paymentOptions?.directDebit &&
      this.paymentOptions?.directDebit?.firstPayment ===
        this.paymentOptions?.directDebit?.subsequentPayment
    );
  }

  get discount() {
    return this.paymentOptions?.directDebit && this.paymentOptions?.card
      ? this.paymentOptions?.card.fee - this.paymentOptions?.directDebit.fee
      : 0;
  }

  private getFormGroup() {
    return new FormGroup({
      paymentOption: new FormControl(this.getDirectDebitPaymentOption(), [
        Validators.required,
      ]),
    });
  }

  private getDirectDebitPaymentOption() {
    return this.paymentOptions?.directDebit;
  }
}
