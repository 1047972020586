<ng-container *ngIf="confirmationData">
  <h2 class="summary-title">{{ confirmationData.heading }}</h2>

  <div class="summary-container">
    <div class="summary-container__heading">
      <div class="summary-container__heading-details">
        <img
          class="appliance-icon"
          data-automation="icon"
          src="{{ confirmationData.applianceIcon }}"
          alt="{{ confirmationData.applianceName }}"
        />
        <span class="brand-name" data-automation="brandName">{{
          confirmationData.brandName
        }}</span
        >&nbsp;
        <span class="appliance-name" data-automation="applianceName">{{
          confirmationData.applianceName
        }}</span>
      </div>
      <div
        class="summary-container__heading-tick-icon icon icon-large-tick"
      ></div>
    </div>

    <!-- Heating / Boilers -->
    <div
      *ngIf="confirmationData.isHeating"
      class="summary-container__details"
      data-automation="summary-details-heating"
    >
      <div class="item">
        <div class="label">Your plan number</div>
        <div class="value">{{ confirmationData.planNumber }}</div>
      </div>
      <div class="item" data-automation="payments">
        <div class="label">Payments</div>
        <div *ngIf="!isCardPayment" class="value">
          1<sup>st</sup> payment of &pound;{{
            firstMonthlyPayment | number: '1.2-2'
          }}
          then 11 monthly payments of &pound;{{
            subsequentPayments | number: '1.2-2'
          }}
        </div>
        <div *ngIf="isCardPayment" class="value">
          &pound;{{ actualPrice | number: '1.2-2' }}
        </div>
      </div>
      <div class="item" data-automation="startDate">
        <div class="label">Plan start date</div>
        <div class="value">
          {{
            confirmationData.itemQuoteDetails.breakdownStartDate
              | date: 'dd/MM/yyyy'
          }}
        </div>
      </div>
      <div class="item" data-automation="paymentMethod">
        <div class="label">Payment method</div>
        <div class="value">{{ paymentMethodName }}</div>
      </div>
      <div class="item" data-automation="paymentDay" *ngIf="!isCardPayment">
        <div class="label">Monthly payment date</div>
        <div class="value">
          Day {{ confirmationData.preferredPaymentDay }} of each month
        </div>
      </div>
    </div>

    <!-- White Goods / Appliance -->
    <div
      *ngIf="!confirmationData.isHeating"
      class="summary-container__details"
      data-automation="summary-details-white-goods"
    >
      <div class="item">
        <div class="label">Your policy number</div>
        <div class="value">{{ confirmationData.planNumber }}</div>
      </div>
      <div class="item" data-automation="payments">
        <div class="label">Monthly payments</div>
        <div class="value">
          &pound;{{ subsequentPayments | number: '1.2-2' }}
          a month
        </div>
      </div>
      <div class="item" data-automation="startDate">
        <div class="label">Cover start date</div>
        <div class="value">
          {{
            confirmationData.itemQuoteDetails.breakdownStartDate
              | date: 'dd/MM/yyyy'
          }}
        </div>
      </div>
      <div class="item" data-automation="paymentMethod">
        <div class="label">Payment method</div>
        <div class="value">{{ paymentMethodName }}</div>
      </div>
      <div class="item" data-automation="paymentDay">
        <div class="label">Monthly payment date</div>
        <div class="value">
          Day {{ confirmationData.preferredPaymentDay }} of each month
        </div>
      </div>
    </div>

    <p
      *ngIf="confirmationData.isHeating"
      class="supporting"
      data-automation="supporting-doc-heating"
    >
      If your boiler is in guarantee, the plan will start at the end of the
      guarantee. If your boiler is out of guarantee, the plan will start once we
      have processed your application. If your chosen payment date is within the
      next 14 days, your first and second payments will be taken at the same
      time next month.
    </p>

    <p
      *ngIf="!confirmationData.isHeating"
      class="supporting"
      data-automation="supporting-doc-white-goods"
    >
      If your appliance is in guarantee, cover will start once we have processed
      your application. If your appliance is out of guarantee, cover will start
      after a 30 day wait. <br /><br />
      The policies provide continuous cover, so payments will be taken monthly
      for as long as you like. If your chosen payment date is within the next 14
      days, your first and second payments will be taken at the same time next
      month.
    </p>
  </div>
</ng-container>
