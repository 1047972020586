import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@oren/util-environment';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { OrenFeatureShellModule } from '@oren/feature-shell';

@NgModule({
  declarations: [AppComponent],
  imports: [CoreModule, BrowserModule, OrenFeatureShellModule],
  providers: [
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production }, // to enable debug logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
