<ui-panel class="desktop-screen" variant="highlight-feedback">
  <ui-panel-header>
    <ui-panel-title>Protection for your {{ brand }} {{ boilerType }}</ui-panel-title>
  </ui-panel-header>
  <ui-panel-body class="detail-panel" *ngIf="detail">
    <ui-panel-display-info-row
      *ngFor="let row of detail"
      [data]="row"
    ></ui-panel-display-info-row>
    <ng-content></ng-content>
    <ui-panel-content-row id="terms-link">
      <div *ngIf="isInsurance; else elseBlock">
        <a class="link" target="_blank" [href]="terms?.link"
        >Terms and conditions</a
        >
        and
        <a class="link" target="_blank" [href]="productInfo?.link"
        >Insurance Product Information Document</a
        >
      </div>
      <ng-template #elseBlock>
        <a
          class="link"
          target="_blank"
          [href]="terms?.link"
          (click)="termsClick.emit()"
        >Terms and conditions</a
        >
      </ng-template>
    </ui-panel-content-row>
  </ui-panel-body>
</ui-panel>
