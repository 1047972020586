import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ApplianceDetailsSelectors from '@common/data-access-appliance-details';
import {
  AvailableCover,
  CoverType,
  ExcessPrice,
  Quote,
  QuoteSummaryDataInput,
  TaggingMetadata,
  UpgradableCover,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as QuotesPageSelectors from './quotes-page.selectors';
import * as QuotesPageActions from './quotes.actions';
import { QuotesPartialState } from './quotes.reducer';
import * as QuotesSelectors from './quotes.selectors';
import { FeatureConfigState } from '@common/data-access-feature-config';

@Injectable()
export class QuotesFacade {
  quote$ = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  quoteSummary$ = this.store.pipe(
    select(QuotesPageSelectors.getQuoteSummary)
  ) as Observable<QuoteSummaryDataInput>;

  basketId$ = this.store.pipe(
    select(QuotesSelectors.getFirstItemQuote),
    filter((basketItem) => !!basketItem),
    map((basketItem) => basketItem?.basketId)
  ) as Observable<string>;

  taggingMetadata$ = this.store.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  availableExcessPrices$ = this.store.pipe(
    select(QuotesPageSelectors.getAvailableExcessPrices),
    filter((excess) => !!excess?.length)
  ) as Observable<ExcessPrice[]>;

  availableCovers$ = this.store.pipe(
    select(QuotesPageSelectors.getAvailableCovers),
    filter((covers) => !!covers?.length)
  ) as Observable<AvailableCover[]>;

  upgradableCovers$ = this.store.pipe(
    select(QuotesPageSelectors.getUpgradableCovers),
    filter((covers) => !!covers?.upgrade)
  ) as Observable<UpgradableCover>;

  selectedExcess$ = this.store.pipe(
    select(QuotesSelectors.getSelectedExcess)
  ) as Observable<number>;

  excessToDisplay$ = this.store.pipe(
    select(QuotesPageSelectors.getExcessToDisplay)
  ) as Observable<number>;

  selectedCoverType$ = this.store.pipe(
    select(QuotesSelectors.getSelectedCoverType),
    filter((selectedCoverType) => !!selectedCoverType)
  ) as Observable<CoverType>;

  selectedCoverName$ = this.store.pipe(
    select(QuotesSelectors.getSelectedCoverName)
  );

  selectedContractType$ = this.store.pipe(
    select(QuotesPageSelectors.getSelectedContractType)
  );

  isInsurance$ = this.store.pipe(
    select(QuotesPageSelectors.getIsInsurance),
    map((isInsurance) => !!isInsurance)
  );

  selectedCover$ = this.store.pipe(select(QuotesSelectors.getSelectedCover));

  isMultiplePaymentOptions$ = this.selectedCover$.pipe(
    map(
      (cover) => cover?.paymentOptions.card && cover?.paymentOptions.directDebit
    )
  );

  alternateProduct$ = this.store.pipe(
    select(QuotesPageSelectors.getAlternateProduct)
  );

  periodOfCover$ = this.store.pipe(select(QuotesSelectors.getPeriodOfCover));

  isHeating$ = this.store.pipe(
    select(ApplianceDetailsSelectors.isHeatingAppliance)
  );

  selectExcess(excessValue: number): void {
    this.store.dispatch(QuotesPageActions.selectExcessValue({ excessValue }));
  }

  selectCoverType(coverType: CoverType): void {
    this.store.dispatch(QuotesPageActions.selectCoverType({ coverType }));
  }

  selectFirstCoverType(coverType: CoverType): void {
    this.store.dispatch(QuotesPageActions.selectFirstCoverType({ coverType }));
  }

  constructor(private store: Store<QuotesPartialState & FeatureConfigState>) {}
}
