import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { TabComponent } from './tabs/components/tab/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { HelpfulLinksComponent } from './helpful-links/helpful-links.component';
import { CustomerServiceContactComponent } from './customer-service-contact/customer-service-contact.component';
import { CommonUiWhitelabelComponentsModule } from '@common/ui-whitelabel-components';
@NgModule({
  imports: [CommonModule, RouterModule, CommonUiWhitelabelComponentsModule],
  declarations: [
    HeaderComponent,
    TabsComponent,
    TabComponent,
    FooterComponent,
    LoaderComponent,
    ErrorComponent,
    HelpfulLinksComponent,
    CustomerServiceContactComponent,
  ],
  exports: [
    HeaderComponent,
    TabsComponent,
    TabComponent,
    FooterComponent,
    LoaderComponent,
    ErrorComponent,
    HelpfulLinksComponent,
  ],
})
export class CommonUiSalesComponentsModule {}
