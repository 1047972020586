import * as fs from 'fs';
import * as path from 'path';
import { CONFIG_KEYS, RuntimeConfig } from '@common/util-models';

export function validateConfigFiles(projectRoot: string) {
  const allowedConfigFiles = [
    'config.ci.json',
    'config.dev.json',
    'config.preprod.json',
    'config.prod.json',
    'config.sit.json',
    'config.test.json',
    'config.uat.json',
  ];
  return () => {
    const deploymentFolder = path.resolve(path.join(projectRoot, 'deployment'));
    for (const configFileName of allowedConfigFiles) {
      const configFilePath = path.join(deploymentFolder, configFileName);

      const configFileContents = fs.existsSync(configFilePath)
        ? JSON.parse(
            fs.readFileSync(configFilePath, {
              encoding: 'utf-8',
            })
          )
        : ({} as RuntimeConfig);

      // Check if exact number of keys are present
      expect(Object.keys(configFileContents).length).toEqual(
        CONFIG_KEYS.length
      );

      // Check if each key is having a truthy value
      for (const configKey of CONFIG_KEYS) {
        expect(configFileContents[configKey]).toBeTruthy();
      }
    }
  };
}
