import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { ErrorService } from '@common/util-foundation';
import {
  BuildConfig,
  CmsDataResolverConfig,
  CmsDataUrl,
  CmsPage,
} from '@common/util-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CMS_DATA } from '../mock-data';
import { cmsDataUrlToken } from './cms-data-url-token';

type CmsDataWithResults = { results: Array<unknown> };

@Injectable({
  providedIn: 'root',
})
export class CmsDataService {
  private config!: BuildConfig;

  constructor(
    private errorService: ErrorService,
    private buildConfigService: BuildConfigService,
    private httpClient: HttpClient,
    @Inject(cmsDataUrlToken)
    private cmsDataUrls: CmsDataUrl
  ) {}
  getCmsDataForPage(page: CmsPage) {
    const config = this.cmsDataUrls[page];
    if (!config) {
      return throwError(
        new Error(`Missing CMS config for ${page} in CmsDataUrl`)
      );
    }
    return this.getCmsData(config);
  }
  getCmsData({
    url: cmsDataUrl,
    id: cmsDataId,
    mapper: cmsDataMapper,
  }: CmsDataResolverConfig): Observable<unknown> {
    this.config = this.buildConfigService.config;
    const absoluteCmsUrl = this.config.cms_config.useCachedCmsOutput
      ? `${this.config.cms_config.cachedContentAppBase}${cmsDataId}.json`
      : `${this.config.cms_config.contentAppBase}/${cmsDataUrl}`;

    return this.httpClient.get(absoluteCmsUrl).pipe(
      catchError((error) => {
        console.warn(error.message);

        if (this.config.cms_config.useFallbackCmsContent) {
          // Fall back to mock data if CMS API is not available
          return of(CMS_DATA[cmsDataId]);
        } else {
          // Not using harcoded fallbackdata,
          // throwing an error to redirect to the error page instead
          return throwError(error);
        }
      }),
      map((cmsData: unknown | CmsDataWithResults) => {
        let data = cmsData;

        if (
          (cmsData as CmsDataWithResults).results &&
          (cmsData as CmsDataWithResults).results.length === 0
        ) {
          // Fall back to mock data if CMS API return is empty
          data = CMS_DATA[cmsDataId];
        }

        return cmsDataMapper ? cmsDataMapper(data as never) : data;
      })
    );
  }
}
