import { ApplianceFormField } from './appliance-form-cms.types';

export interface ApplianceFormSubmit {
  [ApplianceFormField.Appliance]: string;
  [ApplianceFormField.Brand]: string;
  [ApplianceFormField.GoodWorkingOrder]: string;
  [ApplianceFormField.PurchaseDate]: { month: number; year: number };
  [ApplianceFormField.PurchasePrice]: string;
  [ApplianceFormField.UnderGuarantee]: string;
  applianceCode: string;
  brandCode: string;
}

export type ApplianceFormSubmitNoCode = Omit<
  ApplianceFormSubmit,
  'applianceCode' | 'brandCode'
>;
