import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import type { detailData } from '@domgen/dgx-fe-base-components';
import { YourDetails } from '@common/util-models';
@Component({
  selector: 'wl-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnChanges {
  @Input() personalDetails: YourDetails | undefined | null;

  detail: detailData[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.personalDetails) {
      return;
    }
    if (this.personalDetails) {
      this.mapToDetailArray(this.personalDetails);
    }
  }

  mapToDetailArray(personalDetail: YourDetails) {
    this.detail = [
      {
        key: 'Name',
        value: personalDetail.name.trim(),
      },
      {
        key: 'Address',
        value: this.addressGenerator(personalDetail),
      },
      {
        key: 'Email address',
        value: personalDetail.email.trim(),
      },
      {
        key: 'Telephone number',
        value: personalDetail.mobile,
      },
    ];
  }

  addressGenerator(personalDetail: YourDetails) {
    let addressData = '';
    if (personalDetail.address) {
      const adressArray = Object.values(personalDetail.address);
      addressData = adressArray.filter((v) => !!v).join(', ');
    }
    return addressData;
  }
}
