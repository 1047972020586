import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { ErrorService } from '@common/util-foundation';
import { LoadFeatureConfigResponse } from '@common/util-models';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureConfigApiService {
  constructor(
    private errorService: ErrorService,
    private buildConfigService: BuildConfigService,
    private httpClient: HttpClient
  ) {}

  getFeatureConfig(): Observable<LoadFeatureConfigResponse> {
    const url = `local-data/features/${this.buildConfigService.config.catalogueStore}.json`;

    return this.httpClient.get<LoadFeatureConfigResponse>(url).pipe(
      catchError((error: Error) => {
        console.warn(error.message);
        return throwError(error);
      }),
      map((response: LoadFeatureConfigResponse) => response)
    );
  }
}
