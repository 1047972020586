import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { WrappedValidate, CmsFormField } from '@domgen/dgx-fe-business-models';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { PlanDocumentsFormConfigService } from './plan-documents-form-config.service';
import { PlanDocumentsFormStateService } from './plan-documents-form-state.service';

@Component({
  selector: 'dgx-uib-plan-documents',
  templateUrl: './plan-documents.component.html',
  styleUrls: ['./plan-documents.component.scss'],
  providers: [PlanDocumentsFormStateService, PlanDocumentsFormConfigService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDocumentsComponent {
  @Input() isHeating!: boolean | null;
  @Input() infoText!: string | null;

  @Input() set displayPaperlessAs(value: 'checkbox' | 'buttons') {
    if (value) {
      this.stateService.setDisplayPaperlessAs(value);
    }
  }
  @Input() set hideTermsAndConditions(value: boolean | null) {
    if (value !== null) {
      this.stateService.hideTermsAndConditionField(value);
    }
  }

  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.stateService.setCmsFormData(value);
    }
  }

  @Input() set stateFormData(value: boolean | null) {
    if (typeof value === 'boolean') {
      this.stateService.setPrefilledFormData(value);
    }
  }

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.stateService.validate();
    }
  }

  /**
   * The underlying form group
   */
  @Output() form = this.stateService.formGroup$;

  /**
   * The valid value of the form
   */
  @Output() valueChanges = this.stateService.valueChanges$;

  /**
   * Analytics data for the form
   */
  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  readonly vm$ = this.stateService.vm$;

  constructor(private readonly stateService: PlanDocumentsFormStateService) {}

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
