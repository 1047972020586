import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CheckoutConfirmationData,
  PaymentType,
  Quote,
} from '@common/util-models';
import { PaymentOptionsService } from '../payment-options/payment-options.service';

@Component({
  selector: 'sales-confirmation-details',
  templateUrl: './confirmation-details.component.html',
  styleUrls: ['./confirmation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaymentOptionsService],
})
export class ConfirmationDetailsComponent {
  @Input() confirmationData: CheckoutConfirmationData | null = null;
  @Input() quote: Quote | null = null;

  constructor(private paymentOptionsService: PaymentOptionsService) {}

  get paymentMethodName(): string {
    return this.isCardPayment ? 'Credit/Debit Card' : 'Direct Debit';
  }

  get isCardPayment(): boolean {
    return this.confirmationData?.paymentType === PaymentType.Card;
  }

  get firstMonthlyPayment() {
    return this.quote?.cover.paymentOptions.directDebit?.firstPayment.toFixed(
      2
    );
  }

  get subsequentPayments() {
    return this.quote?.cover.paymentOptions.directDebit?.subsequentPayment.toFixed(
      2
    );
  }

  get actualPrice(): string | 0 {
    const paymentOptions = this.confirmationData?.paymentOptions;

    return paymentOptions
      ? this.paymentOptionsService.price(paymentOptions)
      : 0;
  }
}
