<wl-quote-panel
  *ngIf="data && config"
  [expandable]="config.isForSmallScreen"
  [sticky]="config.isForSmallScreen ? 'bottom' : false"
  [stickyAutoExpand]="config.isForSmallScreen"
  [title]="title"
  [selectedExcessLevel]="data.excessToDisplay"
  [quote]="data.quote"
  [isInsurance]="data.isInsurance"
  [paymentType]="selectedPaymentType"
></wl-quote-panel>
