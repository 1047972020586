export const CHECKOUT_LOGGED_IN_USER_FORM = {
  results: [
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'Name',
      controlName: 'name',
      placeholder: '',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {},
    },
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'Email',
      controlName: 'email',
      placeholder: '',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {},
    },
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'Phone number',
      controlName: 'phoneNumber',
      placeholder: '',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {},
    },
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'Address',
      controlName: 'address',
      placeholder: '',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {},
    },
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'Name of account holder',
      controlName: 'accountName',
      placeholder: 'Name of account holder',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Name of account holder is required',
        },
      },
    },
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'Account number',
      controlName: 'accountNumber',
      placeholder: 'Account number',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Account number is required',
        },
        '1': {
          type: 'invalidAccountNumber',
          message: 'Account number must be 8 digits',
        },
      },
    },
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'Sort code',
      controlName: 'sortCode',
      placeholder: '',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Sort code is required',
        },
        '1': {
          type: 'minLength',
          message: 'Sort code should contain 6 digits',
        },
        '2': {
          type: 'invalidSortCode',
          message: 'Enter a valid sort code',
        },
      },
    },
    {
      formName: 'checkoutLoggedInUserForm',
      application: 'sales',
      label: 'What day would you like to pay each month?',
      controlName: 'monthlyPaymentDay',
      placeholder: 'Select',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Payment day is required',
        },
      },
    },
    {
      formName: 'checkoutLoggedInUserForm',
      label: 'Would you also like to receive a copy of your plan in the post?',
      placeholder: 'None',
      tooltip: '',
      controlName: 'paperless',
      hint: '',
      message:
        'Going paperless means there’s less post to deal with, file or recycle.',
      options: ['Yes', 'No'],
      jcrName: 'sales_directDebitForm_paperless',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Select one of the options',
          '@nodes': [],
        },
      },
    },
    {
      formName: 'checkoutLoggedInUserForm',
      label: '',
      tooltip: '',
      controlName: 'termsAndConditions',
      hint: '',
      jcrName: 'sales_directDebitForm_termsAndConditions',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please confirm that you have read and agree',
        },
      },
    },
  ],
};
