import { ActionReducerMap } from '@ngrx/store';
import {
  appliancesReducer,
  AppliancesState,
  APPLIANCES_KEY,
} from './appliances.reducer';
import { brandsReducer, BrandsState, BRANDS_KEY } from './brands.reducer';
import {
  formDataReducer,
  ApplianceDetailsFormState,
  FORM_DATA,
} from './appliance-details-form.reducer';

export const APPLIANCEDETAILS_FEATURE_KEY = 'applianceDetails';

export interface ApplianceDetailsState {
  [APPLIANCES_KEY]: AppliancesState;
  [BRANDS_KEY]: BrandsState;
  [FORM_DATA]: ApplianceDetailsFormState;
}

export const applianceDetailsReducer: ActionReducerMap<ApplianceDetailsState> = {
  [APPLIANCES_KEY]: appliancesReducer,
  [BRANDS_KEY]: brandsReducer,
  [FORM_DATA]: formDataReducer,
};
