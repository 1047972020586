import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PersonalDetails } from '@common/util-models';

@Component({
  selector: 'sales-welcome-back-message',
  templateUrl: './welcome-back-message.component.html',
  styleUrls: ['./welcome-back-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeBackMessageComponent {
  @Input() personalDetails!: PersonalDetails | null;
}
