import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlanTermsAndConditionsComponent } from './plan-terms-and-conditions.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PlanTermsAndConditionsComponent],
  exports: [PlanTermsAndConditionsComponent],
})
export class PlanTermsAndConditionsModule {}
