<div class="form-group yes-no-radio">
  <div class="flex yes-no-radio__header">
    <label class="label yes-no-radio__label">
      {{ field?.label?.text }}
      <dgx-dfb-tooltip
        [tooltip]="field?.tooltip"
        [tooltipName]="tooltipName"
        *ngIf="field?.tooltip"
      ></dgx-dfb-tooltip>
    </label>
  </div>
  <ng-container dgxDfbTooltipTarget [tooltipName]="tooltipName"></ng-container>
  <div [class.yes-no-radio__options]="true" [ngClass]="field?.classes || []">
    <ng-container *ngFor="let option of field?.options">
      <label
        (click)="writeValue(option, true)"
        class="yes-no-radio__option"
        [ngClass]="{
          'yes-no-radio__option--active':
            value === option || (field.initialValue === option && !isDirty),
          'yes-no-radio__option--inactive': value !== option,
          'yes-no-radio__option--invalid': !!errorMessage
        }"
      >
        <input
          class="form-control yes-no-radio__input"
          type="radio"
          [name]="field?.controlName"
          [value]="option"
          [attr.checked]="
            value === option || (field.initialValue === option && isDirty)
              ? ''
              : null
          "
          (click)="isDirty = true; handleClick($event)"
        />
        <span>{{ option }}</span>
      </label>
    </ng-container>
  </div>
  <dgx-dfb-error
    *ngIf="errorMessage"
    [errorMessage]="errorMessage"
  ></dgx-dfb-error>
  <div class="form-group__hint" *ngIf="field?.hint">{{ field?.hint }}</div>
  <div *ngIf="field?.message" class="form-group__message">
    <p>{{ field?.message }}</p>
  </div>
</div>
