<div class="your-details form-group" *ngIf="personalDetails">
  <h2 class="your-details__title">Your details</h2>
  <div class="your-details__details ms-stack md-row">
    <div class="xs-12 md-6">
      <div class="your-details__personal-info-section">
        <div class="your-details__personal-info">
          <div class="your-details__label label">{{ nameLabel }}</div>
          <div class="your-details__personal-info-detail">
            {{ personalDetails.name }}
          </div>
        </div>
        <div class="your-details__personal-info">
          <div class="your-details__label label">{{ emailLabel }}</div>
          <div
            class="your-details__personal-info-detail email"
            [title]="personalDetails.email"
          >
            {{ personalDetails.email }}
          </div>
        </div>
        <div class="your-details__personal-info">
          <div class="your-details__label label">{{ phoneNumberLabel }}</div>
          <div
            class="your-details__personal-info-detail"
            *ngIf="personalDetails.mobile"
          >
            {{ personalDetails.mobile }}
          </div>
          <div
            class="your-details__personal-info-detail"
            *ngIf="personalDetails.landline && !personalDetails.mobile"
          >
            {{ personalDetails.landline }}
          </div>
        </div>
      </div>
    </div>
    <div class="xs-12 md-6">
      <div class="your-details__address-section">
        <div class="your-details__label label">{{ addressLabel }}</div>
        <div class="your-details__address-detail">
          <span class="your-details__address-line">{{
            personalDetails.address.addressLine1
          }}</span>
          <span
            class="your-details__address-line"
            *ngIf="personalDetails.address.addressLine2"
            >{{ personalDetails.address.addressLine2 }}</span
          >
          <span
            class="your-details__address-line"
            *ngIf="personalDetails.address.addressLine3"
            >{{ personalDetails.address.addressLine3 }}</span
          >
          <span
            class="your-details__address-line"
            *ngIf="personalDetails.address.addressLine4"
            >{{ personalDetails.address.addressLine4 }}</span
          >
          <span class="your-details__address-line">{{
            personalDetails.address.postalCode
          }}</span>
          <span
            class="your-details__address-line your-details__address-line--no-margin"
            >{{ personalDetails.address.countryCode }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
