import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { YesNoDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { RadioComponent } from '../radio/radio.component';

@Component({
  selector: 'dgx-dfb-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class YesNoComponent extends RadioComponent {
  @Input() field!: YesNoDef;
  tooltipName = 'tooltip';
  // a flag to apply active styling to the default option
  // gets set once the value is set by user
  isDirty = false;

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }
}
