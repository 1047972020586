import { inject, InjectionToken } from '@angular/core';
import { BuildConfigService } from '../services/build.config.service';

export const FORM_BUILDER_CONFIG = new InjectionToken('FormBuilderConfig', {
  providedIn: 'root',
  factory: () => {
    const buildConfigService = inject(BuildConfigService);
    return {
      ...buildConfigService.getFormbuilderConfig(),
    };
  },
});
