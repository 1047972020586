import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { QuotesFacade } from '../+state/quotes.facade';

@Injectable({
  providedIn: 'root',
})
export class QuoteRouteParamsResolver implements Resolve<boolean> {
  constructor(private quotesFacade: QuotesFacade) {}
  resolve(route: ActivatedRouteSnapshot): boolean {
    if (route.queryParams?.cover) {
      this.quotesFacade.selectFirstCoverType(route.queryParams.cover);
    } else if (route.data?.cover) {
      this.quotesFacade.selectFirstCoverType(route.data.cover);
    }
    if (route.queryParams?.excess) {
      this.quotesFacade.selectExcess(Number(route.queryParams.excess));
    } else if (route.data?.excess) {
      this.quotesFacade.selectExcess(Number(route.data.excess));
    }
    return !!route.queryParams || !!route.data?.cover;
  }
}
