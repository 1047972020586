import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiCmsModule } from '@domgen/dgx-fe-content-management';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { ApplianceDetailsFormComponent } from './appliance-details-form/appliance-details-form.component';
import { SafeHandsComponent } from './safe-hands/safe-hands.component';
import { WhyGetProtectedComponent } from './why-get-protected/why-get-protected.component';

@NgModule({
  imports: [CommonModule, SharedUiDynamicFormBuilderModule, SharedUiCmsModule],
  declarations: [
    ApplianceDetailsFormComponent,
    SafeHandsComponent,
    WhyGetProtectedComponent,
  ],
  exports: [
    ApplianceDetailsFormComponent,
    SafeHandsComponent,
    WhyGetProtectedComponent,
  ],
})
export class ApplianceDetailsUiModule {}
