<ng-container *ngIf="vm$ | async as vm">
  <div class="form-group">
    <div class="flex">
      <label [for]="vm.fieldDef?.controlName">
        {{ vm.fieldDef?.label?.text }}
      </label>
      <dgx-dfb-tooltip
        [tooltip]="vm.fieldDef?.tooltip"
        [tooltipName]="vm.tooltipName"
        *ngIf="vm.fieldDef?.tooltip"
      ></dgx-dfb-tooltip>
    </div>
    <ng-container
      dgxDfbTooltipTarget
      [tooltipName]="vm.tooltipName"
    ></ng-container>
    <div class="wrapper">
      <div class="form-group__feedback">
        <input
          class="form-control"
          autocomplete="off"
          type="text"
          [name]="vm.fieldDef?.controlName"
          [placeholder]="vm.fieldDef?.placeholder"
          [value]="vm.value"
          [disabled]="vm.disable"
          (input)="onChange($event)"
          (blur)="onBlur($event)"
          (keydown)="onKeyDown($event)"
          (focus)="isInputFocused = true"
          (focusout)="isInputFocused = false"
        />

        <i class="icon icon-tick-circle icon--xxs"></i>
        <i class="icon icon-error-circle icon--xxs"></i>
        <i
          class="icon icon--lg icon-control"
          [ngClass]="{
            'icon-close': vm.value !== '' || isInputFocused,
            'icon-search': !vm.value && !isInputFocused
          }"
          (click)="clearControl()"
        ></i>
      </div>
      <div
        class="form-group__result"
        *ngIf="vm.showOptions"
        [ngClass]="{
          'form-group__result--all-options': vm?.fieldDef?.showAllOptions
        }"
      >
        <button
          *ngFor="let option of vm.filteredOptions; let idx = index"
          type="button"
          class="form-group__result-button {{ vm.optionClass }}"
          [id]="typeaheadId + '-' + idx"
          [class.active]="idx === vm.activeOptionIndex"
          (mouseenter)="onMouseEnter(idx)"
          (click)="onClick(idx)"
        >
          <ng-container
            *ngFor="
              let part of vm.highlightedFilteredOptions[idx];
              let isOdd = odd
            "
          >
            <span
              *ngIf="isOdd; else even"
              class="typeahead-option--highlight"
              >{{ part }}</span
            >
            <ng-template #even>{{ part }}</ng-template>
          </ng-container>
        </button>
      </div>
    </div>

    <dgx-dfb-error *ngIf="vm.errorMessage" [errorMessage]="vm.errorMessage">
    </dgx-dfb-error>
    <div *ngIf="vm.fieldDef?.hint" class="form-group__hint">
      {{ vm.fieldDef?.hint }}
    </div>
  </div>
</ng-container>
