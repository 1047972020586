export interface CmsFormField<T extends string = string> {
  label: string;
  controlName: T;
  formName: string;
  validationMessages: {
    [key: string]: {
      type: string;
      message: string;
    };
  };
  hint?: string;
  message?: string;
  tooltip?: string;
  placeholder?: string;
  options?: string[];
  jcrName?: string;
}

export enum DirectDebitFormField {
  AccountName = 'accountName',
  AccountNumber = 'accountNumber',
  SortCode = 'sortCode',
  MonthlyPaymentDay = 'monthlyPaymentDay',
}

export enum MarketingPreferencesFormField {
  Email = 'email',
  Phone = 'phone',
  Post = 'post',
}

export enum PlanDocumentsFormField {
  Paperless = 'paperless',
  TermsAndConditions = 'termsAndConditions',
}

// Combination all of forms in Business Components lib
export type CmsBusinessComponentsFormFieldName =
  | DirectDebitFormField
  | PlanDocumentsFormField
  | MarketingPreferencesFormField
  | {
      [key: string]: string;
    };
