import { GaTags } from '@common/util-models';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GaTaggingService } from '../services';

export const pushToGa = (
  gaTaggingService: GaTaggingService
): MonoTypeOperatorFunction<GaTags> => {
  return (source$: Observable<GaTags>) =>
    source$.pipe(tap((dataToPush) => gaTaggingService.tag(dataToPush)));
};
