import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorService } from '@common/util-foundation';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  loadFeatureConfig,
  loadFeatureConfigFailure,
  loadFeatureConfigSuccess,
} from './feature-config.actions';
import { FeatureConfigApiService } from '../services/feature-config-api.service';

@Injectable()
export class FeatureConfigEffects {
  loadFeatureConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeatureConfig),
      exhaustMap(() =>
        this.featureConfigService.getFeatureConfig().pipe(
          map((response) =>
            loadFeatureConfigSuccess({
              response,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            this.handleError();
            return of(loadFeatureConfigFailure({ error }));
          })
        )
      )
    )
  );

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    private actions$: Actions,
    private featureConfigService: FeatureConfigApiService,
    private errorService: ErrorService
  ) {}
}
