import { createAction } from '@ngrx/store';

export const restartCheckoutGuest = createAction(
  '[CheckoutDirectDebitComponent] Restart the guest checkout journey'
);

export const restartCheckoutLoggedInUser = createAction(
  '[CheckoutLoggedInUserComponent] Restart the logged in user checkout journey'
);

export const restartCheckoutBackButton = createAction(
  '[AppComponent] Restart the journey due to browser back button'
);
