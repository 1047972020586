import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { TypeAheadDef } from '../../_shared/interfaces';
import { TypeaheadStateService } from './typeahead-state.service';

let nextId = 0;
@UntilDestroy()
@Component({
  selector: 'dgx-dfb-type-ahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [TooltipService, TypeaheadStateService],
})
export class TypeaheadComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() set field(fieldDef: TypeAheadDef) {
    if (fieldDef) {
      this.typeaheadStateService.setFieldDef(fieldDef);
    }
  }

  @Input() validate = false;

  /**
   * Simulate as if the user has types something. But not a selection of it. This is done via a call to
   * writeValue()
   *
   * @memberof TypeaheadComponent
   */
  @Input() set typeInput(value: string) {
    if (value !== undefined) {
      this.typeaheadStateService.setTemplateEvent({
        type: 'input',
        payload: value,
      });
    }
  }

  typeaheadId = `typeahead-${nextId++}`;

  // Analytics event to contain data on blur
  @Output() analytics = this.typeaheadStateService.analytics$;

  // An event emitted when an item is selected from the options list.
  @Output() selectItem = this.typeaheadStateService.selectedOption$;

  // An event emitted to reflect the value changes.
  @Output() value = this.typeaheadStateService.value$;

  vm$ = this.typeaheadStateService.vm$;
  isInputFocused = false;

  constructor(
    private readonly typeaheadStateService: TypeaheadStateService,
    @Optional() private ngControl: NgControl,
    private elementRef: ElementRef<HTMLElement>
  ) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
    this.typeaheadStateService.setNativeDomElement(
      this.elementRef.nativeElement
    );
  }

  ngOnInit(): void {
    if (this.ngControl && this.ngControl.control) {
      this.typeaheadStateService.setFormControl(this.ngControl.control);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue === true) {
      this.typeaheadStateService.setValidate(true);
    }
  }

  onChange(event: Event): void {
    this.typeaheadStateService.setTemplateEvent({
      type: 'input',
      payload: (event.target as HTMLInputElement).value,
    });
  }

  onBlur(event: Event): void {
    this.typeaheadStateService.setTemplateEvent({
      type: 'blur',
      payload: event,
    });
  }

  onClick(index: number) {
    this.typeaheadStateService.setTemplateEvent({
      type: 'click',
      payload: index,
    });
  }

  onMouseEnter(index: number) {
    this.typeaheadStateService.setTemplateEvent({
      type: 'mouseEnter',
      payload: index,
    });
  }

  onKeyDown(event: KeyboardEvent) {
    this.typeaheadStateService.setTemplateEvent({
      type: 'keyDown',
      payload: event,
    });
  }

  clearControl() {
    this.typeaheadStateService.clearInput();
  }

  registerOnChange(fn: (val: string) => void) {
    this.typeaheadStateService.saveOnChangeReference(fn);
  }

  registerOnTouched(fn: () => void) {
    this.typeaheadStateService.saveOnTouchedReference(fn);
  }

  writeValue(val: string) {
    this.typeaheadStateService.updateForWriteValue(val);
  }
}
