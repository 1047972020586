import { Component, HostBinding, Input, Optional } from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import {
  SortCodeDef,
  SortCodeInputs,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

@Component({
  selector: 'dgx-dfb-sortcode',
  templateUrl: './sortcode.component.html',
  styleUrls: ['./sortcode.component.scss'],
  providers: [TooltipService],
})
export class SortcodeComponent
  extends FormElementBaseComponent
  implements ControlValueAccessor {
  @Input() field!: SortCodeDef;
  value: string | undefined;
  tooltipName = 'tooltip';
  empty = '';
  nextFocusControlName = '';

  inputs: SortCodeInputs = {
    sort1: { touched: false, value: this.empty },
    sort2: { touched: false, value: this.empty },
    sort3: { touched: false, value: this.empty },
  };

  @HostBinding('class.all-inputs-touched') get allTouched() {
    return (
      this.inputs.sort1.touched &&
      this.inputs.sort2.touched &&
      this.inputs.sort3.touched
    );
  }

  constructor(
    @Optional() public ngControl: NgControl,
    protected formGroupDir: FormGroupDirective
  ) {
    super(ngControl);
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string) {
    this.value = val;
    this.distributeValueIntoInputs(this.value);
    super.writeValue(val);
  }

  onInputChange(target: {
    value: string;
    nextSibling: HTMLInputElement;
    name: string;
  }) {
    const input = (this.inputs as SortCodeInputs)[target.name];
    if (target && target.value) {
      input.value = target.value;

      if (target.value.length === 2 && target.nextSibling) {
        // tab to the next section of the sort code
        this.nextFocusControlName = target.nextSibling?.name;
        (target.nextSibling as HTMLInputElement).focus();
      }
    }
    this.combineValue();
  }

  onMousedown(target: {
    value: string;
    nextSibling: HTMLInputElement;
    name: string;
  }) {
    this.nextFocusControlName = target.name;
    setTimeout(() => {
      this.nextFocusControlName = '';
    }, 0);
  }

  onKeyDown(
    event: KeyboardEvent,
    target: {
      value: string;
      nextSibling: HTMLInputElement;
      name: string;
    }
  ) {
    if (
      event.key == 'Tab' &&
      !(target.name === 'sort3' && !event.shiftKey) &&
      !(target.name === 'sort1' && event.shiftKey)
    ) {
      this.nextFocusControlName = target?.name;
    }
  }

  onInputBlur(target: {
    value: string;
    nextSibling: HTMLInputElement;
    name: string;
  }) {
    const input = (this.inputs as SortCodeInputs)[target.name];
    input.touched = true;
    this.onTouched();
    this.combineValue();
    const sortcodeControlArray = ['sort1', 'sort2', 'sort3'];
    if (
      this.nextFocusControlName &&
      sortcodeControlArray.indexOf(this.nextFocusControlName) !== -1
    ) {
      this.nextFocusControlName = '';
    } else {
      this.emitAnalyticsData(this.value);
    }
  }

  combineValue(): void {
    this.value = `${this.inputs.sort1.value}${this.inputs.sort2.value}${this.inputs.sort3.value}`;

    // prevent validation error from showing too early
    if (
      this.inputs.sort1.touched &&
      this.inputs.sort2.touched &&
      this.inputs.sort3.touched
    ) {
      super.writeValue(this.value);
    }
  }

  private distributeValueIntoInputs(value: string) {
    const parts = (value ?? this.empty).match(/.{1,2}/g);
    this.resetSortcodeParts();

    if (parts && parts.length > 0) {
      this.inputs.sort1.value = parts[0];
    }

    if (parts && parts.length > 1) {
      this.inputs.sort2.value = parts[1];
    }

    if (parts && parts.length > 2) {
      this.inputs.sort3.value = parts[2];
    }
  }

  resetSortcodeParts() {
    this.inputs.sort1.value = this.empty;
    this.inputs.sort2.value = this.empty;
    this.inputs.sort3.value = this.empty;
  }
}
