import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Quote } from '@common/util-models';

@Component({
  selector: 'sales-appliance-details-card',
  templateUrl: './appliance-details-card.component.html',
  styleUrls: ['./appliance-details-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplianceDetailsCardComponent {
  @Input() quote!: Quote | null;
}
