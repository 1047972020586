import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const validTypeAheadSelection = (
  optionsStream$: Observable<string[]>
) => {
  return (control: AbstractControl) => {
    return optionsStream$.pipe(
      map((options) => {
        return options?.length ? validateInput(control.value, options) : null;
      }),
      take(1)
    );
  };
};

function validateInput(input: string, options: string[]) {
  const match = options.find(
    (option) => option.toLowerCase() === input?.toLowerCase()
  );
  return match ? null : { invalid: true };
}
