<ui-panel class="desktop-screen">
  <ui-panel-header>
    <ui-panel-title
      >Your {{ isInsurance ? 'policy' : 'plan' }} duration
    </ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <div>
      Your {{ isInsurance ? 'policy' : 'plan' }} will start 30 days after your
      purchase, and will be valid for one year. Your protection is provided by
      {{
        isInsurance
          ? 'Domestic & General Insurance PLC'
          : 'Domestic & General Services Limited'
      }}.
    </div>
    <div class="pt-1" *ngIf="isDirectDebit">
      Paying by Direct Debit ensures automatic renewal each year. We will write
      to you before your renewal date.
    </div>
  </ui-panel-body>
</ui-panel>
