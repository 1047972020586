import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BasketItem,
  BasketItemQuoteDetails,
  Cover,
  CoverType,
  isRemoteDataError,
  ItemType,
  QuotesConfig,
  RemoteData,
} from '@common/util-models';
import {
  quotesAdapter,
  QuotesPartialState,
  QuotesState,
  QUOTES_FEATURE_KEY,
} from './quotes.reducer';
import { transformQuoteDetailToCover } from './quotes.utils';
import { FeatureConfigSelectors } from '@common/data-access-feature-config';
import { FeatureConfigPartialState } from '@common/data-access-feature-config';

export const getQuotesState = createFeatureSelector<
  QuotesPartialState,
  QuotesState
>(QUOTES_FEATURE_KEY);

const { selectAll, selectEntities, selectTotal } = quotesAdapter.getSelectors();

export const getQuotesRemoteState = createSelector(
  getQuotesState,
  (state: QuotesState) => state.remoteState
);

export const getQuotesError = createSelector(
  getQuotesRemoteState,
  (remoteState: RemoteData) => {
    if (isRemoteDataError(remoteState)) {
      return remoteState.error;
    }
    return undefined;
  }
);

export const getAllQuotes = createSelector(
  getQuotesState,
  (state: QuotesState) => selectAll(state)
);

export const getQuotesEntities = createSelector(
  getQuotesState,
  (state: QuotesState) => selectEntities(state)
);

export const getTotalQuotes = createSelector(
  getQuotesState,
  (state: QuotesState) => selectTotal(state)
);

export const getFirstItemQuote = createSelector(
  getAllQuotes,
  (quotes: BasketItem[]) => quotes.find((q) => q.itemType === ItemType.Quote)
);

export const getFirstCoverType = createSelector(
  getQuotesState,
  (state: QuotesState) => state.entities[state.ids[0]]?.data.quotes[0].coverType
);

export const getSelectedCoverType = createSelector(
  getQuotesState,
  (state: QuotesState): CoverType => state.selectedCoverType
);

export const getSelectedCoverName = createSelector(
  FeatureConfigSelectors.getQuotesConfig,
  getQuotesState,
  (config: QuotesConfig | undefined, state: QuotesState): string => {
    const name =
      config?.coverTypeNames && config?.coverTypeNames[state.selectedCoverType];
    return name ? name : '';
  }
);

export const getInitialCoverType = createSelector(
  getQuotesState,
  (state: QuotesState): CoverType => state.initialCoverType
);

export const shouldAutoSelectCover = createSelector(
  getQuotesState,
  (state: QuotesState): boolean => state.enableAutoSelectCover ?? false
);

export const getSelectedExcess = createSelector(
  getQuotesState,
  (state: QuotesState): number => state.selectedExcess
);

export const getApplianceDetails = createSelector(
  getFirstItemQuote,
  (quote: BasketItem | undefined) => quote?.data?.applianceDetails
);

export const getQuoteDetails = createSelector(
  getFirstItemQuote,
  (quote: BasketItem | undefined) => quote?.data?.quotes
);

/**
 * Gets the "BasketItemQuoteDetails" entry for the selected cover type
 * Filters payment methods based on selected Excess
 * Returns Payment methods as object rather than array
 */
export const getSelectedCover = createSelector(
  getSelectedCoverType,
  getSelectedExcess,
  getQuoteDetails,
  FeatureConfigSelectors.getQuotesConfig,
  (
    selectedCoverType: CoverType,
    selectedExcess: number,
    quoteDetails: BasketItemQuoteDetails[] | undefined,
    config
  ): Cover | undefined => {
    const quoteDetail = quoteDetails?.find(
      (quoteDetail) =>
        quoteDetail.coverType === selectedCoverType &&
        quoteDetail.excessAmount === selectedExcess
    );
    if (!quoteDetail) {
      return;
    }
    const name =
      config?.coverTypeNames && config?.coverTypeNames[selectedCoverType];
    return { name, ...transformQuoteDetailToCover(quoteDetail) };
  }
);

export const getPaymentOptions = createSelector(getSelectedCover, (cover) => {
  const paymentOptions = cover?.paymentOptions || undefined;
  if (paymentOptions && paymentOptions.directDebit && paymentOptions.card) {
    const discount = paymentOptions.card.fee - paymentOptions.directDebit.fee;
    paymentOptions.directDebit = {
      ...paymentOptions.directDebit,
      directDebitDiscount: discount,
    };
  }
  return paymentOptions;
});

export const getPeriodOfCover = createSelector(getSelectedCover, (cover) => {
  return cover?.periodOfCover ?? undefined;
});

export const isDirectDebitOnlyPaymentOption = createSelector(
  getPaymentOptions,
  (paymentOptions) => {
    if (paymentOptions) {
      return paymentOptions.directDebit && !paymentOptions.card;
    }

    return undefined;
  }
);

export const getReturnedCoverTypes = createSelector(
  getQuoteDetails,
  (quotes): Array<CoverType> => {
    //find all the coverTypes returned by the Quote API
    const coverTypes: CoverType[] =
      quotes?.map(({ coverType }: BasketItemQuoteDetails) => coverType) || [];
    //filter to unique values
    return coverTypes.filter(
      (coverType, index) => coverTypes.indexOf(coverType) === index
    );
  }
);

export const getFirstCoverTypeFromWhitelist = createSelector<
  QuotesPartialState & FeatureConfigPartialState,
  QuotesConfig | undefined,
  CoverType[],
  CoverType | undefined
>(
  FeatureConfigSelectors.getQuotesConfig,
  getReturnedCoverTypes,
  (config: QuotesConfig | undefined, returnedCoverTypes) => {
    return (config?.coverTypesWhitelist || []).find((coverType) =>
      returnedCoverTypes.includes(coverType)
    );
  }
);

export const getCoverTypeNames = createSelector<
  QuotesPartialState & FeatureConfigPartialState,
  QuotesConfig | undefined,
  QuotesConfig['coverTypeNames']
>(
  FeatureConfigSelectors.getQuotesConfig,
  (config: QuotesConfig | undefined) => config?.coverTypeNames || {}
);
