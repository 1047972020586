<ui-panel
  [initialExpanded]="expandable ? false : true"
  [sticky]="sticky"
  [stickyAutoExpand]="stickyAutoExpand"
  *ngIf="quote"
  [ngClass]="{ 'mobile-screen': sticky, 'desktop-screen': !sticky }"
>
  <ui-panel-header>
    <ui-panel-title variant="highlight">{{ title }}</ui-panel-title>
    <ui-panel-toggle-button *ngIf="expandable"></ui-panel-toggle-button>
  </ui-panel-header>
  <ui-panel-body class="quote-panel">
    <div
      class="quote-panel__row quote-panel__excess_level"
      *ngIf="selectedExcessLevel !== null"
    >
      <div class="excess-level quote-panel__label">
        {{ isInsurance ? 'Excess per claim' : 'Call-out charge' }}
      </div>
      <div class="quote-panel__value">
        £{{ selectedExcessLevel | number: '1.2-2' }}
      </div>
    </div>
    <div
      class="quote-panel__DD_monthly-payment"
      *ngIf="!isSameSubsequentPayment"
    >
      <div class="quote-panel__row quote-panel__monthly-payment">
        <div class="quote-panel__label">1st Monthly payment</div>
        <div class="quote-panel__value" *ngIf="!isCard">
          £{{ DDPaymentOption?.firstPayment || 0 | number: '1.2-2' }}
        </div>
      </div>
      <div class="quote-panel__row quote-panel__monthly-payment">
        <div class="quote-panel__label">11 Monthly payments</div>
        <div class="quote-panel__value" *ngIf="!isCard">
          £{{ DDPaymentOption?.subsequentPayment || 0 | number: '1.2-2' }}
        </div>
      </div>
    </div>
    <!-- <div class="quote-panel__row quote-panel__monthly-price">
      <div class="quote-panel__label">
        {{ isCard ? 'One time Cost' : 'Monthly cost' }}
      </div>
      <div class="quote-panel__value" *ngIf="!isCard">
        £{{ DDPaymentOption?.subsequentPayment || 0 | number: '1.2-2' }}
      </div>
      <div class="quote-panel__value" *ngIf="isCard">
        £{{ cardPaymentOption?.fee || 0 | number: '1.2-2' }}
      </div>
    </div> -->
    <div class="quote-panel__total-pay-section">
      <div class="quote-panel__total-price-section">
        <div class="quote-panel__row quote-panel__total-price">
          <div class="quote-panel__label">Total to pay</div>

          <div class="quote-panel__value" *ngIf="!isCard">
            £{{ DDPaymentOption?.subsequentPayment || 0 | number: '1.2-2' }}
            a month
          </div>
          <div class="quote-panel__value" *ngIf="isCard">
            £{{ cardPaymentOption?.fee || 0 | number: '1.2-2' }}
          </div>
        </div>
        <div
          class="quote-panel__row quote-panel__yearly-price"
          *ngIf="!isCard && periodOfCover"
        >
          <div class="quote-panel__label"></div>
          <div class="quote-panel__value">
            12 months for £{{ DDPaymentOption?.fee || 0 | number: '1.2-2' }}
          </div>
        </div>
      </div>
      <div class="quote-panel__discount" *ngIf="discount">
        Includes £{{ discount | number: '1.2-2' }}
        Direct Debit Discount
      </div>
      <div class="quote-panel__total-price-desc">
        All prices include all applicable taxes (including
        {{ isInsurance ? 'IPT' : 'VAT' }}).
      </div>
    </div>

    <div class="quote-panel__footer">
      <div class="footer-header">Fast & secure payment</div>
      <wl-payment-icons
        [showDirectDebitIcon]="isDDPaymentAvailable"
        [showCardIcons]="isCardPaymentAvailable"
      ></wl-payment-icons>
    </div>
  </ui-panel-body>
  <ui-panel-summary class="quote-panel">
    <div class="quote-panel__row quote-panel__total-price">
      <div class="quote-panel__label">Total to pay</div>

      <div class="quote-panel__value" *ngIf="!isCard">
        £{{ DDPaymentOption?.subsequentPayment || 0 | number: '1.2-2' }}
        a month
      </div>
      <div class="quote-panel__value" *ngIf="isCard">
        £{{ cardPaymentOption?.fee || 0 | number: '1.2-2' }}
      </div>
    </div>
    <div
      class="quote-panel__row quote-panel__yearly-price"
      *ngIf="!isCard && periodOfCover"
    >
      <div class="quote-panel__label"></div>
      <div class="quote-panel__value">
        12 months for £{{ DDPaymentOption?.fee || 0 | number: '1.2-2' }}
      </div>
    </div>
  </ui-panel-summary>
</ui-panel>
