import { createReducer, on } from '@ngrx/store';
import { ApplianceFormSubmit } from '@common/util-models';
import * as ApplianceDetailsActions from './appliance-details.actions';

export const FORM_DATA = 'formData';

export interface ApplianceDetailsFormState {
  formData?: ApplianceFormSubmit;
}

export const initialState: ApplianceDetailsFormState = {
  formData: undefined,
};

export const formDataReducer = createReducer(
  initialState,
  on(ApplianceDetailsActions.createQuote, (state, { formData }) => ({
    ...state,
    formData,
  }))
);
