<div class="form-group">
  <div class="flex form-group__header">
    <label class="label" [for]="field?.controlName">
      {{ field?.label?.text }}
      <dgx-dfb-tooltip
        [tooltip]="field?.tooltip"
        [tooltipName]="tooltipName"
        *ngIf="field?.tooltip"
      ></dgx-dfb-tooltip>
    </label>
  </div>

  <ng-container dgxDfbTooltipTarget [tooltipName]="tooltipName"></ng-container>

  <div class="month-year-wrapper">
    <div
      class="select form-control"
      [ngClass]="{
        'is-invalid': isInvalid.month || (!isValid.month && !!errorMessage),
        'is-valid': isValid.month
      }"
    >
      <div class="wrapper">
        <select
          class="form-control"
          [class.select--empty]="value.month === undefined"
          #monthSelect
          [name]="field?.controlName + '-month'"
          (change)="saveMonth($event, monthSelect.value)"
          onfocusout="updateValidity($event)"
          [attr.aria-label]="field?.monthLabel?.text"
        >
          <option disabled [selected]="value.month === undefined">
            Select month
          </option>
          <option
            *ngFor="let month of field?.monthOptions"
            [value]="month"
            [selected]="(value$ | async)?.month === month"
          >
            {{ monthNames[month - 1] }}
          </option>
        </select>
        <i class="icon icon-arrow-down"></i>
        <i class="icon icon-tick-circle icon--xxs"></i>
        <i class="icon icon-error-circle icon--xxs"></i>
      </div>
    </div>

    <div
      class="select form-control"
      [ngClass]="{
        'is-invalid': isInvalid.year || (!isValid.year && !!errorMessage),
        'is-valid': isValid.year
      }"
    >
      <div class="wrapper">
        <select
          class="form-control"
          [class.select--empty]="value.year === undefined"
          #yearSelect
          [name]="field?.controlName + '-year'"
          (change)="saveYear($event, yearSelect.value)"
          onfocusout="updateValidity($event)"
          [attr.aria-label]="field?.yearLabel?.text"
        >
          <option disabled [selected]="value.year === undefined">
            Select year
          </option>
          <option
            *ngFor="let year of field?.yearOptions"
            [value]="year"
            [selected]="(value$ | async)?.year === year"
          >
            {{ year }}
          </option>
        </select>
        <i class="icon icon-arrow-down"></i>
        <i class="icon icon-tick-circle icon--xxs"></i>
        <i class="icon icon-error-circle icon--xxs"></i>
      </div>
    </div>
  </div>
  <dgx-dfb-error
    *ngIf="errorMessage"
    [errorMessage]="errorMessage"
  ></dgx-dfb-error>
  <div *ngIf="field?.hint" class="form-group__hint">{{ field?.hint }}</div>
</div>
