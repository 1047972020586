import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { PersonalDetailsPartialState } from '@common/data-access-personal-details';
import { QuotesPartialState } from '@common/data-access-quotes';
import { UserProfilePartialState } from '@common/data-access-user-profile';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CardPaymentApiService } from '../services/card-payment-api.service';
import * as CardPaymentActions from './card-payment.actions';
import * as CardPaymentSelectors from './card-payment.selectors';
import { CardPaymentPartialState } from './card-payment.reducer';
import { EMPTY, of } from 'rxjs';
import { BuildConfigService, ErrorService } from '@common/util-foundation';
import { retryPayingByDirectDebit } from '@common/data-access-shared';
import { selectIsAuthenticated } from '@domgen/dgx-fe-auth';
import { Router } from '@angular/router';

@Injectable()
export class CardPaymentEffects {
  setupCardPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CardPaymentActions.setupCardPayment),
      withLatestFrom(
        this.store$.pipe(
          select(CardPaymentSelectors.getCardPaymentSetupRequest)
        )
      ),
      switchMap(([, setupRequest]) =>
        setupRequest
          ? this.cardPaymentService.setupCardPayment(setupRequest).pipe(
              map((cardPaymentSetupInformation) =>
                CardPaymentActions.setupCardPaymentSuccess({
                  cardPaymentSetupInformation,
                })
              ),
              catchError((error) => {
                this.handleError();
                return of(
                  CardPaymentActions.setupCardPaymentFailure({ error })
                );
              })
            )
          : EMPTY
      )
    )
  );

  retryWithDirectDebitPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(retryPayingByDirectDebit),
        withLatestFrom(this.store$.pipe(select(selectIsAuthenticated))),
        tap(([, isLoggedOnUser]) =>
          isLoggedOnUser
            ? this.router.navigateByUrl(
                this.buildConfig.config.checkoutLoggedInUserPage
              )
            : this.router.navigateByUrl(
                this.buildConfig.config.checkoutDirectDebitPage
              )
        )
      ),
    { dispatch: false }
  );

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private cardPaymentService: CardPaymentApiService,
    private store$: Store<
      PersonalDetailsPartialState &
        QuotesPartialState &
        UserProfilePartialState &
        CardPaymentPartialState
    >,
    private errorService: ErrorService,
    private buildConfig: BuildConfigService
  ) {}
}
