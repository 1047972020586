import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  Appliance,
  ApplianceDetailsConfig,
  ApplianceFormFields,
  ApplianceFormSubmit,
  ApplianceFormSubmitNoCode,
  Brand,
} from '@common/util-models';
import { InvalidFormSubmissionPayload } from '@domgen/dgx-fe-dynamic-form-builder';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { ApplianceDetailsFormConfigService } from './appliance-details-form-config.service';
import { ApplianceDetailsFormStateService } from './appliance-details-form-state.service';

@Component({
  selector: 'sales-appliance-details-form',
  templateUrl: './appliance-details-form.component.html',
  styleUrls: ['./appliance-details-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ApplianceDetailsFormStateService,
    ApplianceDetailsFormConfigService,
  ],
})
export class ApplianceDetailsFormComponent {
  @Output() appliance = this.applianceDetailsFormService.appliance$;

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  @Output()
  invalidSubmit: EventEmitter<InvalidFormSubmissionPayload> = new EventEmitter();

  @Output() validatedSubmit = this.applianceDetailsFormService.validatedSubmit$;

  @Input() set cmsFormData(value: ApplianceFormFields | null) {
    if (value) {
      this.applianceDetailsFormService.setCmsFormData(value);
    }
  }

  @Input() set prefilledForm(value: ApplianceFormSubmit | undefined | null) {
    if (value) {
      this.applianceDetailsFormService.setPrefilledFormData(value);
    }
  }

  @Input() set appliances(value: Appliance[] | null) {
    if (value) {
      this.applianceDetailsFormService.setAppliances(value);
    }
  }

  @Input() set brands(value: Brand[] | null) {
    if (value) {
      this.applianceDetailsFormService.setBrands(value);
    }
  }

  @Input() set fieldsOptions(config: ApplianceDetailsConfig | null) {
    if (config?.formControlsToHide) {
      this.applianceDetailsFormService.setControlsToHideData(
        config.formControlsToHide
      );
    }
    if (config?.fixedValues) {
      this.applianceDetailsFormService.setFixedValues(config.fixedValues);
    }
    if (config?.controlTypes) {
      this.applianceDetailsFormService.setControlTypes(config.controlTypes);
    }
    if (config?.maxAge) {
      this.applianceDetailsFormService.setMaxAge(config.maxAge);
    }

    if (config?.showInWarrantyStopMsg) {
      this.applianceDetailsFormService.setPreventInWarrantySales(
        config.showInWarrantyStopMsg
      );
    }
  }

  readonly vm$ = this.applianceDetailsFormService.vm$;

  constructor(
    private readonly applianceDetailsFormService: ApplianceDetailsFormStateService
  ) {}

  handleValidFormSubmission(value: ApplianceFormSubmitNoCode) {
    this.applianceDetailsFormService.updateSubmittedForm(value);
  }

  handleInvalidFormSubmission(value: InvalidFormSubmissionPayload) {
    this.invalidSubmit.emit(value);
  }

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }

  handleValueChanges(event: unknown) {
    this.applianceDetailsFormService.updateFormValue(event);
  }
}
