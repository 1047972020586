<div class="form-group">
  <div class="checkbox">
    <label
      class="checkbox__label"
      [ngClass]="{
        'checkbox--readonly': isReadonly,
        'checkbox--checked': isChecked,
        'checkbox--disabled': field?.disabled
      }"
    >
      <input
        type="checkbox"
        class="checkbox__input"
        checked="isChecked"
        [disabled]="isReadonly || field?.disabled"
        (click)="onClick()"
      />
      <span class="focus"></span>
      <span class="icon icon-tick"></span>
    </label>

    <div [class]="field?.label?.classes || 'checkbox__text'">
      {{ field?.label?.text }}
    </div>
  </div>

  <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
</div>
