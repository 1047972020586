import { createAction, props } from '@ngrx/store';
import {
  CheckoutRequest,
  CheckoutResponse,
  ValidateDirectDebitDetailsRequest,
  ValidateDirectDebitDetailsResponse,
} from '@common/util-models';

export const checkoutGuest = createAction(
  '[CheckoutDirectDebitComponent] Checkout as guest user'
);

export const checkoutLoggedInUser = createAction(
  '[CheckoutLoggedInUserComponent] Checkout as logged in user'
);

export const startCheckout = createAction(
  '[CheckoutEffects] Start checkout',
  props<{ checkoutRequest: CheckoutRequest }>()
);

export const validateDirectDebitDetails = createAction(
  '[CheckoutEffects] Validate direct debit details',
  props<{
    checkoutRequest: CheckoutRequest;
    validateDirectDebitRequest: ValidateDirectDebitDetailsRequest;
  }>()
);

export const validateDirectDebitDetailsSuccess = createAction(
  '[CheckoutEffects] Direct debit details validated successfully',
  props<{
    checkoutRequest: CheckoutRequest;
    validateDirectDebitResult: ValidateDirectDebitDetailsResponse;
  }>()
);

export const validateDirectDebitDetailsFailure = createAction(
  '[CheckoutEffects] Validate direct debit details call failed',
  props<{ error: Error }>()
);

export const checkoutCardPayment = createAction(
  '[CheckoutEffects] Checkout using card payment',
  props<{ checkoutRequest: CheckoutRequest }>()
);

export const checkoutDirectDebitPayment = createAction(
  '[CheckoutEffects] Checkout using direct debit payment',
  props<{ checkoutRequest: CheckoutRequest }>()
);

export const checkoutSuccess = createAction(
  '[CheckoutEffects] Checkout completed successfully',
  props<{ checkoutResponse: CheckoutResponse }>()
);

export const checkoutFailure = createAction(
  '[CheckoutEffects] Checkout failed',
  props<{ error: Error }>()
);

export const exitingCheckoutLoggedInUser = createAction(
  '[CheckoutLoggedInUserComponent] Exiting the checkout page'
);

export const exitingCheckoutGuestUser = createAction(
  '[CheckoutDirectDebitComponent] Exiting the checkout page'
);

export const showPaymentDetailsSummaryPage = createAction(
  '[CheckoutEffects] show payment details summary page'
);

export const paymentDetailsSummaryOnSubmit = createAction(
  '[PaymentDetailsSummaryComponent] payment details summary on submit'
);
