<div class="form-input form-group">
  <label class="label"> {{ field?.label?.text }}</label>
  <div class="radio" [ngClass]="field?.classes || []">
    <label
      *ngFor="let option of field?.options"
      (click)="writeValue(option, true)"
    >
      <input
        class="form-control"
        type="radio"
        [name]="field?.controlName"
        [value]="option"
        [checked]="value === option"
        (click)="handleClick($event)"
      />
      <span>{{ option }}</span>
    </label>

    <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
  </div>
</div>
