import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import {
  Appliance,
  ApplianceDetailsConfig,
  ApplianceFormField,
  ApplianceFormFields,
  ApplianceFormSubmit,
  ApplianceFormSubmitNoCode,
  Brand,
  FixedFormValues,
} from '@common/util-models';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApplianceDetailsFormConfigService } from './appliance-details-form-config.service';

type FormValues = {
  [ApplianceFormField.Appliance]: string;
  [ApplianceFormField.GoodWorkingOrder]: string;
  [ApplianceFormField.UnderGuarantee]: string;
  [ApplianceFormField.PurchaseDate]: { month: number; year: number };
  [ApplianceFormField.Brand]: string;
};

export interface ApplianceDetailsFormState {
  appliances: Appliance[];
  appliance: string | undefined;
  brands: Brand[];
  brand: string | undefined;
  formBuilderConfig: FieldDef[];
  formControlsToHide: ApplianceFormField[];
  formValue: FormValues;
  cmsFormData: ApplianceFormFields;
  form: FormGroup;
  prefilledForm: ApplianceFormSubmitNoCode | undefined;
  submittedForm: ApplianceFormSubmitNoCode | undefined;
  applianceNotWorking: boolean;
  fixedValues: FixedFormValues | undefined;
  controlTypes?: Partial<Record<ApplianceFormField, string>>;
  maxAge?: number;
  inWarrantyStopMsg?: boolean;
  preventInWarrantySales?: ApplianceDetailsConfig['showInWarrantyStopMsg'];
  disableBrandSelection?: boolean;
}

const BOILER_CATEGORY = 'Boilers';
const DEFAULT_PURCHASE_PRICE = 10;

@Injectable()
export class ApplianceDetailsFormStateService extends ComponentStore<ApplianceDetailsFormState> {
  // ***** Selectors *****
  readonly fixedValues$ = this.select(
    (state: ApplianceDetailsFormState) => state.fixedValues
  );

  readonly appliance$ = this.select(
    (state: ApplianceDetailsFormState) => state.appliance
  );

  readonly submittedForm$ = this.select(
    (state: ApplianceDetailsFormState) => state.submittedForm
  ).pipe(filter((submittedForm) => !!submittedForm));

  readonly appliances$ = this.select(
    (state: ApplianceDetailsFormState) => state.appliances
  );

  // Typeahead component needs a list of strings. We use this selector to obtain a list of
  // strings from a list of Item[]
  readonly appliancesOptions$ = this.select(
    this.appliances$,
    (appliances: Appliance[]) =>
      appliances.map((appliance) => appliance.description)
  );

  readonly disableBrandSelection$ = this.select(
    (state: ApplianceDetailsFormState) => state.disableBrandSelection
  ).pipe(map((disableBrandSelection) => !!disableBrandSelection));

  readonly controlType$ = this.select(
    (state: ApplianceDetailsFormState) => state.controlTypes
  );

  readonly maxAge$ = this.select(
    (state: ApplianceDetailsFormState) => state.maxAge
  );

  readonly brand$ = this.select(
    (state: ApplianceDetailsFormState) => state.brand
  );

  validatedSubmit$ = this.select(
    this.appliance$,
    this.brand$,
    this.submittedForm$,
    (applianceCode, brandCode, submittedForm) =>
      ({
        ...submittedForm,
        applianceCode,
        brandCode,
      } as ApplianceFormSubmit)
  );

  // Typeahead component needs a list of strings. We use this selector to obtain a list of
  // strings from a list of Manufacturer[]
  readonly brands$ = this.select(
    (state: ApplianceDetailsFormState) => state.brands
  );

  readonly brandsOptions$ = this.select(this.brands$, (brands: Brand[]) =>
    brands.map((brand) => brand.description)
  );

  readonly formBuilderConfig$ = this.select(
    (state: ApplianceDetailsFormState) => state.formBuilderConfig
  );

  readonly formGroup$ = this.select(
    (state: ApplianceDetailsFormState) => state.form
  );

  readonly formValue$ = this.select(
    (state: ApplianceDetailsFormState) => state.formValue
  );

  readonly applianceNotWorking$ = this.select(
    (state: ApplianceDetailsFormState) => state.applianceNotWorking
  );

  readonly cmsFormData$ = this.select(
    (state: ApplianceDetailsFormState) => state.cmsFormData
  );

  readonly prefilledForm$ = this.select(
    (state: ApplianceDetailsFormState) => state.prefilledForm
  );

  readonly formControlsToHide$ = this.select(
    (state: ApplianceDetailsFormState) => state.formControlsToHide
  );

  readonly preventInWarrantySales$ = this.select(
    (state: ApplianceDetailsFormState) => state.preventInWarrantySales
  );

  // *** Updater Sources ****
  private fieldDefinitionUpdater$ = combineLatest([
    this.cmsFormData$,
    this.controlType$,
    this.maxAge$,
  ]).pipe(
    filter(([cmsData]) => !!cmsData && !!cmsData.length),
    switchMap(([cmsData, controlTypes, maxAge]) =>
      of(cmsData).pipe(
        withLatestFrom(this.prefilledForm$),
        // eslint-disable-next-line no-shadow
        map(([cmsData, prefilledForm]) =>
          this.applianceDetailsFormConfig.getFormbuilderConfig(
            cmsData,
            this.appliancesOptions$,
            this.brandsOptions$,
            controlTypes,
            maxAge,
            prefilledForm,
            this.disableBrandSelection$
          )
        )
      )
    )
  );

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    switchMap((formBuilderConfig) =>
      of(formBuilderConfig).pipe(
        // eslint-disable-next-line no-shadow
        map((formBuilderConfig) =>
          this.dynamicFormBuilder.generateFormGroup(formBuilderConfig)
        )
      )
    )
  );

  private inWarrantyByDate$ = combineLatest([
    this.formValue$,
    this.brand$,
    this.brands$,
  ]).pipe(
    map(([formValue, selectedBrand, brands]) => {
      const purchaseDate = formValue[ApplianceFormField.PurchaseDate];
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const brandWarrantyInMonths = brands?.find(
        (brand) => brand.code === selectedBrand
      )?.warranty;
      const userEnteredMonths =
        (currentYear - purchaseDate?.year) * 12 +
        (currentMonth - purchaseDate?.month);
      return brandWarrantyInMonths && userEnteredMonths < brandWarrantyInMonths;
    })
  );

  private preventInWarrantySalesDynamic$ = this.formValue$.pipe(
    withLatestFrom(this.preventInWarrantySales$, this.appliances$),
    map(([formValue, inWarrantyStopSubmission, appliances]) => {
      const appliance = formValue[ApplianceFormField.Appliance];
      const selectedAppliance = appliances.find(
        (x: Appliance) => x.description === appliance
      );
      const applianceCategory = selectedAppliance?.category;

      return this.applianceCategoryInWarranty(
        applianceCategory,
        inWarrantyStopSubmission
      );
    })
  );

  private inWarrantyByDateStopSubmission$ = combineLatest([
    this.inWarrantyByDate$,
    this.preventInWarrantySalesDynamic$,
  ]).pipe(
    map(
      ([inWarrantyByDate, inWarrantyStopSubmission]) =>
        inWarrantyStopSubmission && inWarrantyByDate
    )
  );

  private inWarrantyStopSubmission$ = combineLatest([
    this.formValue$,
    this.preventInWarrantySalesDynamic$,
  ]).pipe(
    map(
      ([formValue, inWarrantyStopSubmission]) =>
        inWarrantyStopSubmission &&
        formValue[ApplianceFormField.UnderGuarantee] === 'Yes'
    )
  );

  //Used by EDF (always hide price field) and Sales (Hide price field for boilers only)
  private fieldsWithFixedValues$ = this.formValue$.pipe(
    withLatestFrom(this.appliances$, this.fixedValues$),
    map(([formValue, appliances, fixedValues]) => {
      const fixedValueControls: ApplianceFormField[] = fixedValues
        ? (Object.keys(fixedValues) as ApplianceFormField[])
        : [];
      const appliance = formValue[ApplianceFormField.Appliance];
      const selectedAppliance = appliances.find(
        (x) => x.description === appliance
      );
      const heatingCategory =
        !!selectedAppliance && selectedAppliance.category === BOILER_CATEGORY;
      return heatingCategory
        ? [...fixedValueControls, ApplianceFormField.PurchasePrice]
        : [...fixedValueControls];
    })
  );

  private controlsToHideUpdater$ = this.formValue$.pipe(
    withLatestFrom(
      this.inWarrantyStopSubmission$,
      this.inWarrantyByDateStopSubmission$,
      this.fieldsWithFixedValues$
    ),
    map(
      ([
        formValue,
        inWarrantyStopSubmission,
        inWarrantyByDateStopSubmission,
        fixedValueControls,
      ]) => {
        let controlsToHide: ApplianceFormField[] = [];
        const goodWorkingOrder = formValue[ApplianceFormField.GoodWorkingOrder];
        if (goodWorkingOrder === 'No') {
          controlsToHide = [
            ApplianceFormField.UnderGuarantee,
            ApplianceFormField.PurchaseDate,
            ApplianceFormField.PurchasePrice,
            ApplianceFormField.GetQuote,
            ApplianceFormField.Submit,
          ];
        } else if (inWarrantyByDateStopSubmission) {
          controlsToHide = [
            ApplianceFormField.UnderGuarantee,
            ApplianceFormField.Submit,
          ];
        } else if (inWarrantyStopSubmission) {
          controlsToHide = [ApplianceFormField.Submit];
        }
        return [...fixedValueControls, ...controlsToHide];
      }
    )
  );

  private applianceNotWorkingUpdater$ = this.formValue$.pipe(
    map((formValue) => formValue[ApplianceFormField.GoodWorkingOrder] === 'No')
  );

  // The Typeahead component needs a list of strings. We use this updater to map the selected description
  // to its corresponding code value. We need code to drive the API to fetch the corresponding Manufacturers from the backend
  private applianceUpdater$ = this.formGroup$.pipe(
    withLatestFrom(this.formBuilderConfig$),
    switchMap(([form, config]) =>
      this.dynamicFormBuilder.selectFieldValue$(
        form,
        ApplianceFormField.Appliance,
        config,
        true
      )
    ),
    withLatestFrom(this.appliances$),
    map(
      ([selectedAppliance, options]: [string, Appliance[]]) =>
        options.find(
          (option) =>
            option.description.toLowerCase() === selectedAppliance.toLowerCase()
        )?.code
    ),
    filter((appliance) => !!appliance)
  );

  private purchasePriceUpdater$ = this.fieldsWithFixedValues$.pipe(
    withLatestFrom(this.formGroup$),
    map(([fieldsWithFixedValue, formGroup]) => {
      const defaultPurchasePrice = DEFAULT_PURCHASE_PRICE;
      const purchasePriceHasFixedValue = fieldsWithFixedValue.includes(
        ApplianceFormField.PurchasePrice
      );
      if (formGroup) {
        const purchasePriceValue = formGroup.get(
          ApplianceFormField.PurchasePrice
        )?.value;
        // Only set if different to default value
        if (
          purchasePriceHasFixedValue &&
          purchasePriceValue !== defaultPurchasePrice
        ) {
          formGroup
            ?.get(ApplianceFormField.PurchasePrice)
            ?.setValue(defaultPurchasePrice);
        }
      }
      return defaultPurchasePrice;
    })
  );

  // The Typeahead component needs a list of strings. We use this updater to map the selected description
  // to its corresponding code value. We need code to drive the API get the quote
  private brandUpdater$ = this.formGroup$.pipe(
    withLatestFrom(this.formBuilderConfig$),
    switchMap(([form, config]) =>
      this.dynamicFormBuilder.selectFieldValue$(
        form,
        ApplianceFormField.Brand,
        config,
        true
      )
    ),
    withLatestFrom(this.brands$),
    map(
      ([selectedBrand, options]: [string, Brand[]]) =>
        options.find(
          (option) =>
            option.description.toLocaleLowerCase() ===
            selectedBrand.toLocaleLowerCase()
        )?.code
    ),
    filter((brand) => !!brand)
  );

  // ***** Updaters *****
  readonly setPreventInWarrantySales = this.updater(
    (
      state: ApplianceDetailsFormState,
      value: ApplianceDetailsFormState['preventInWarrantySales']
    ) => ({
      ...state,
      preventInWarrantySales: value,
    })
  );

  readonly setFixedValues = this.updater(
    (state: ApplianceDetailsFormState, value: FixedFormValues) => ({
      ...state,
      fixedValues: value,
    })
  );

  // ***** Updaters *****
  readonly setControlTypes = this.updater(
    (
      state: ApplianceDetailsFormState,
      value: Partial<Record<ApplianceFormField, string>>
    ) => ({
      ...state,
      controlTypes: value,
    })
  );
  // ***** Updaters *****
  readonly setMaxAge = this.updater(
    (state: ApplianceDetailsFormState, value: number) => ({
      ...state,
      maxAge: value,
    })
  );

  readonly setBrands = this.updater(
    (state: ApplianceDetailsFormState, value: Brand[]) => {
      return {
        ...state,
        brands: value,
        disableBrandSelection:
          value && value.length && state.appliance ? false : true,
      };
    }
  );

  readonly setAppliances = this.updater(
    (state: ApplianceDetailsFormState, value: Appliance[]) => ({
      ...state,
      appliances: value,
    })
  );

  readonly setCmsFormData = this.updater(
    (state: ApplianceDetailsFormState, value: ApplianceFormFields) => ({
      ...state,
      cmsFormData: value,
    })
  );

  readonly setPrefilledFormData = this.updater(
    (state: ApplianceDetailsFormState, value: ApplianceFormSubmit) => ({
      ...state,
      prefilledForm: value,
      appliance: value.applianceCode,
      brand: value.brandCode,
    })
  );

  readonly setFormData = this.updater(
    (state: ApplianceDetailsFormState, value: ApplianceFormFields) => ({
      ...state,
      cmsFormData: value,
    })
  );

  readonly setControlsToHideData = this.updater(
    (state: ApplianceDetailsFormState, value: ApplianceFormField[]) => ({
      ...state,
      formControlsToHide: value,
    })
  );

  readonly updateSubmittedForm = this.updater(
    (
      state: ApplianceDetailsFormState,
      submittedForm: ApplianceFormSubmitNoCode
    ) => ({
      ...state,
      submittedForm,
    })
  );

  readonly updateFormValue = this.updater(
    (state: ApplianceDetailsFormState, value: unknown) => ({
      ...state,
      formValue: value as FormValues,
    })
  );

  private readonly controlsToHideUpdater = this.updater(
    (state: ApplianceDetailsFormState, value: ApplianceFormField[]) => {
      return {
        ...state,
        formControlsToHide: value,
      };
    }
  )(this.controlsToHideUpdater$);

  private readonly applianceNotWorkingUpdater = this.updater(
    (state: ApplianceDetailsFormState, value: boolean) => ({
      ...state,
      applianceNotWorking: value,
    })
  )(this.applianceNotWorkingUpdater$);

  private readonly selectedBrandUpdater = this.updater(
    (state: ApplianceDetailsFormState, value: string | undefined) => ({
      ...state,
      brand: value,
    })
  )(this.brandUpdater$);

  private readonly selectedApplianceUpdater = this.updater(
    (state: ApplianceDetailsFormState, value: string | undefined) => ({
      ...state,
      appliance: value,
    })
  )(this.applianceUpdater$);

  private readonly cmsFieldDefinitionUpdater = this.updater(
    (state: ApplianceDetailsFormState, value: FieldDef[]) => ({
      ...state,
      formBuilderConfig: value,
    })
  )(this.fieldDefinitionUpdater$);

  private readonly formGroupUpdater = this.updater(
    (state: ApplianceDetailsFormState, value: FormGroup) => ({
      ...state,
      form: value,
    })
  )(this.formGroupUpdater$);

  private readonly purchasePriceUpdater = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state: ApplianceDetailsFormState, value: number) => ({
      ...state,
    })
  )(this.purchasePriceUpdater$);

  // **** Effects ****
  readonly clearPreviousBrandSelection = this.effect(
    (appliance$: Observable<string | undefined>) => {
      return appliance$.pipe(
        withLatestFrom(this.formGroup$),
        tap(([, formGroup]) => {
          formGroup?.get(ApplianceFormField.Brand)?.reset();
          formGroup?.get(ApplianceFormField.Brand)?.markAsUntouched();
          formGroup?.get(ApplianceFormField.Brand)?.setErrors(null);
          formGroup?.get(ApplianceFormField.Brand)?.updateValueAndValidity();
        })
      );
    }
  )(this.appliance$);

  readonly vm$ = this.select(
    this.formBuilderConfig$,
    this.formGroup$,
    this.formControlsToHide$,
    this.applianceNotWorking$,
    this.inWarrantyStopSubmission$,
    this.inWarrantyByDateStopSubmission$,
    (
      fieldDef,
      formGroup,
      formControlsToHide,
      applianceNotWorking,
      inWarrantyStopSubmission,
      inWarrantyByDateStopSubmission
    ) => ({
      fieldDef,
      formGroup,
      formControlsToHide,
      applianceNotWorking,
      inWarrantyStopSubmission,
      inWarrantyByDateStopSubmission,
    })
  );

  private applianceCategoryInWarranty(
    applianceCategory: string | undefined,
    inWarrantyStopSubmission:
      | ApplianceDetailsConfig['showInWarrantyStopMsg']
      | undefined
  ): boolean | undefined {
    if (
      applianceCategory &&
      inWarrantyStopSubmission?.applianceCategories &&
      typeof inWarrantyStopSubmission?.applianceCategories[
        applianceCategory
      ] !== 'undefined'
    ) {
      return inWarrantyStopSubmission?.applianceCategories[applianceCategory];
    }

    return inWarrantyStopSubmission?.default;
  }

  constructor(
    private applianceDetailsFormConfig: ApplianceDetailsFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      appliance: '',
      appliances: [],
      brand: '',
      brands: [],
      formBuilderConfig: [],
      formControlsToHide: [],
      formValue: {} as FormValues,
      cmsFormData: [],
      form: new FormGroup({}),
      submittedForm: undefined,
      applianceNotWorking: false,
      fixedValues: undefined,
      preventInWarrantySales: { default: false, applianceCategories: {} },
      prefilledForm: undefined,
      disableBrandSelection: false,
    });
  }
}
