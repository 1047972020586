import { Injectable } from '@angular/core';
import { CoverPaymentOptions } from '@common/util-models';

@Injectable()
export class PaymentOptionsService {
  isDirectDebitCheaperThanCreditCard(paymentOptions: CoverPaymentOptions) {
    return (
      paymentOptions?.directDebit &&
      paymentOptions?.card &&
      paymentOptions?.directDebit.fee < paymentOptions?.card.fee
    );
  }

  isFirstPaymentAndSubsequentPaymentsSame(paymentOptions: CoverPaymentOptions) {
    return (
      paymentOptions?.directDebit &&
      paymentOptions?.directDebit?.firstPayment ===
        paymentOptions?.directDebit?.subsequentPayment
    );
  }

  discount(paymentOptions: CoverPaymentOptions) {
    return paymentOptions?.directDebit && paymentOptions?.card
      ? paymentOptions?.card.fee - paymentOptions?.directDebit.fee
      : 0;
  }

  price(paymentOptions: CoverPaymentOptions) {
    const discount = this.discount(paymentOptions);
    if (paymentOptions.directDebit) {
      return (paymentOptions.directDebit.fee + discount).toFixed(2);
    } else if (paymentOptions.card) {
      return (paymentOptions.card.fee + discount).toFixed(2);
    }
    return 0;
  }
}
