<div class="accept">
  <div class="documents">
    For your own benefit and protection, please read the
    <a
      target="_blank"
      class="link"
      href="https://sit-cms.domesticandgeneral.com/dam/jcr:2790c839-1da2-4638-a897-57a283082681/DG_WTCHE_1118_29.pdf"
      >Plan Terms and Conditions</a
    >
    and
    <a
      target="_blank"
      class="link"
      href="https://www.domesticandgeneral.com/privacy-cookies-policy"
      >Privacy Policy</a
    >
    carefully before purchase.
  </div>
</div>
