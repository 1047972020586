<div class="pb-1">
  <ng-content> </ng-content>
</div>
<div class="tc-container">
  <div class="pt-1" *ngIf="field">
    <dgx-dfb-checkbox
      [field]="field"
      (checked)="onAcceptanceChange($event)"
      [validate]="validate"
    ></dgx-dfb-checkbox>
  </div>
  <div class="tc-text">
    <ng-content select="[checkbox-text]"></ng-content>
  </div>
</div>
