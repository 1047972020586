import {
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  CoverPaymentOptions,
  PaymentType,
} from '@common/util-models';

export const convertPaymentOptionsToObject = (
  paymentOptions: BasketItemPaymentOption[]
): CoverPaymentOptions => ({
  card: paymentOptions.find((po) => po.paymentType === PaymentType.Card),
  directDebit: paymentOptions.find(
    (po) => po.paymentType === PaymentType.DirectDebit
  ),
});

export const transformQuoteDetailToCover = ({
  paymentOptions,
  ...cover
}: BasketItemQuoteDetails) => {
  return {
    ...cover,
    paymentOptions: convertPaymentOptionsToObject(paymentOptions),
  };
};
