import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  PaymentType,
  QuoteSummaryConfigInput,
  QuoteSummaryDataInput,
} from '@common/util-models';

@Component({
  selector: 'wl-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss'],
})
export class QuoteSummaryComponent implements OnChanges {
  @Input() data: QuoteSummaryDataInput | null = null;
  @Input() config: QuoteSummaryConfigInput | null = null;

  title = '';
  selectedPaymentType: PaymentType = PaymentType.DirectDebit;
  ngOnChanges(changes: SimpleChanges): void {
    // avoid race condition
    if ((changes.config || changes.data) && this.config && this.data) {
      switch (this.config.whichTitle) {
        case 'brandAppliance':
          this.title = `${this.data.quote.brandName} ${this.data.quote.applianceName}`;
          break;
        case 'selectedCoverName':
          this.title = `${this.data.quote.cover.name}`;
          break;
      }
      this.selectedPaymentType =
        this.data.selectedPaymentType || PaymentType.DirectDebit;
    }
  }
}
