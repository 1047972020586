<div
  *ngIf="stickyAutoExpand"
  class="panel__sticky-checker"
  #stickyChecker
></div>
<div class="panel__inner panel--{{variant}}">
  <!--  use select to ensure correct order -->
  <ng-content select="ui-panel-header"></ng-content>
  <ng-content select="ui-panel-summary"></ng-content>
  <ng-content select="ui-panel-body"></ng-content>
</div>
