import { Inject, Injectable } from '@angular/core';
import { GaTags, GoogleTagManager } from '@common/util-models';
import { GOOGLE_TAG_MANAGER } from '../tokens/gtm.token';

@Injectable({
  providedIn: 'root',
})
export class GaTaggingService {
  constructor(@Inject(GOOGLE_TAG_MANAGER) private gtm: GoogleTagManager) {}

  tag(data: GaTags) {
    console.log('data:::', data);
    this.gtm.push(data);
  }
}
