import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoversExclusionTabs, QuoteCms } from '@common/util-models';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';

export const COVERS_EXCLUSIONS_CONTROL_NAME = 'CoversExclusions';
@Component({
  selector: 'sales-covers-exclusions',
  templateUrl: './covers-exclusions.component.html',
  styleUrls: ['./covers-exclusions.component.scss'],
})
export class CoversExclusionsComponent {
  @Input() cmsData!: QuoteCms | null | undefined;
  @Input() hideHeader = false;
  @Input() displayAs: 'tabs' | 'stacked' = 'tabs';

  get included() {
    return this.cmsData?.included;
  }
  get excluded() {
    return this.cmsData?.excluded;
  }

  @Input() appliance!: string | undefined | null;
  @Input() brand!: string | undefined | null;
  @Input() category!: string | undefined | null;
  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  get tabsHeading() {
    return `Protect your ${this.brand} ${this.appliance}`;
  }

  handleTabSelection({ index }: { index: number }) {
    index === 0
      ? this.handleWhatsIncludedClick()
      : this.handleWhatsExcludedClick();
  }

  handleWhatsExcludedClick() {
    this.analytics.emit({
      controlName: COVERS_EXCLUSIONS_CONTROL_NAME,
      value: CoversExclusionTabs.WhatsExcluded,
    });
  }

  handleWhatsIncludedClick() {
    this.analytics.emit({
      controlName: COVERS_EXCLUSIONS_CONTROL_NAME,
      value: CoversExclusionTabs.WhatsIncluded,
    });
  }
}
