import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CmsFormField } from '@domgen/dgx-fe-business-models';
import {
  LoggedInUserFormField,
  LoggedInUserFormFields,
  YourDetails,
} from '@common/util-models';
import * as CmsHelpers from '@domgen/dgx-fe-business-components';

@Component({
  selector: 'sales-your-details',
  templateUrl: './your-details.component.html',
  styleUrls: ['./your-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YourDetailsComponent implements OnChanges {
  @Input() personalDetails!: YourDetails | null;
  @Input() cmsFormData!: LoggedInUserFormFields | null;
  nameLabel = 'Name';
  emailLabel = 'Email';
  phoneNumberLabel = 'Phone number';
  addressLabel = 'Address';

  ngOnChanges(changes: SimpleChanges): void {
    const { cmsFormData } = changes;
    if (!!cmsFormData && !!this.cmsFormData) {
      this.updateLabels(this.cmsFormData);
    }
  }

  private updateLabels(cmsFormData: LoggedInUserFormFields) {
    this.nameLabel = this.getFieldLabel(
      cmsFormData,
      LoggedInUserFormField.Name
    );
    this.emailLabel = this.getFieldLabel(
      cmsFormData,
      LoggedInUserFormField.Email
    );
    this.phoneNumberLabel = this.getFieldLabel(
      cmsFormData,
      LoggedInUserFormField.PhoneNumber
    );
    this.addressLabel = this.getFieldLabel(
      cmsFormData,
      LoggedInUserFormField.Address
    );
  }

  private getFieldLabel(
    cmsFormData: LoggedInUserFormFields,
    fieldName: LoggedInUserFormField
  ) {
    return CmsHelpers.getFormField<CmsFormField>(cmsFormData, fieldName).label;
  }
}
