import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  OptionItem,
  SelectDef,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { of, Subscription } from 'rxjs';

@Component({
  selector: 'dgx-dfb-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class SelectComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  @Input() field!: SelectDef;
  @Input() validate!: boolean;

  value!: string;
  blurred = false;
  disabled$ = of(false);
  private controlStatusChangesubscriptions = new Subscription();

  options: string[] | OptionItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if(this.ngControl?.control){
      this.controlStatusChangesubscriptions.add(
        this.ngControl.control.statusChanges.subscribe((result) => {
          if (result === 'VALID') {
            super.updateErrorMessage();
          }
        })
      )
    }
    if (this.field?.disabledStream$) {
      this.disabled$ = this.field.disabledStream$;
    }
    if (this.field?.optionsStream$) {
      this.field.optionsStream$.subscribe((options) => {
        this.options = options;
      });
    } else if (this.field?.options && this.field?.options?.length) {
      this.options = this.field.options;
    }
    return super.ngOnChanges(changes);
  }

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.value = this.field?.initialValue || '';
  }

  ngOnDestroy(): void {
    if (this.controlStatusChangesubscriptions) {
      this.controlStatusChangesubscriptions.unsubscribe();
    }
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string): void {
    this.value = val;
    super.writeValue(val);
  }

  onChange(value: string) {
    const placeholder = this.field?.placeholder;
    if (placeholder && placeholder === value) {
      return;
    }
    this.writeValue(value);
    this.emitAnalyticsData(value);
  }
}
