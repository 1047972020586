import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BuildConfigService } from '@common/util-foundation';
import { tap } from 'rxjs/operators';
import * as ResetStateActions from './reset-state.actions';

@Injectable()
export class RestartJourneyEffects {
  restart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ResetStateActions.restartCheckoutGuest,
          ResetStateActions.restartCheckoutLoggedInUser,
          ResetStateActions.restartCheckoutBackButton
        ),
        tap(() =>
          this.router.navigateByUrl(
            this.buildConfigService.config.applianceDetailsPage
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private buildConfigService: BuildConfigService
  ) {}
}
