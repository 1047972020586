import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressTrackerRouteBasedComponent } from './progress-tracker-route-based/progress-tracker-route-based.component';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';
import { RadioListComponent } from './radio-list/radio-list.component';
import { RadioListItemComponent } from './radio-list-item/radio-list-item.component';
import { PanelComponentsModule } from './panel/panel-components.module';
import { CardComponentsModule } from './card/card-components.module';
import { BannerComponent } from './banner/banner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ProgressTrackerComponent,
    ProgressTrackerRouteBasedComponent,
    RadioListComponent,
    RadioListItemComponent,
    BannerComponent,
  ],
  exports: [
    ProgressTrackerComponent,
    ProgressTrackerRouteBasedComponent,
    RadioListComponent,
    RadioListItemComponent,
    PanelComponentsModule,
    CardComponentsModule,
    BannerComponent,
  ],
})
export class SharedUiBaseComponentsModule {}
