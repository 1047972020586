import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
} from '@angular/core';
import { TabComponent } from './components/tab/tab.component';

@Component({
  selector: 'sales-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  /**
   * Get a reference to the tabs
   */
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  @Output() tabSelected = new EventEmitter<{
    tab: TabComponent;
    index: number;
  }>();

  /**
   * Set contentChildren active state
   */
  ngAfterContentInit() {
    this.setDefaultTab();
  }

  /**
   * Get all active tabs | if there is no active tab set, activate the first
   */
  setDefaultTab() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (!activeTabs.length) {
      this.selectTab(this.tabs.first);
    }
  }

  /**
   * Deactivate all tabs and activate the tab passed in as a parameter
   */
  selectTab(selectedTab: TabComponent, event?: Event, index: number = 0) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    selectedTab.active = true;
    this.tabSelected.emit({ tab: selectedTab, index });

    if (event) {
      event.stopPropagation();
    }
  }
}
