import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as QuotesSelectors from './quotes.selectors';
import * as QuotesPageSelectors from './quotes-page.selectors';
import { filter, map } from 'rxjs/operators';
import {
  CoverPaymentOptions,
  Quote,
  TaggingMetadata,
} from '@common/util-models';
import { QuotesPartialState } from './quotes.reducer';
import { ApplianceDetailsSelectors } from '@common/data-access-appliance-details';

@Injectable()
export class SelectQuotePaymentOptionsFacade {
  isHeating$ = this.store.pipe(
    select(ApplianceDetailsSelectors.isHeatingAppliance)
  );

  paymentOptions$ = this.store.pipe(
    select(QuotesSelectors.getPaymentOptions),
    filter((paymentOptions) => !!paymentOptions)
  ) as Observable<CoverPaymentOptions>;

  quote$ = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  basketId$ = this.store.pipe(
    select(QuotesSelectors.getFirstItemQuote),
    filter((basketItem) => !!basketItem),
    map((basketItem) => basketItem?.basketId)
  ) as Observable<string>;

  taggingMetadata$ = this.store.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  constructor(private store: Store<QuotesPartialState>) {}
}
