import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  Appliance,
  ApplianceFormField,
  ApplianceFormSubmit,
} from '@common/util-models';
import { Subject, Subscription } from 'rxjs';
import { mapTo, tap, withLatestFrom } from 'rxjs/operators';
import * as ApplianceDetailsActions from './appliance-details.actions';
import * as fromApplianceDetails from './appliance-details.reducer';
import * as ApplianceDetailsSelectors from './appliance-details.selectors';

@Injectable()
export class ApplianceDetailsFacade implements OnDestroy {
  appliances$ = this.store.pipe(
    select(ApplianceDetailsSelectors.getAppliances)
  );

  selectedAppliance$ = this.store.pipe(
    select(ApplianceDetailsSelectors.getSelectedAppliance)
  );

  brands$ = this.store.pipe(select(ApplianceDetailsSelectors.getBrands));

  formData$ = this.store.pipe(select(ApplianceDetailsSelectors.getFormData));

  appliancesLoaded$ = this.store.pipe(
    select(ApplianceDetailsSelectors.getAppliancesLoaded)
  );

  brandsLoaded$ = this.store.pipe(
    select(ApplianceDetailsSelectors.getBrandsLoaded)
  );

  isHeating$ = this.store.pipe(
    select(ApplianceDetailsSelectors.isHeatingAppliance)
  );

  private createQuoteSubject = new Subject<ApplianceFormSubmit>();
  private createQuote$ = this.createQuoteSubject.asObservable().pipe(
    withLatestFrom(this.selectedAppliance$),
    tap(([formValues, selectedAppliance]) =>
      this.store.dispatch(
        ApplianceDetailsActions.createQuote({
          quoteRequest: this.getQuoteRequest(formValues, selectedAppliance),
          formData: formValues,
        })
      )
    ),
    mapTo(null)
  );
  private subscription = new Subscription();

  constructor(
    private store: Store<fromApplianceDetails.ApplianceDetailsState>
  ) {
    this.subscription.add(this.createQuote$.subscribe());
  }

  selectAppliance(itemCode: string) {
    this.store.dispatch(
      ApplianceDetailsActions.applianceSelected({ code: itemCode })
    );
  }

  createQuote(formValues: ApplianceFormSubmit) {
    this.createQuoteSubject.next(formValues);
  }

  private getQuoteRequest(
    formValues: ApplianceFormSubmit,
    selectedAppliance: Appliance | undefined
  ) {
    return {
      itemType: 'QUOTE',
      applianceCode: formValues.applianceCode,
      brandCode: formValues.brandCode,
      purchaseMonth: formValues[ApplianceFormField.PurchaseDate].month,
      purchaseYear: formValues[ApplianceFormField.PurchaseDate].year,
      purchasePrice: Number(formValues[ApplianceFormField.PurchasePrice]),
      applianceCategory: selectedAppliance?.category,
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
