import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export const filterFields = (
  ...formFields: string[]
): MonoTypeOperatorFunction<AnalyticsData> => {
  return (source$: Observable<AnalyticsData>) =>
    source$.pipe(
      filter((analyticsData) => formFields.includes(analyticsData.controlName))
    );
};
