import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { fadeInOut } from '../animations/animations';

@Component({
  selector: 'sales-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  /**
   * Receives a list of links from the app component
   */
  @Input() links!: { home: string; repair: string; faq: string };
  /**
   * Emits a click event back to the parent component
   */
  @Output() clicked: EventEmitter<unknown> = new EventEmitter();

  /**
   * Emits a toggled event back to the parent component
   */
  @Output() toggled: EventEmitter<string> = new EventEmitter();

  /**
   * Tracks menu open state
   */
  isOpen = false;

  /**
   * Toggle mobile menu
   */
  onToggleMenu() {
    this.isOpen = !this.isOpen;
    this.toggled.emit(this.isOpen ? 'open' : 'closed');
  }

  /**
   * Listens and fires emit `clicked` when clicked
   */
  onClick(e: Event) {
    this.clicked.emit(e);
    this.isOpen = false;
  }
}
