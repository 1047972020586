import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ApplianceDetailsState,
  APPLIANCEDETAILS_FEATURE_KEY,
} from './appliance-details.reducer';
import { appliancesAdapter, AppliancesState } from './appliances.reducer';
import { brandsAdapter, BrandsState } from './brands.reducer';
import { Appliance } from '@common/util-models';
import { ApplianceDetailsFormState } from './appliance-details-form.reducer';

// Lookup the 'ApplianceDetails' feature state managed by NgRx
export const getApplianceDetailsState = createFeatureSelector<ApplianceDetailsState>(
  APPLIANCEDETAILS_FEATURE_KEY
);

const {
  selectAll: selectAppliances,
  selectEntities: selectApplianceEntities,
} = appliancesAdapter.getSelectors();

const {
  selectAll: selectBrands,
  selectEntities: selectBrandEntities,
} = brandsAdapter.getSelectors();

export const getAppliancesState = createSelector(
  getApplianceDetailsState,
  (state: ApplianceDetailsState) => state?.appliances
);

export const getAppliancesLoaded = createSelector(
  getAppliancesState,
  (state: AppliancesState) => state.remoteState
);

export const getAppliances = createSelector(
  getAppliancesState,
  (state: AppliancesState) => selectAppliances(state)
);

export const getSelectedAppliance = createSelector(
  getAppliancesState,
  (state: AppliancesState) => state?.selectedAppliance
);

export const getCorrelationId = createSelector(
  getAppliancesState,
  (state: AppliancesState) => state.correlationId
);

export const isHeatingAppliance = createSelector(
  getSelectedAppliance,
  (selectedAppliance: Appliance | undefined) =>
    selectedAppliance?.category === 'Boilers'
);

export const getApplianceEntities = createSelector(
  getAppliancesState,
  (state: AppliancesState) => selectApplianceEntities(state)
);

export const getBrandsState = createSelector(
  getApplianceDetailsState,
  (state: ApplianceDetailsState) => state.brands
);

export const getBrandsLoaded = createSelector(
  getBrandsState,
  (state: BrandsState) => state.remoteState
);

export const getBrands = createSelector(getBrandsState, (state: BrandsState) =>
  selectBrands(state)
);

export const getBrandEntities = createSelector(
  getBrandsState,
  (state: BrandsState) => selectBrandEntities(state)
);

export const getFormDataState = createSelector(
  getApplianceDetailsState,
  (state: ApplianceDetailsState) => state.formData
);

export const getFormData = createSelector(
  getFormDataState,
  (state: ApplianceDetailsFormState) => state.formData
);
