import { PanelComponent } from './panel/panel.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { PanelBodyComponent } from './panel-body/panel-body.component';
import { PanelToggleButtonComponent } from './panel-toggle-button/panel-toggle-button.component';
import { PanelTitleComponent } from './panel-title/panel-title.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelSummaryComponent } from './panel-summary/panel-summary.component';
import { PanelContentRowComponent } from './panel-content-row/panel-content-row.component';
import { PanelDisplayInfoRowComponent } from './panel-display-info-row/panel-display-info-row.component';
import { PanelActionButtonComponent } from './panel-action-button/panel-action-button.component';

const components = [
  PanelComponent,
  PanelHeaderComponent,
  PanelBodyComponent,
  PanelSummaryComponent,
  PanelToggleButtonComponent,
  PanelTitleComponent,
  PanelContentRowComponent,
  PanelDisplayInfoRowComponent,
  PanelActionButtonComponent,
];
@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class PanelComponentsModule {}
