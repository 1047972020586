import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UpgradableCover } from '@common/util-models';

@Component({
  selector: 'sales-upgrade-plan-protection',
  templateUrl: './upgrade-plan-protection.component.html',
  styleUrls: ['./upgrade-plan-protection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradePlanProtectionComponent {
  @Input() upgradableCovers!: UpgradableCover | null;
  @Output() selectedCover = new EventEmitter<string | undefined>();
  hasChecked = false;

  excessAmountPerMonth(upgradableCovers: UpgradableCover | undefined) {
    if (!upgradableCovers) return;

    const baseCard = upgradableCovers?.base?.paymentOptions?.card;
    const baseDirectDebit = upgradableCovers?.base?.paymentOptions?.directDebit;
    const upgradeCard = upgradableCovers?.upgrade?.paymentOptions?.card;
    const upgradeDirectDebit =
      upgradableCovers?.upgrade?.paymentOptions?.directDebit;

    return baseDirectDebit?.preferredPayment &&
      upgradeDirectDebit?.preferredPayment
      ? upgradeDirectDebit?.subsequentPayment -
          baseDirectDebit?.subsequentPayment
      : baseCard?.preferredPayment &&
          upgradeCard?.preferredPayment &&
          upgradeCard?.subsequentPayment - baseCard?.subsequentPayment;
  }

  toggleUpgradable(type: string | undefined) {
    this.hasChecked = !this.hasChecked;
    this.selectedCover.emit(type);
  }
}
