/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'sales-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0ms 200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [style({ opacity: 0 })]),
    ]),
  ],
})
export class TabComponent {
  /**
   * Receives the title
   */
  @Input() title!: string;

  /**
   * Default active state false
   */
  @Input() active = false;
}
