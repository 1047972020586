import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-terms-and-conditions-text',
  templateUrl: './terms-and-conditions-text.component.html',
  styleUrls: ['./terms-and-conditions-text.component.scss'],
})
export class TermsAndConditionsTextComponent {
  @Input() termsUrl: string | undefined = undefined;
  @Input() policyUrl: string | undefined = undefined;
  @Input() isInsurance: boolean | null = false;
}
