export interface RuntimeConfig {
  catalogueApi: string;
  basketApi: string;
  personApi: string;
  domain: string;
  sales: string;
  common: string;
  identityApiUrl: string;
  worldpayIframeHelperURL: string;
  recaptchaSiteKey: string;
  cmsRestUrlBase: string;
  cmsTemplateDefinitionBase: string;
  cmsContentAppBase: string;
  cmsDamRawUrl: string;
  cookieProDomainScript: string;
  // Auth Lib needs the version in config.json. This is the reason this property is included here even though its always static
  identityApiUrlVersion: string;
}

export const CONFIG_KEYS: Array<keyof RuntimeConfig> = [
  'catalogueApi',
  'basketApi',
  'personApi',
  'domain',
  'sales',
  'common',
  'identityApiUrl',
  'identityApiUrlVersion',
  'recaptchaSiteKey',
  'worldpayIframeHelperURL',
  'cmsRestUrlBase',
  'cmsTemplateDefinitionBase',
  'cmsContentAppBase',
  'cmsDamRawUrl',
  'cookieProDomainScript',
];
