import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BuildConfigService, ErrorService } from '@common/util-foundation';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { QuotesApiService } from '../services/quotes-api.service';
import * as QuotesActions from './quotes.actions';
import { ApplianceDetailsActions } from '@common/data-access-appliance-details';
import { NavigationActions } from '@shared/data-access-navigation';

@Injectable()
export class QuotesEffects {
  createQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuote),
      exhaustMap((action) =>
        this.quotesService.createQuote(action.quoteRequest).pipe(
          map((quoteResponse) =>
            QuotesActions.createQuoteSuccess({
              quoteResponse,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            this.handleError();
            return of(QuotesActions.createQuoteFailure({ error }));
          })
        )
      )
    )
  );

  createQuoteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuotesActions.createQuoteSuccess),
        tap(() => {
          this.router.navigateByUrl(this.config.quotePage);
        })
      ),
    {
      dispatch: false,
    }
  );

  stepCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.createQuoteSuccess),
      map(() =>
        NavigationActions.stepCompleted({
          step: this.config.applianceDetailsPage,
        })
      )
    )
  );

  private get config() {
    return this.buildConfigService.config;
  }

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    private actions$: Actions,
    private quotesService: QuotesApiService,
    private router: Router,
    private buildConfigService: BuildConfigService,
    private errorService: ErrorService
  ) {}
}
