<h3>Your {{ isHeating ? 'plan' : 'policy' }} documents</h3>

<p class="info" [innerHtml]="infoText"></p>

<dgx-dfb-form
  *ngIf="vm$ | async as vm"
  [fieldset]="vm.fieldDef"
  [form]="vm.formGroup"
  [validate]="vm.validate"
  (analytics)="handleAnalytics($event)"
>
</dgx-dfb-form>
