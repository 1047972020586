<div>
  By switching this toggle, you confirm you have read the
  <span
    ><a
      class="link"
      target="_blank"
      [href]="productInfoUrl"
      title="Insurance Product Information Document"
    >
      Insurance Product Information Document
    </a></span
  >

  and that this product meets your demands and needs.
</div>
