<ng-container *ngIf="errorPageData$ | async as errorPageData">
  <div class="error">
    <div class="error__content text--center container">
      <h2 class="error__header">{{ errorPageData.header }}</h2>
      <p class="error__support">{{ errorPageData.support }}</p>
      <p
        class="error__support error__support--additional"
        *ngIf="errorPageData.additionalSupport"
      >
        {{ errorPageData.additionalSupport }}
      </p>
      <sales-customer-service-contact
        [title]="errorPageData.contactCustomerService"
      ></sales-customer-service-contact>
    </div>
  </div>
  <sales-helpful-links></sales-helpful-links>
</ng-container>
