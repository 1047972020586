import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Address } from '../../_shared/interfaces/address.interface';
import { AddressDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { AddressLookupStateService } from './address-lookup-state.service';
import { SelectionMode } from '../../_shared/interfaces/address-lookup.interface';

@Component({
  selector: 'dgx-dfb-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddressLookupStateService],
})
export class AddressLookupComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() set field(fieldDef: AddressDef) {
    if (fieldDef) {
      this.addressLookupStateService.setFieldDef(fieldDef);
      if (fieldDef?.initialValue?.line1) {
        this.addressLookupStateService.setSelectionMode(SelectionMode.Manual);
      }
    }
  }

  @Input() validate = false;

  constructor(
    private addressLookupStateService: AddressLookupStateService,
    @Optional() private ngControl: NgControl
  ) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  vm$ = this.addressLookupStateService.vm$;

  // Analytics event for tagging reasons
  @Output() analytics = this.addressLookupStateService.analytics$;

  ngOnInit(): void {
    if (this.ngControl && this.ngControl.control) {
      this.addressLookupStateService.setFormControl(this.ngControl.control);
    }
  }

  handleEnteredInput(input: string) {
    this.addressLookupStateService.enteredInput(input);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.validate?.currentValue === true) {
      this.addressLookupStateService.validate();
    }
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.addressLookupStateService.saveOnChangeReference(fn);
  }

  registerOnTouched(fn: () => void) {
    this.addressLookupStateService.saveOnTouchedReference(fn);
  }

  writeValue(val: Address) {
    this.addressLookupStateService.setSelectedAddress(val);
  }

  setSelectionMode(selectionMode: SelectionMode, clickEvent: Event) {
    this.addressLookupStateService.setSelectionMode(selectionMode);
    this.vm$.subscribe( v => {
      if (v.selectedAddress) {
        this.addressLookupStateService.validate();
      }
    })
    clickEvent.preventDefault();
  }
}
