import { MarketingPreferences } from '@domgen/dgx-fe-business-models';
import { Action, createReducer, on } from '@ngrx/store';
import { SelectPaymentActions } from '@common/data-access-select-payment';
import {
  DirectDebit,
  PersonalDetailsFormField,
  PersonalDetailsFormSubmitType,
  PersonalDetailsState,
} from '@common/util-models';
import * as PersonalDetailsActions from './personal-details.actions';

export const PERSONAL_DETAILS_FEATURE_KEY = 'guestPersonalDetails';

export interface PersonalDetailsPartialState {
  readonly [PERSONAL_DETAILS_FEATURE_KEY]: PersonalDetailsState;
}

export const initialState: PersonalDetailsState = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  address: null,
  marketingPreferences: null,
  directDebitDetails: null,
  paperlessPlanDocuments: null,
};

export const personalDetailsReducer = createReducer(
  initialState,

  on(
    PersonalDetailsActions.updatePersonalDetails,
    (state, action: { payload: PersonalDetailsFormSubmitType }) => {
      return <PersonalDetailsState>{
        ...state,
        title: action.payload.title,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        mobileNumber: action.payload.mobileNumber,
        address: { ...action.payload[PersonalDetailsFormField.Address] },
      };
    }
  ),

  on(
    PersonalDetailsActions.updateDirectDebit,
    (state, action: { payload: DirectDebit }) => {
      return <PersonalDetailsState>{
        ...state,
        directDebitDetails: { ...action.payload },
      };
    }
  ),

  on(
    PersonalDetailsActions.updateMarketingPreference,
    (state, action: { payload: MarketingPreferences }) => {
      return <PersonalDetailsState>{
        ...state,
        marketingPreferences: { ...action.payload },
      };
    }
  ),

  on(
    PersonalDetailsActions.updatePlanDocumentPreference,
    SelectPaymentActions.updatePlanDocumentPreferences,
    (state, { paperless: paperlessPlanDocuments }) => {
      return {
        ...state,
        paperlessPlanDocuments,
      };
    }
  )
);

export function reducer(
  state: PersonalDetailsState | undefined,
  action: Action
) {
  return personalDetailsReducer(state, action);
}
