import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export const continueAsGuest = { guest: true };

@Component({
  selector: 'sales-login-error-dialog',
  templateUrl: './login-error-dialog.component.html',
  styleUrls: ['./login-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginErrorDialogComponent {
  constructor(private dialogRef: MatDialogRef<LoginErrorDialogComponent>) {}

  handleClose(): void {
    this.dialogRef.close();
  }

  handleContinue(): void {
    this.dialogRef.close(continueAsGuest);
  }
}
