import { Injectable } from '@angular/core';
import {
  Appliance,
  ApplianceResponse,
  Brand,
  BrandResponse,
} from '@common/util-models';

@Injectable()
export class AppliancesMapperService {
  mapToBrand({
    brandCode: code,
    brandName: description,
    warranty,
  }: BrandResponse): Brand {
    return {
      code,
      description,
      warranty,
    };
  }
  mapToAppliance({
    applianceCode: code,
    applianceName: description,
    icon,
    image,
    category,
  }: ApplianceResponse): Appliance {
    return {
      code,
      description,
      icon,
      image,
      category,
    };
  }
}
