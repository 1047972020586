import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as CheckoutSelectors from './checkout.selectors';
import * as CheckoutPageSelectors from './checkout-page.selectors';
import { CheckoutPartialState } from './checkout.reducer';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as CheckoutActions from './checkout.actions';
import { ResetStateActions } from '@common/data-access-shared';
import {
  WrappedInvalidPaymentDetails,
  getWrappedInvalidPaymentDetails,
  Quote,
  PaymentType,
} from '@common/util-models';
import {
  QuotesPageSelectors,
  QuotesPartialState,
} from '@common/data-access-quotes';
import { ApplianceDetailsSelectors } from '@common/data-access-appliance-details';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';

@Injectable()
export class CheckoutFacade {
  quote$ = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  showPopupToRestartJourney$ = this.store.pipe(
    select(CheckoutPageSelectors.showPopupToRestartCheckoutJourney),
    filter((showPopup) => !!showPopup)
  );

  invalidPaymentDetails$ = this.store.pipe(
    select(CheckoutSelectors.getDirectDebitDetailsValid),
    filter((directDebitDetailsValid) => directDebitDetailsValid !== undefined),
    map((directDebitDetailsValid) =>
      directDebitDetailsValid === false
        ? getWrappedInvalidPaymentDetails(true)
        : getWrappedInvalidPaymentDetails(false)
    )
  ) as Observable<WrappedInvalidPaymentDetails>;

  isHeating$ = this.store.pipe(
    select(ApplianceDetailsSelectors.isHeatingAppliance)
  );

  selectedPayment$ = this.store.pipe(
    select(SelectPaymentSelectors.getSelectedPaymentType)
  ) as Observable<PaymentType>;

  constructor(
    private store: Store<
      CheckoutPartialState & QuotesPartialState & SelectPaymentPartialState
    >
  ) {}

  checkoutGuest() {
    this.store.dispatch(CheckoutActions.checkoutGuest());
  }

  checkoutLoggedInUser() {
    this.store.dispatch(CheckoutActions.checkoutLoggedInUser());
  }

  exitCheckoutLoggedInUser() {
    this.store.dispatch(CheckoutActions.exitingCheckoutLoggedInUser());
  }

  exitCheckoutGuestUser() {
    this.store.dispatch(CheckoutActions.exitingCheckoutGuestUser());
  }

  restartCheckoutJourneyLoggedInUser() {
    this.store.dispatch(ResetStateActions.restartCheckoutLoggedInUser());
  }

  restartCheckoutJourneyGuestUser() {
    this.store.dispatch(ResetStateActions.restartCheckoutGuest());
  }

  paymentDetailsSummaryOnSubmit() {
    this.store.dispatch(CheckoutActions.paymentDetailsSummaryOnSubmit());
  }
}
