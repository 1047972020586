import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { ExcessSelectionComponent } from './excess-selection/excess-selection.component';
import { QuotePanelComponent } from './quote-panel/quote-panel.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { CoverSelectionComponent } from './cover-selection/cover-selection.component';
import { FooterComponent } from './footer/footer.component';
import { NeedToKnowComponent } from './need-to-know/need-to-know.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { AppliancePlanDetailComponent } from './appliance-plan-detail/appliance-plan-detail.component';
import { YourDetailsComponent } from './your-details/your-details.component';
import { PaymentCardComponent } from './payment-card/payment-card.component';
import { PaymentMethodSelectionComponent } from './payment-method-selection/payment-method-selection.component';
import { UpgradeBannerComponent } from './upgrade-banner/upgrade-banner.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { TermsAndConditionsForInsuranceComponent } from './terms-and-conditions-for-insurance/terms-and-conditions-for-insurance.component';
import { DirectDebitGuranteeComponent } from './direct-debit-gurantee/direct-debit-gurantee.component';
import { ErrorPageGaTaggingDirective } from './error-page/error-page-ga-tagging/error-page-ga-tagging.directive';
import { OrderConfirmationThankyouTextComponent } from './order-confirmation-thankyou-text/order-confirmation-thankyou-text.component';
import { QuoteTitleComponent } from './quote-title/quote-title.component';
import { TermsAndConditionsTextComponent } from './terms-and-conditions-text/terms-and-conditions-text.component';
import { PlanDurationComponent } from './plan-duration/plan-duration.component';
import { PaymentDetailConfirmationComponent } from './payment-detail-confirmation/payment-detail-confirmation.component';
import { PaymentOptionFormComponent } from './payment-option-form/payment-option-form.component';
import { DirectDebitInstructionConfirmationTextComponent } from './direct-debit-instruction-confirmation-text/direct-debit-instruction-confirmation-text.component';
import { DirectDebitConfirmationCheckboxComponent } from './direct-debit-payment-detail-confirmation-checkbox/direct-debit-confirmation-checkbox.component';
import { TermsAndConditionsFormComponent } from './terms-and-conditions-form/terms-and-conditions.component';
import { QuoteSummaryComponent } from './quote-summary/quote-summary.component';
import { TermsAndConditionsCheckboxTextComponent } from './terms-and-conditions-checkbox-text/terms-and-conditions-checkbox-text.component';
import { PaymentIconsComponent } from './payment-icons/payment-icons.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiBaseComponentsModule,
    SharedUiDynamicFormBuilderModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ExcessSelectionComponent,
    QuotePanelComponent,
    ProductCardComponent,
    CoverSelectionComponent,
    FooterComponent,
    NeedToKnowComponent,
    CustomerDetailsComponent,
    AppliancePlanDetailComponent,
    YourDetailsComponent,
    PaymentCardComponent,
    PaymentMethodSelectionComponent,
    UpgradeBannerComponent,
    ErrorPageComponent,
    PaymentDetailsComponent,
    TermsAndConditionsForInsuranceComponent,
    DirectDebitGuranteeComponent,
    ErrorPageGaTaggingDirective,
    OrderConfirmationThankyouTextComponent,
    QuoteTitleComponent,
    TermsAndConditionsTextComponent,
    PlanDurationComponent,
    PaymentDetailConfirmationComponent,
    PaymentOptionFormComponent,
    TermsAndConditionsFormComponent,
    DirectDebitInstructionConfirmationTextComponent,
    DirectDebitConfirmationCheckboxComponent,
    TermsAndConditionsCheckboxTextComponent,
    QuoteSummaryComponent,
    PaymentIconsComponent,
  ],
  exports: [
    ExcessSelectionComponent,
    QuotePanelComponent,
    ProductCardComponent,
    CoverSelectionComponent,
    FooterComponent,
    NeedToKnowComponent,
    CustomerDetailsComponent,
    AppliancePlanDetailComponent,
    YourDetailsComponent,
    PaymentCardComponent,
    PaymentMethodSelectionComponent,
    UpgradeBannerComponent,
    ErrorPageComponent,
    PaymentDetailsComponent,
    TermsAndConditionsForInsuranceComponent,
    DirectDebitGuranteeComponent,
    ErrorPageGaTaggingDirective,
    OrderConfirmationThankyouTextComponent,
    QuoteTitleComponent,
    TermsAndConditionsTextComponent,
    PlanDurationComponent,
    PaymentDetailConfirmationComponent,
    PaymentOptionFormComponent,
    TermsAndConditionsFormComponent,
    DirectDebitInstructionConfirmationTextComponent,
    DirectDebitConfirmationCheckboxComponent,
    TermsAndConditionsCheckboxTextComponent,
    QuoteSummaryComponent,
    PaymentIconsComponent,
  ],
  providers: [DatePipe],
})
export class CommonUiWhitelabelComponentsModule {}
