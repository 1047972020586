import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as OrderConfirmationPageSelectors from './order-confirmation-page.selectors';
import { CheckoutPartialState } from './checkout.reducer';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  CheckoutCompletedItem,
  CheckoutConfirmationTaggingData,
  Quote,
} from '@common/util-models';
import {
  QuotesPageSelectors,
  QuotesPartialState,
} from '@common/data-access-quotes';
import * as ApplianceDetailsSelectors from '@common/data-access-appliance-details';
import { SelectPaymentPartialState } from '@common/data-access-select-payment';
import { UserProfilePartialState } from '@common/data-access-user-profile';
import { CardPaymentPartialState } from '@common/data-access-card-payment';
import { FeatureConfigPartialState } from '@common/data-access-feature-config';

@Injectable()
export class OrderConfirmationFacade {
  quote$ = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  selectedContractType$ = this.store.pipe(
    select(QuotesPageSelectors.getSelectedContractType)
  );

  checkedOutItem$ = this.store.pipe(
    select(OrderConfirmationPageSelectors.getFirstCheckedOutItem),
    filter((checkedOutItem) => checkedOutItem !== undefined)
  ) as Observable<CheckoutCompletedItem>;

  personalDetails$ = this.store.pipe(
    select(OrderConfirmationPageSelectors.getPersonalDetails)
  );

  preferredPaymentDay$ = this.store.pipe(
    select(OrderConfirmationPageSelectors.getPreferredPaymentDay)
  );

  isHeating$ = this.store.pipe(
    select(ApplianceDetailsSelectors.isHeatingAppliance)
  );

  checkoutConfirmationData$ = this.store.pipe(
    select(OrderConfirmationPageSelectors.getCheckoutConfirmation)
  );

  taggingData$ = this.store.pipe(
    select(OrderConfirmationPageSelectors.getPageLandingTaggingData)
  ) as Observable<CheckoutConfirmationTaggingData>;

  checkoutPlanDetails$ = this.store.pipe(
    select(OrderConfirmationPageSelectors.getPlanDetails)
  );

  constructor(
    private store: Store<
      CheckoutPartialState &
        UserProfilePartialState &
        QuotesPartialState &
        SelectPaymentPartialState &
        CardPaymentPartialState &
        FeatureConfigPartialState
    >
  ) {}
}
