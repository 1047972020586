<div class="retry-card-payment-dialog">
  <p class="retry-card-payment-dialog__title">Payment failed</p>
  <p class="retry-card-payment-dialog__body">
    An error occurred while processing your credit card payment.
  </p>
  <p class="retry-card-payment-dialog__footer">
    <button
      class="btn btn--primary retry-card-payment-dialog__cta retry-card-payment-dialog__cta--try-again"
      (click)="handleTryAgain()"
    >
      <span class="label">Try again</span>
    </button>
    <button
      class="btn btn--primary retry-card-payment-dialog__cta"
      (click)="handlePayByDirectDebit()"
    >
      <span class="label">Pay with Direct Debit</span>
    </button>
  </p>
</div>
