import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedFeatureAuthenticationModule } from '@domgen/dgx-fe-auth';
import { SharedUiCmsModule } from '@domgen/dgx-fe-content-management';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonDataAccessApplianceDetailsModule } from '@common/data-access-appliance-details';
import { CommonDataAccessCardPaymentModule } from '@common/data-access-card-payment';
import { CommonDataAccessCheckoutModule } from '@common/data-access-checkout';
import { CommonDataAccessPersonalDetailsModule } from '@common/data-access-personal-details';
import { CommonDataAccessQuotesModule } from '@common/data-access-quotes';
import { CommonDataAccessSelectPaymentModule } from '@common/data-access-select-payment';
import { RestartJourneyEffects } from '@common/data-access-shared';
import { CommonDataAccessUserProfileModule } from '@common/data-access-user-profile';
import { FORM_BUILDER_CONFIG } from '@common/util-foundation';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '@oren/util-environment';
import { cmsConfigToken } from '../../cms-config/cms-config.token';
import { authenticationModuleConfig } from './authentication-module.config';
import { PROVIDERS } from './providers';
import { META_REDUCERS } from './meta-reducers';
import { SharedDataAccessNavigationModule } from '@shared/data-access-navigation';
import { NAVIGATION_RULES } from './navigation-rules.config';
import { CommonDataAccessFeatureConfigModule } from '@common/data-access-feature-config';

@NgModule({
  providers: [...PROVIDERS],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedUiCmsModule.forRoot(cmsConfigToken),
    SharedUiDynamicFormBuilderModule.forRoot(FORM_BUILDER_CONFIG),
    SharedFeatureAuthenticationModule.forRoot(authenticationModuleConfig),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    CommonDataAccessFeatureConfigModule,
    CommonDataAccessCheckoutModule,
    CommonDataAccessQuotesModule,
    CommonDataAccessUserProfileModule,
    CommonDataAccessPersonalDetailsModule,
    CommonDataAccessApplianceDetailsModule,
    CommonDataAccessSelectPaymentModule,
    CommonDataAccessCardPaymentModule,
    SharedDataAccessNavigationModule.forRoot({
      defaultRedirectUrl: environment.applianceDetailsPage,
      navigationRules: [...NAVIGATION_RULES],
    }),
    EffectsModule.forRoot([RestartJourneyEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    RecaptchaV3Module,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() coreModule: CoreModule) {
    if (coreModule) {
      throw new TypeError(`CoreModule can be imported only once`);
    }
  }
} // Singleton objects (services, components, forRoot(), forFeature() that are loaded only once, etc.)
