<ul class="progress-tracker">
  <li
    class="progress-tracker__step"
    [ngClass]="{
      'progress-tracker__step--current': i === activeStep,
      'progress-tracker__step--completed': i < activeStep
    }"
    *ngFor="let step of steps; let i = index"
  >
    {{ step }}
  </li>
</ul>
<h4 class="progress-tracker-mobile">
  Step {{ activeStep + 1 }} of {{ steps.length }}
</h4>
