import { createSelector } from '@ngrx/store';
import {
  AvailableCover,
  BasketItemApplianceDetails,
  ContractType,
  Cover,
  CoverType,
  ExcessPrice,
  Quote,
  QuotesConfig,
  TaggingMetadata,
  UpgradeCoverConfig,
} from '@common/util-models';

import {
  getApplianceDetails,
  getInitialCoverType,
  getQuoteDetails,
  getReturnedCoverTypes,
  getSelectedCover,
  getSelectedCoverType,
  getSelectedExcess,
} from './quotes.selectors';
import {
  convertPaymentOptionsToObject,
  transformQuoteDetailToCover,
} from './quotes.utils';
import { ApplianceDetailsSelectors } from '@common/data-access-appliance-details';
import { FeatureConfigSelectors } from '@common/data-access-feature-config';

export const getSelectedContractType = createSelector(
  getSelectedCover,
  (cover) => cover?.contractTypeCode || null
);
export const getIsInsurance = createSelector(
  getSelectedCover,
  (cover) => cover?.contractTypeCode == ContractType.Insurance
);

export const getTaggingMetadata = createSelector(
  getApplianceDetails,
  getSelectedCover,
  ApplianceDetailsSelectors.isHeatingAppliance,
  (
    applianceDetails: BasketItemApplianceDetails | undefined,
    cover: Cover | undefined,
    isHeating
  ) => {
    if (applianceDetails && cover) {
      return {
        ...applianceDetails,
        schemeCode: cover.schemeCode,
        companyCode: cover.companyCode,
        outOfWarranty: !applianceDetails.inWarranty,
        isHeating,
      } as TaggingMetadata;
    }

    return undefined;
  }
);

export const getQuote = createSelector(
  getSelectedCover,
  getApplianceDetails,
  (cover?: Cover, applianceDetails?: BasketItemApplianceDetails) => {
    if (!applianceDetails || !cover) {
      return undefined;
    }

    return {
      applianceCode: applianceDetails.applianceCode,
      applianceCategory: applianceDetails.applianceCategory,
      applianceName: applianceDetails.applianceName,
      brandCode: applianceDetails.brandCode,
      brandName: applianceDetails.brandName,
      purchaseDate: new Date(
        applianceDetails.purchaseYear,
        applianceDetails.purchaseMonth - 1
      ),
      applianceIcon: applianceDetails.applianceIcon,
      cover,
    } as Quote;
  }
);

/**
 * Get available cover types as strings
 */
export const getAvailableCoverTypes = createSelector(
  FeatureConfigSelectors.getQuotesConfig,
  getReturnedCoverTypes,
  getInitialCoverType,
  (config, returnedCoverTypes, initialCoverType): Array<CoverType> => {
    if (config?.showAllCoverTypes) {
      return returnedCoverTypes;
    }

    //based on config work out what cover types we should offer as upgrades
    const upgradeCoverTypes = (
      config?.coverTypeUpgrades[initialCoverType] || []
    ).map((upgradeCoverType) => upgradeCoverType.coverType);

    //return initial plus upgrades, but remove any that were not returned by API
    return [
      initialCoverType,
      ...upgradeCoverTypes.filter((coverType: CoverType) =>
        returnedCoverTypes.includes(coverType)
      ),
    ];
  }
);

/**
 * Get alternate product types as strings
 */
export const getAlternateProduct = createSelector(
  FeatureConfigSelectors.getQuotesConfig,
  getInitialCoverType,
  (config, initialCoverType): UpgradeCoverConfig | undefined => {
    return config?.alternateProductUpgrades
      ? config.alternateProductUpgrades[initialCoverType]
      : undefined;
  }
);

/**
 * Get available covers with relevant properties i.e. price
 */
export const getAvailableCovers = createSelector(
  getAvailableCoverTypes,
  getQuoteDetails,
  getInitialCoverType,
  getSelectedExcess,
  FeatureConfigSelectors.getQuotesConfig,
  (
    coverTypes,
    quotes,
    initialCoverType,
    selectedExcess,
    config
  ): AvailableCover[] => {
    if (!quotes) {
      return [];
    }
    return quotes
      .filter(
        (quote) =>
          coverTypes.includes(quote.coverType) &&
          quote.excessAmount === selectedExcess
      )
      .map((cover) => {
        const isUpgrade = initialCoverType !== cover.coverType;
        const name =
          config?.coverTypeNames && config.coverTypeNames[cover.coverType];

        const description = getCoverDescription(
          cover.coverType,
          config,
          isUpgrade,
          initialCoverType
        );

        return {
          name,
          description,
          ...transformQuoteDetailToCover(cover),
          isUpgrade,
        };
      })
      .sort((a, b) => {
        if (a.isUpgrade && b.isUpgrade) {
          return 0;
        }
        //todo: support multiple upgrades order via config (whitelist)
        return a.isUpgrade ? 1 : -1;
      });
  }
);

/**
 * Get available excess values, along with payment methods
 */
export const getAvailableExcessPrices = createSelector(
  getQuoteDetails,
  getSelectedCoverType,
  (quotes, coverType): ExcessPrice[] => {
    if (!quotes) {
      return [];
    }
    //get selected cover entity from quote
    const covers = quotes.filter((q) => coverType === q.coverType);
    if (!covers.length) {
      return [];
    }
    //we are working on the assumption that each cover will have the same paymentOption for each excessAmount
    return covers
      .map(
        (cover): ExcessPrice => ({
          excessAmount: cover.excessAmount,
          paymentOptions: convertPaymentOptionsToObject(cover.paymentOptions),
        })
      )
      .sort((a, b) => b.excessAmount - a.excessAmount);
  }
);

/**
 * Get base and upgrade covers if cover is upgradable
 */
export const getUpgradableCovers = createSelector(
  getAvailableCovers,
  (availableCovers: AvailableCover[]) => {
    if (availableCovers.length !== 2) {
      return undefined;
    }

    return {
      base: availableCovers.find((ac) => !ac.isUpgrade),
      upgrade: availableCovers.find((ac) => !!ac.isUpgrade),
    };
  }
);

function getCoverDescription(
  coverType: CoverType,
  config: QuotesConfig | undefined,
  isUpgrade: boolean,
  initialCover?: CoverType
): string {
  if (!isUpgrade) {
    return config?.coverTypeDescription?.[coverType] ?? '';
  }

  if (isUpgrade && initialCover) {
    return (
      config?.coverTypeUpgrades?.[initialCover]?.find(
        (c) => c.coverType === coverType
      )?.description ?? ''
    );
  }

  return '';
}

export const getExcessToDisplay = createSelector(
  getSelectedExcess,
  getAvailableExcessPrices,
  (selectedExcess, availableExcess) => {
    if (availableExcess.length > 1) {
      return selectedExcess;
    } else {
      return null;
    }
  }
);

/**
 * Get a merged selector of all values needed for Quote Summary component
 */
export const getQuoteSummary = createSelector(
  getQuote,
  getIsInsurance,
  getExcessToDisplay,
  (quote, isInsurance, excessToDisplay) => ({
    quote,
    isInsurance,
    excessToDisplay,
  })
);
