<div>
  For your own benefit and protection, please read the
  <span
    ><a
      class="link"
      target="_blank"
      [href]="termsUrl"
      title="Terms & Conditions"
      ><span *ngIf="isInsurance">Policy</span> Terms and conditions</a
    ></span
  >
  and
  <span
    ><a class="link" target="_blank" [href]="policyUrl" title="Privacy Policy"
      >Privacy Policy</a
    ></span
  >
  carefully<span *ngIf="isInsurance"> before purchase</span>.
</div>
