import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ErrorService } from '@common/util-foundation';
import { CoverType } from '@common/util-models';
import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  pipe,
} from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import * as QuotesActions from '../+state/quotes.actions';
import { QuotesPartialState } from '../+state/quotes.reducer';
import * as QuotesSelectors from '../+state/quotes.selectors';
import {
  FeatureConfigSelectors,
  FeatureConfigPartialState,
} from '@common/data-access-feature-config';

@Injectable()
export class SelectCoverTypeFromWhitelistGuard implements CanActivate {
  constructor(
    private store: Store<QuotesPartialState & FeatureConfigPartialState>,
    private errorHandler: ErrorService
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(FeatureConfigSelectors.getQuotesConfig),
      withLatestFrom(
        this.store.pipe(select(QuotesSelectors.shouldAutoSelectCover))
      ),
      take(1),
      switchMap(([config, shouldAutoSelectCover]) =>
        config?.coverTypesWhitelist === false || shouldAutoSelectCover === false
          ? of(true)
          : this.store.pipe(
              select(QuotesSelectors.getFirstCoverTypeFromWhitelist),
              take(1),
              this.autoSelectCoverType(),
              this.redirectToErrorPageIfNeeded()
            )
      )
    );
  }

  private autoSelectCoverType(): MonoTypeOperatorFunction<
    CoverType | undefined
  > {
    return pipe(
      tap((coverType) => {
        if (coverType) {
          this.store.dispatch(QuotesActions.autoSelectCoverType({ coverType }));
        }
      })
    );
  }

  private redirectToErrorPageIfNeeded(): OperatorFunction<
    CoverType | undefined,
    boolean
  > {
    return pipe(
      tap((coverType) => {
        if (!coverType) {
          this.errorHandler.handleError({
            support: 'We’re having a little trouble creating your plan',
          });
        }
      }),
      map((coverType) => !!coverType)
    );
  }
}
