import { Inject, Injectable } from '@angular/core';
import { CmsModuleConfig } from '@domgen/dgx-fe-content-management';
import {
  BuildConfig,
  ConfigAdapter,
  Environment,
  RuntimeConfig,
} from '@common/util-models';
import { ReplaySubject } from 'rxjs';
import { CONFIG_ADAPTER } from '../tokens/config-adapter.token';
import { ENVIRONMENT } from '../tokens/environment.token';
import { RUNTIME_CONFIG } from '../tokens/runtime-config.token';

/**
 * Provides dynamic runtime environment configuration.
 * See https://labs.thisdot.co/blog/runtime-environment-configuration-with-angular
 * for more information.
 * Wherever environment-specific configuration is required it can be accessed
 * via the config property of the injected service.
 */
@Injectable({
  providedIn: 'root',
})
export class BuildConfigService {
  config$: ReplaySubject<BuildConfig> = new ReplaySubject(1);
  config!: BuildConfig;

  constructor(
    @Inject(ENVIRONMENT) environment: Environment,
    @Inject(RUNTIME_CONFIG) runtimeConfig: RuntimeConfig,
    @Inject(CONFIG_ADAPTER) configAdapters: ConfigAdapter[]
  ) {
    this.evaluateConfig(environment, runtimeConfig, configAdapters);
    this.config$.next(this.config);
  }

  getCmsConfig(componentMapping: CmsModuleConfig['componentMapping']) {
    if (!this.config) {
      throw new Error('Build Config not set');
    }
    return <CmsModuleConfig>{ ...this.config.cms_config, componentMapping };
  }

  getFormbuilderConfig() {
    if (!this.config) {
      throw new Error('Build Config not set');
    }
    return {
      postcodeServiceKey: this.config.postcodeServiceKey,
      postcodeServiceFind: this.config.postcodeServiceFind,
      postcodeServiceRetrieve: this.config.postcodeServiceRetrieve,
    };
  }

  private evaluateConfig(
    environment: Environment,
    runtimeConfig: RuntimeConfig,
    configAdapters: ConfigAdapter[]
  ) {
    this.config = { ...environment, ...runtimeConfig } as BuildConfig;
    this.config = configAdapters.reduce(
      (prevConfig, configAdapter) => ({
        ...prevConfig,
        ...configAdapter.getConfig(),
      }),
      this.config
    );
  }
}
