import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PaymentType } from '@common/util-models';
import { SelectPaymentPartialState } from './select-payment.reducer';
import * as SelectPaymentActions from './select-payment.actions';
import { selectUser } from '@domgen/dgx-fe-auth';
import * as SelectPaymentSelectors from './select-payment.selectors';

@Injectable()
export class SelectPaymentFacade {
  user$ = this.store.pipe(select(selectUser));

  selectedPayment$ = this.store.pipe(
    select(SelectPaymentSelectors.getSelectedPaymentType)
  );

  constructor(private store: Store<SelectPaymentPartialState>) {}

  selectPaymentOption(paymentType: PaymentType) {
    this.store.dispatch(
      SelectPaymentActions.selectPaymentOption({ paymentType })
    );
  }

  proceedToPayment() {
    this.store.dispatch(SelectPaymentActions.proceedToPayment());
  }

  savePlanDocumentPreference(byPost: boolean) {
    this.store.dispatch(
      SelectPaymentActions.updatePlanDocumentPreferences({ paperless: !byPost })
    );
  }
}
