<div class="checkout-as-guest">
  <h2 class="title">{{ cmsData?.guestCheckout?.title }}</h2>
  <h3 class="sub-heading inline-flex">
    <i class="icon icon--xl icon-basket"></i
    >{{ cmsData?.guestCheckout?.subtitle }}
  </h3>
  <p class="description">{{ cmsData?.guestCheckout?.description }}</p>
  <div class="checkout-as-guest__cta">
    <button class="btn btn--primary" (click)="handleClick()">
      <span class="label">{{ cmsData?.guestCheckout?.buttonText }}</span>
      <i class="icon icon--md icon-arrow-right"></i>
    </button>
    <p class="hint">{{ cmsData?.guestCheckout?.hint }}</p>
  </div>
</div>
