import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export type RetryCardPaymentResult = 'Try Again' | 'Pay By DirectDebit';

@Component({
  selector: 'sales-retry-card-payment',
  templateUrl: './retry-card-payment.component.html',
  styleUrls: ['./retry-card-payment.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetryCardPaymentComponent {
  constructor(
    private dialogRef: MatDialogRef<
      RetryCardPaymentComponent,
      RetryCardPaymentResult
    >
  ) {}

  handleTryAgain() {
    this.dialogRef.close('Try Again');
  }

  handlePayByDirectDebit() {
    this.dialogRef.close('Pay By DirectDebit');
  }
}
