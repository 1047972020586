import { MarketingPreferences } from '@domgen/dgx-fe-business-models';

export type PlanDocumentsSendMethod = 'EMAIL' | 'POST' | 'BOTH';

export interface CheckoutItemDetails {
  itemType: 'QUOTE';
  itemId: string;
  quoteId: string;
}

export interface CheckoutRequest {
  basketId: string;
  items: Array<CheckoutItemDetails>;
  customer: CustomerDetails;
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard;
}

export interface CustomerDetails {
  title?: string;
  initial?: string;
  firstName: string;
  surname: string;
  preferredName?: string;
  telephone?: CustomerTelephone;
  email: CustomerEmail;
  address: CustomerAddress;
  sendMethod: PlanDocumentsSendMethod;
  preferredContactMethod: 'EMAIL' | 'POST' | 'TELEPHONE';
  marketingPreferences: MarketingPreferences;
}

export interface CustomerTelephone {
  mobile?: string;
  home?: string;
  work?: string;
  preferred?: 'H' | 'M' | 'W';
}

export interface CustomerEmail {
  main: string;
}

export interface CustomerAddress {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postalCode: string;
  countryCode: string;
}

export interface PaymentByDirectDebit {
  paymentType: 'D';
  directDebitDetails: PaymentByDirectDebitDetails;
  preferredPaymentDay: number;
}

export interface PaymentByDirectDebitDetails {
  sortCode: string;
  accountNumber: string;
  accountName: string;
}
export interface PaymentByDirectDebitToken {
  paymentType: 'D';
  directDebitPaymentToken: string;
  preferredPaymentDay: number;
}
export interface PaymentByCreditCard {
  paymentType: 'C';
  cardPaymentReference: string;
}

export function isPaymentByCreditCard(
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard
): payment is PaymentByCreditCard {
  return payment.paymentType === 'C';
}

export function isPaymentByDirectDebitToken(
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard
): payment is PaymentByCreditCard {
  return (
    payment.paymentType === 'D' &&
    !!(payment as PaymentByDirectDebitToken).directDebitPaymentToken
  );
}

export function isPaymentByDirectDebit(
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard
): payment is PaymentByDirectDebit {
  return (
    payment.paymentType === 'D' &&
    !(payment as PaymentByDirectDebitToken).directDebitPaymentToken
  );
}
