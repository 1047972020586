import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sales-restart-checkout-journey',
  templateUrl: './restart-checkout-journey.component.html',
  styleUrls: ['./restart-checkout-journey.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestartCheckoutJourneyComponent {
  constructor(
    private dialogRef: MatDialogRef<RestartCheckoutJourneyComponent>
  ) {}

  handleClose() {
    this.dialogRef.close();
  }
}
