import { createAction, props } from '@ngrx/store';
import {
  Appliance,
  ApplianceFormSubmit,
  Brand,
  CreateQuoteRequest,
} from '@common/util-models';

export const loadAppliances = createAction(
  '[AppliancesResolver] Load appliances'
);

export const loadAppliancesSuccess = createAction(
  '[ApplianceDetailsEffects] Appliances loaded successfully',
  props<{ appliances: Appliance[]; correlationId: string }>()
);

export const loadAppliancesFailure = createAction(
  '[ApplianceDetailsEffects] Load appliances failed',
  props<{ error: Error }>()
);

export const loadBrands = createAction(
  '[ApplianceDetailsEffects] Load brands for appliance',
  props<{ code: string }>()
);

export const loadBrandsSuccess = createAction(
  '[ApplianceDetailsEffects] Brands for appliance loaded successfully',
  props<{ brands: Brand[] }>()
);

export const loadBrandsFailure = createAction(
  '[ApplianceDetailsEffects] Brands for appliance failed',
  props<{ error: Error }>()
);

export const applianceSelected = createAction(
  '[ApplianceDetailsComponent] Appliance selected',
  props<{ code: string }>()
);

export const createQuote = createAction(
  '[ApplianceDetailsComponent] Create Quote',
  props<{
    quoteRequest: CreateQuoteRequest;
    formData: ApplianceFormSubmit;
  }>()
);
