<div class="overlay md--hidden" *ngIf="isOpen" [@fadeInOut]></div>
<header class="header xs--middle" [class.active]="isOpen">
  <div class="container">
    <button
      id="menu-button"
      [class.active]="isOpen"
      class="header__menu-button md--hidden"
      (click)="onToggleMenu()"
    ></button>
    <div class="container xs--middle header__nav-wrapper">
      <div class="header__menu flex-auto"></div>

      <a
        href="{{ links?.home }}"
        target="_blank"
        class="header__logo xs--middle flex-auto"
        (click)="onClick($event)"
      >
        <img
          src="assets/images/dg-logo-colour.png"
          role="img"
          alt="Domestic & General"
        />
      </a>

      <nav class="container header__nav flex-auto">
        <ul class="xs--hidden xs--stack md-row" [class.active]="isOpen">
          <li>
            <a
              [routerLink]="['/products']"
              (click)="onClick($event)"
              class="link xs--middle"
              >Get protected</a
            >
          </li>
          <li>
            <a
              href="{{ links?.repair }}"
              (click)="onClick($event)"
              class="link xs--middle"
              target="_blank"
              >Book a repair</a
            >
          </li>
          <li>
            <a
              href="{{ links?.faq }}"
              (click)="onClick($event)"
              class="link xs--middle"
              target="_blank"
              >Help and advice</a
            >
          </li>
          <li class="icon-my-account icon--xl with-icon is-hidden">
            <a
              href="/my-account"
              (click)="onClick($event)"
              class="link xs--middle"
              target="_blank"
              >My account</a
            >
          </li>
          <li class="icon-basket icon--xl is-hidden">
            <a
              href="/basket"
              (click)="onClick($event)"
              class="link xs--middle"
              target="_blank"
            ></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
