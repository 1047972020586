import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCardPayment from './+state/card-payment.reducer';
import { CardPaymentEffects } from './+state/card-payment.effects';
import { CardPaymentFacade } from './+state/card-payment.facade';
import { CardPaymentSetupResolver } from './services/card-payment-setup.resolver';
import { CardPaymentApiService } from './services/card-payment-api.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCardPayment.CARD_PAYMENT_FEATURE_KEY,
      fromCardPayment.reducer
    ),
    EffectsModule.forFeature([CardPaymentEffects]),
  ],
  providers: [
    CardPaymentFacade,
    CardPaymentSetupResolver,
    CardPaymentApiService,
  ],
})
export class CommonDataAccessCardPaymentModule {}
