import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Quote } from '@common/util-models';

@Component({
  selector: 'sales-buy-now-quote-button',
  templateUrl: './buy-now-quote-button.component.html',
  styleUrls: ['./buy-now-quote-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyNowQuoteButtonComponent {
  @Input() quote!: Quote | null;
  @Input() isHeating!: boolean | null;
  @Output() buyNow = new EventEmitter();

  get fee() {
    const paymentOptions = this.quote?.cover.paymentOptions;
    return (
      paymentOptions?.directDebit?.subsequentPayment ??
      paymentOptions?.card?.fee
    );
  }

  get isDDPaymentAvailable(): boolean {
    return !!this.quote?.cover.paymentOptions.directDebit;
  }

  get isCardPaymentAvailable(): boolean {
    return !!this.quote?.cover.paymentOptions.card;
  }

  handleClick() {
    this.buyNow.emit();
  }
}
