<div class="form-group" data-hook="dgx-sales-input">
  <div class="flex input__header">
    <label [for]="field?.controlName">
      {{ field?.label?.text }}
      <dgx-dfb-tooltip
        [tooltip]="field?.tooltip"
        [tooltipName]="tooltipName"
        *ngIf="field?.tooltip"
      ></dgx-dfb-tooltip>
    </label>
  </div>
  <ng-container
    dgxDfbTooltipTarget
    *ngIf="field?.tooltip"
    [tooltipName]="tooltipName"
  ></ng-container>

  <div class="form-group__feedback">
    <input
      [name]="field?.controlName"
      class="form-control"
      [type]="type"
      [placeholder]="field?.placeholder"
      [value]="value$ | async"
      (input)="onChange($any($event))"
      (change)="onChange($any($event))"
      (keydown)="onKeyDown($event)"
      (blur)="onBlur($any($event))"
      autocomplete="off"
      [dgxDfbNumericMask]="isNumberType"
      [precision]="precision"
      [attr.maxlength]="maxLength"
    />
    <i
      class="icon icon-tick-circle icon--xxs"
      [ngClass]="{ 'hide-tick-icon': hideValidTickIcon }"
    ></i>
    <i class="icon icon-error-circle icon--xxs"></i>
  </div>
  <dgx-dfb-error
    *ngIf="errorMessage"
    [errorMessage]="errorMessage"
  ></dgx-dfb-error>
  <div *ngIf="field?.hint" class="form-group__hint">{{ field?.hint }}</div>
</div>
