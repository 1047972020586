import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { YearDateDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-year-date',
  templateUrl: './year-date.component.html',
  styleUrls: ['./year-date.component.scss'],
  providers: [TooltipService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearDateComponent
  extends FormElementBaseComponent
  implements OnChanges, OnInit, ControlValueAccessor {
  @Input() field!: YearDateDef;

  value: { month: number | undefined; year: number | undefined } = {
    month: undefined,
    year: undefined,
  };
  isInvalid = false;
  isValid = false;

  tooltipName = 'tooltip';

  constructor(
    @Optional() public ngControl: NgControl,
    protected formGroupDir: FormGroupDirective
  ) {
    super(ngControl);
  }

  ngOnInit() {
    const control = this.ngControl.control;
    this.formGroupDir.form.statusChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.isInvalid = !!control?.errors;
        })
      )
      .subscribe();
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  initValue() {
    this.value = this.value
      ? this.value
      : {
          month: undefined,
          year: undefined,
        };
  }

  /**
   * Modifies boolean flags that are used to assign 'is-valid' or 'is-invalid' classes
   * to month and year select elements.
   * This validation takes place when either month or year is chosen.
   */
  updateValidity(event: Event) {
    // validation errors
    const errors = this.ngControl.errors;

    // reset validity flags
    this.isInvalid = false;
    this.isValid = false;

    // year is not valid when there are any validation errors except for 'month is not selected' error
    this.isInvalid = !!errors;
    // if year is NOT invalid then we can check whether it can have a green tick
    if (!this.isInvalid) {
      // the field has a value and there are no errors (or at least no error saying 'select year')
      this.isValid = (!errors || !errors.yearNotSelected) && !!this.value.year;
    }

    (event.target as HTMLInputElement).blur();
  }

  saveYear(event: Event, val: string) {
    const option = this.field.values.find((v) => v.label === val);
    this.value.year = option?.year;
    this.value.month = option?.month;
    this.isInvalid = false;
    super.writeValue(this.value, false);
    this.updateValidity(event);
    this.emitAnalyticsData(this.value);
  }
}
