import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { AddCorrelationIdHeaderInterceptor } from '@common/data-access-appliance-details';
import { environment } from '@oren/util-environment';
import {
  BuildConfigService,
  CmsConfigAdapter,
  CONFIG_ADAPTER,
  cookieProInitializer,
  ENVIRONMENT,
  GA_TAGS,
  RequestInterceptorService,
  StoreConfigAdapterService,
  WorldpayConfigAdapter,
} from '@common/util-foundation';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { COMPONENT_MAPPING } from '../../cms-config/cms-component-mapping.config';
import { cmsConfigToken } from '../../cms-config/cms-config.token';
import { gaTagsConfig } from '../../ga-tags-config/ga-tags-config';
import {
  FeatureConfigFacade,
  featureConfigInitializer,
} from '@common/data-access-feature-config';
import { cmsDataUrlToken } from '@common/data-access-shared';
import { cmsDataUrl } from '../../cms-config/cms-data-url';

const recaptchaSiteKeyFactory = (buildConfigService: BuildConfigService) => {
  return buildConfigService.config.recaptchaSiteKey;
};

const cmsConfigFactory = (buildConfigService: BuildConfigService) => {
  return buildConfigService.getCmsConfig(COMPONENT_MAPPING);
};

export const PROVIDERS = [
  {
    provide: cmsDataUrlToken,
    useValue: cmsDataUrl,
  },
  {
    provide: cmsConfigToken,
    useFactory: cmsConfigFactory,
    deps: [BuildConfigService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: cookieProInitializer,
    multi: true,
    deps: [DOCUMENT, BuildConfigService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: featureConfigInitializer,
    multi: true,
    deps: [FeatureConfigFacade],
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useFactory: recaptchaSiteKeyFactory,
    deps: [BuildConfigService],
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddCorrelationIdHeaderInterceptor,
    multi: true,
  },
  {
    provide: GA_TAGS,
    useValue: gaTagsConfig,
  },
  {
    provide: ENVIRONMENT,
    useValue: environment,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: WorldpayConfigAdapter,
    multi: true,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: StoreConfigAdapterService,
    multi: true,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: CmsConfigAdapter,
    multi: true,
  },
];
