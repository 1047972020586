import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import * as ApplianceDetailsSelectors from '../+state/appliance-details.selectors';
import { Observable } from 'rxjs';
import { AppliancesState } from '../+state/appliances.reducer';
import { Injectable } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { CORRELATION_ID_HEADER } from '@common/util-models';

@Injectable()
export class AddCorrelationIdHeaderInterceptor implements HttpInterceptor {
  constructor(private store$: Store<AppliancesState>) {}

  // skip a URL if it contains a particular sub-string
  shouldUrlBeSkipped(reqUrl: string): boolean {
    const ignoreUrls = ['cms.domesticandgeneral'];

    return !!ignoreUrls.filter((url) => {
      return reqUrl.indexOf(url) >= 0;
    }).length;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.store$
      .pipe(select(ApplianceDetailsSelectors.getCorrelationId))
      .pipe(
        first(),
        switchMap((correlationId) => {
          const newRequest = correlationId
            ? request.clone({
                setHeaders: {
                  [CORRELATION_ID_HEADER]: correlationId,
                },
              })
            : request;

          if (this.shouldUrlBeSkipped(request.url)) {
            return next.handle(request);
          } else {
            return next.handle(newRequest);
          }
        })
      );
  }
}
