import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromPersonalDetailsSelectors from './personal-details.selectors';
import * as fromPersonalDetailsActions from './personal-details.actions';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';

import {
  DirectDebit,
  PersonalDetailsFormSubmitType,
  PersonalDetailsState,
  Quote,
  TaggingMetadata,
} from '@common/util-models';
import {
  QuotesPageSelectors,
  QuotesPartialState,
  QuotesSelectors,
} from '@common/data-access-quotes';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplianceDetailsSelectors } from '@common/data-access-appliance-details';
import { MarketingPreferences } from '@domgen/dgx-fe-business-models';

@Injectable()
export class PersonalDetailsFacade {
  quote$ = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  taggingMetadata$ = this.store.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  basketId$ = this.store.pipe(
    select(QuotesSelectors.getFirstItemQuote),
    filter((basketItem) => !!basketItem),
    map((basketItem) => basketItem?.basketId)
  ) as Observable<string>;

  personalDetails$ = this.store.select(
    fromPersonalDetailsSelectors.getPersonalDetails
  );

  personalDetailsState$ = this.store.select(
    fromPersonalDetailsSelectors.getPersonalDetailsState
  );

  directDebit$ = this.store.select(fromPersonalDetailsSelectors.getDirectDebit);

  address$ = this.store.select(fromPersonalDetailsSelectors.getAddress);

  isMarketingPreferenceSet$ = this.store.select(
    fromPersonalDetailsSelectors.isMarketingPreferenceSet
  );

  isPaperlessPlanDocumentPreferenceSet$ = this.store.select(
    fromPersonalDetailsSelectors.isPaperlessPlanDocumentPreferenceSet
  );

  isHeating$ = this.store.pipe(
    select(ApplianceDetailsSelectors.isHeatingAppliance)
  );

  selectedPayment$ = this.store.pipe(
    select(SelectPaymentSelectors.getSelectedPaymentType)
  );

  constructor(
    private store: Store<
      PersonalDetailsState & QuotesPartialState & SelectPaymentPartialState
    >
  ) {}

  updatePersonalDetails(data: PersonalDetailsFormSubmitType) {
    this.store.dispatch(
      fromPersonalDetailsActions.updatePersonalDetails({ payload: data })
    );
  }

  updateNewDirectDebitDetails(directDebitDetails: DirectDebit) {
    this.store.dispatch(
      fromPersonalDetailsActions.updateDirectDebit({
        payload: directDebitDetails,
      })
    );
  }

  updateMarketingPreference(marketingPreference: MarketingPreferences) {
    this.store.dispatch(
      fromPersonalDetailsActions.updateMarketingPreference({
        payload: marketingPreference,
      })
    );
  }

  updatePlanDocumentPreference(paperless: boolean) {
    this.store.dispatch(
      fromPersonalDetailsActions.updatePlanDocumentPreference({ paperless })
    );
  }

  proceedGuestToPayment(): void {
    this.store.dispatch(fromPersonalDetailsActions.proceedToPayment());
  }
}
