<div class="form-group">
  <div class="flex form-group__header">
    <label class="label" [for]="field?.controlName">
      {{ field?.label?.text }}
      <dgx-dfb-tooltip
        [tooltip]="field?.tooltip"
        [tooltipName]="tooltipName"
        *ngIf="field?.tooltip"
      ></dgx-dfb-tooltip>
    </label>
  </div>

  <ng-container dgxDfbTooltipTarget [tooltipName]="tooltipName"></ng-container>

  <div class="month-year-wrapper">
    <div
      class="select form-control"
      [ngClass]="{
        'is-invalid': (isInvalid || !!errorMessage),
        'is-valid': isValid
      }"
    >
      <div class="wrapper">
        <select
          class="form-control"
          [class.select--empty]="value.year === undefined"
          #yearSelect
          [name]="field?.controlName + '-year'"
          (change)="saveYear($event, yearSelect.value)"
          (blur)="updateValidity($event)"
          (focus)="updateValidity($event)"
          [attr.aria-label]="field?.yearLabel?.text"
        >
          <option disabled selected>Select year</option>
          <option *ngFor="let option of field?.values" [value]="option.label">
            {{ option.label }}
          </option>
        </select>
        <i class="icon icon-arrow-down"></i>
        <i class="icon icon-tick-circle icon--xxs"></i>
        <i class="icon icon-error-circle icon--xxs"></i>
      </div>
    </div>
  </div>
  <dgx-dfb-error
    *ngIf="errorMessage"
    [errorMessage]="errorMessage"
  ></dgx-dfb-error>
  <div *ngIf="field?.hint" class="form-group__hint">{{ field?.hint }}</div>
</div>
