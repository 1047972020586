import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CoverType, PaymentType, Quote } from '@common/util-models';

@Component({
  selector: 'sales-quote-card',
  templateUrl: './quote-card.component.html',
  styleUrls: ['./quote-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteCardComponent {
  @Input() quote!: Quote | null;
  @Input() isHeating!: boolean | null;
  @Input() selectedPaymentType: string | null = PaymentType.DirectDebit;

  get price() {
    if (this.isHeating) {
      return this.quote?.cover.paymentOptions.directDebit?.fee.toFixed(2);
    } else {
      return this.quote?.cover.paymentOptions.directDebit?.subsequentPayment.toFixed(
        2
      );
    }
  }

  get isAllInstalmentsAreSame() {
    return (
      this.quote?.cover.paymentOptions.directDebit?.firstPayment ===
      this.quote?.cover.paymentOptions.directDebit?.subsequentPayment
    );
  }

  get discount() {
    const directDebitPayment = this.quote?.cover?.paymentOptions?.directDebit
      ?.fee;
    const cardPayment = this.quote?.cover.paymentOptions.card?.fee;
    return (cardPayment as number) - (directDebitPayment as number);
  }

  get firstMonthlyPayment() {
    return this.quote?.cover.paymentOptions.directDebit?.firstPayment.toFixed(
      2
    );
  }

  get subsequentPayments() {
    return this.quote?.cover.paymentOptions.directDebit?.subsequentPayment.toFixed(
      2
    );
  }

  get actualPrice() {
    if (this.quote?.cover.paymentOptions.directDebit) {
      return (
        this.quote?.cover.paymentOptions.directDebit?.fee + this.discount
      ).toFixed(2);
    } else if (this.quote?.cover.paymentOptions.card) {
      return (
        this.quote?.cover.paymentOptions.card?.fee + this.discount
      ).toFixed(2);
    }
    return 0;
  }

  get isDirectDebitPayment(): boolean {
    return this.selectedPaymentType !== PaymentType.Card;
  }

  get heading(): string {
    return this.quote?.cover.coverType === CoverType.Standard
      ? 'Your appliance protection'
      : 'Your maintenance and support plan';
  }
}
