import { Injectable } from '@angular/core';
import { CreateQuoteResponse } from '@common/util-models';

@Injectable()
export class QuotesMapperService {
  addCategoryToApplianceDetails(
    quoteResponse: CreateQuoteResponse,
    applianceCategory?: string
  ): CreateQuoteResponse {
    for (const item of quoteResponse.basket.items) {
      item.data.applianceDetails = {
        ...item.data.applianceDetails,
        applianceCategory: applianceCategory ?? '',
      };
    }

    return quoteResponse;
  }
}
