<section class="tooltip">
  <div
    [dgxDfbTooltip]="tooltipTemplate"
    [tooltipName]="tooltipName"
    class="icon icon-more-info-fill tooltip__icon"
  >
    <span class="path1 tooltip__icon-content"></span>
    <span class="path2 tooltip__icon-content"></span>
    <span class="path3 tooltip__icon-content"></span>
  </div>
  <ng-template #tooltipTemplate>
    <div class="tooltip__content" [@slideInOut] [innerHTML]="tooltip">
    </div>
  </ng-template>
</section>
