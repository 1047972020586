<div class="payment-options-header">
  <div class="payment-options-header__wrapper">
    <span class="payment-options-header__title">Choose payment method</span>
    <i class="icon icon--xl icon-lock"></i>
  </div>
</div>

<ng-container *ngIf="form | async as formGroup">
  <div [formGroup]="formGroup">
    <ng-container *ngIf="paymentOptions?.directDebit">
      <ng-container
        *ngTemplateOutlet="
          directDebitOption;
          context: {
            $implicit: paymentOptions?.directDebit,
            cardPayment: paymentOptions?.card,
            formControl: formGroup.get('paymentOption')
          }
        "
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="paymentOptions?.card">
      <ng-container
        *ngTemplateOutlet="
          cardPaymentOption;
          context: {
            $implicit: paymentOptions?.card,
            formControl: formGroup.get('paymentOption')
          }
        "
      ></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template
  #directDebitOption
  let-paymentOption
  let-cardPayment="cardPayment"
  let-control="formControl"
>
  <div class="direct-debit-payment">
    <div class="direct-debit-payment__header">
      <div class="direct-debit-payment__title">Direct Debit</div>
      <div class="form-group__radio">
        <div class="radio">
          <label for="directDebitPaymentOption">
            <input
              type="radio"
              class="direct-debit-payment__button"
              [formControl]="control"
              [value]="paymentOption"
              name="paymentOption"
              id="directDebitPaymentOption"
            />
            <span class="icon icon-tick icon--md"></span>
          </label>
        </div>
      </div>
    </div>
    <span class="direct-debit-payment__seperator"></span>
    <div class="direct-debit-payment__content">
      <div
        class="direct-debit-payment__save-text"
        *ngIf="isDirectDebitCheaperThanCreditCard"
      >
        Save &pound;{{ discount | number: '1.2-2' }} offer
      </div>
      <div class="direct-debit-payment__payment-details">
        <div class="direct-debit-payment__payment-comparison">
          <div
            class="direct-debit-payment__card-payment-amount direct-debit-payment__card-payment-amount--cancel"
            *ngIf="isDirectDebitCheaperThanCreditCard"
          >
            &pound;{{ cardPayment.fee | number: '1.2-2' }}
          </div>
          <div class="direct-debit-payment__amount">
            &pound;{{ paymentOption.fee | number: '1.2-2' }}
          </div>
        </div>
        <div
          class="direct-debit-payment__payment-division typog-body-regular"
          *ngIf="isFirstPaymentAndSubsequentPaymentsSame"
        >
          12 monthly payments of
          <span class="typog-body-bold"
            >&pound;{{ paymentOption.firstPayment | number: '1.2-2' }}</span
          >
        </div>
        <div
          class="direct-debit-payment__payment-division typog-body-regular direct-debit-payment__payment-division--first-payment"
          *ngIf="!isFirstPaymentAndSubsequentPaymentsSame"
        >
          1st payment of
          <span class="typog-body-bold"
            >&pound;{{ paymentOption.firstPayment | number: '1.2-2' }}</span
          >
        </div>
        <div
          class="direct-debit-payment__payment-division typog-body-regular direct-debit-payment__payment-division--subsequent-payments"
          *ngIf="!isFirstPaymentAndSubsequentPaymentsSame"
        >
          then 11 monthly payments of
          <span class="typog-body-bold"
            >&pound;{{
              paymentOption.subsequentPayment | number: '1.2-2'
            }}</span
          >
        </div>
      </div>
      <div class="direct-debit-payment__logo">
        <i class="icon icon--lg icon-direct-debit-slim"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cardPaymentOption let-paymentOption let-control="formControl">
  <div class="card-payment">
    <div class="card-payment__header">
      <div class="card-payment__title">Credit/Debit Card</div>
      <div class="form-group__radio">
        <div class="radio">
          <label for="cardPaymentOption">
            <input
              type="radio"
              class="card-payment__button"
              [formControl]="control"
              [value]="paymentOption"
              name="paymentOption"
              id="cardPaymentOption"
            />
            <span class="icon icon-tick"></span>
          </label>
        </div>
      </div>
    </div>
    <span class="direct-debit-payment__seperator"></span>
    <div class="card-payment__content">
      <div class="card-payment__amount">
        &pound;{{ paymentOption.fee | number: '1.2-2' }}
      </div>
      <div class="card-payment__payment-division typog-body-regular">
        One-off payment
      </div>
    </div>
    <div class="card-payment__logo">
      <wl-payment-icons
        [showDirectDebitIcon]="false"
        [showCardIcons]="true"
      ></wl-payment-icons>
    </div>
  </div>
</ng-template>
