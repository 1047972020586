import { Action, ActionReducer } from '@ngrx/store';
import * as ResetStateActions from './reset-state.actions';

export function resetStateMetaReducer(
  reducer: ActionReducer<Record<string, unknown>>
) {
  return function (state: Record<string, unknown> | undefined, action: Action) {
    if (shouldReset(action)) {
      return reducer(undefined, action);
    }

    return reducer(state, action);
  };
}

const shouldReset = (action: Action) => {
  const resetActionTypes = [
    ResetStateActions.restartCheckoutGuest,
    ResetStateActions.restartCheckoutLoggedInUser,
    ResetStateActions.restartCheckoutBackButton,
  ];
  return (
    resetActionTypes.find((resetAction) => resetAction.type === action.type) !==
    undefined
  );
};
