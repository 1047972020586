export const APPLIANCE_DETAIL_FORM = {
  results: [
    {
      '@name': 'sales_applianceForm_appliance',
      '@path': '/sales_applianceForm_appliance',
      '@id': 'e814c818-4062-4547-b1fb-b1f1a679ac12',
      '@nodeType': 'form',
      formName: 'applianceForm',
      label: 'Select your appliance',
      controlName: 'appliance',
      placeholder: 'Start typing',
      jcrName: 'sales_applianceForm_appliance',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_appliance/validationMessages',
        '@id': '0b5f053a-fff0-4d2c-8ab9-05a324cc9864',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          '@path': '/sales_applianceForm_appliance/validationMessages/0',
          '@id': 'e6c29147-5756-47eb-93a8-132298cada8b',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Enter an appliance',
          '@nodes': [],
        },
        '1': {
          '@name': '1',
          '@path': '/sales_applianceForm_appliance/validationMessages/0',
          '@id': 'e6c29147-5756-47eb-93a8-132298cada8b',
          '@nodeType': 'mgnl:contentNode',
          type: 'invalid',
          message: 'No match found',
          '@nodes': [],
        },
        '@nodes': ['0', '1'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_applianceForm_brand',
      '@path': '/sales_applianceForm_brand',
      '@id': '680986ef-2499-49fd-a803-f7e20919d7c5',
      '@nodeType': 'form',
      formName: 'applianceForm',
      label: 'Select brand',
      controlName: 'brand',
      placeholder: 'Start typing',
      jcrName: 'sales_applianceForm_brand',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_brand/validationMessages',
        '@id': '210e89b5-8072-4756-aed3-216dba309b80',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          '@path': '/sales_applianceForm_brand/validationMessages/0',
          '@id': '9e59dc46-f609-4f6e-9328-a73ac5393ebb',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Enter a brand',
          '@nodes': [],
        },
        '1': {
          '@name': '1',
          '@path': '/sales_applianceForm_appliance/validationMessages/0',
          '@id': 'e6c29147-5756-47eb-93a8-132298cada8b',
          '@nodeType': 'mgnl:contentNode',
          type: 'invalid',
          message: 'No match found',
          '@nodes': [],
        },
        '@nodes': ['0', '1'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_applianceForm_underGuarantee',
      '@path': '/sales_applianceForm_underGuarantee',
      '@id': '03ff75e9-260f-43fe-a85e-52dd2464dcd0',
      '@nodeType': 'form',
      formName: 'applianceForm',
      label: 'Is it still under guarantee?',
      tooltip:
        'This policy offers immediate protection from accidental damage, during and after the manufacturer’s guarantee. Breakdown protection starts when the guarantee period ends. This includes guarantees offered by retailers, card providers, banks or manufacturers.',
      options: ['Yes', 'No'],
      controlName: 'underGuarantee',
      hint: "It may be under the manufacturer's or other providers' guarantee",
      jcrName: 'sales_applianceForm_underGuarantee',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_underGuarantee/validationMessages',
        '@id': '5ba7018f-d941-4b94-8ea0-2e18f1140881',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          '@path': '/sales_applianceForm_underGuarantee/validationMessages/0',
          '@id': '092c35ba-c1bb-4a71-9e40-3511da85e5c4',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Select one of the options',
          '@nodes': [],
        },
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_applianceForm_goodWorkingOrder',
      '@path': '/sales_applianceForm_goodWorkingOrder',
      '@id': 'b9990871-db6c-4dd7-8f05-c1a06983cc74',
      '@nodeType': 'form',
      formName: 'applianceForm',
      label: 'Is your appliance running as expected and in good working order?',
      controlName: 'goodWorkingOrder',
      tooltip:
        'Your product should be in good working order when you take out the protection policy. It should be functioning and can be used as expected.',
      options: ['Yes', 'No'],
      hint: 'If you answered yes, please continue.',
      jcrName: 'sales_applianceForm_goodWorkingOrder',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_goodWorkingOrder/validationMessages',
        '@id': '9b95801d-0bd0-495e-a128-f9f021691d7f',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          '@path': '/sales_applianceForm_goodWorkingOrder/validationMessages/0',
          '@id': '25499b14-52ee-48c7-adcf-68601b72b89c',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Select one of the options',
          '@nodes': [],
        },
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_applianceForm_purchaseDate',
      '@path': '/sales_applianceForm_purchaseDate',
      '@id': 'b9951c8a-0c35-4623-836f-79bfaa84ea89',
      '@nodeType': 'form',
      formName: 'applianceForm',
      label: 'When did you buy your appliance?',
      hint: 'An approximate will do',
      controlName: 'purchaseDate',
      jcrName: 'sales_applianceForm_purchaseDate',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_purchaseDate/validationMessages',
        '@id': '8bb9abea-4555-46b7-a8fa-4d53336521e2',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          '@path': '/sales_applianceForm_purchaseDate/validationMessages/0',
          '@id': 'a397ffe4-55e7-4220-bdab-fd610548d60b',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Purchase date is required',
          '@nodes': [],
        },
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_applianceForm_purchasePrice',
      '@path': '/sales_applianceForm_purchasePrice',
      '@id': '6b22dfee-7462-499d-89dd-937937ff8aa4',
      '@nodeType': 'form',
      formName: 'applianceForm',
      label: 'Purchase price',
      controlName: 'purchasePrice',
      placeholder: 'Enter approximate price',
      tooltip:
        'To get you an accurate quote, we need to know value of your appliance. From £10 up to £2999.',
      hint: 'Approximately, how much did it cost?',
      jcrName: 'sales_applianceForm_purchasePrice',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_purchasePrice/validationMessages',
        '@id': '9ed64d14-f794-48dc-8f2f-b1e15b2b549e',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          '@path': '/sales_applianceForm_purchasePrice/validationMessages/0',
          '@id': '20f10c46-325c-4479-a384-cd557833d60f',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Enter appliance cost',
          '@nodes': [],
        },
        '@nodes': ['0'],
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_applianceForm_getQuote',
      '@path': '/sales_applianceForm_getQuote',
      '@id': 'cbeaf6d0-1dc6-4414-9a32-6df7497a2e28',
      '@nodeType': 'form',
      formName: 'applianceForm',
      label: 'Get protection policy quote',
      controlName: 'getQuote',
      jcrName: 'sales_applianceForm_getQuote',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_getQuote/validationMessages',
        '@id': '187692d7-57f2-4beb-b7be-9e20f82f7f6f',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': [],
      },
      '@nodes': ['validationMessages'],
    },
  ],
};
