import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import {
  CheckboxToggleDef,
  ControlType,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'wl-terms-and-conditions-form',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsFormComponent
  implements OnChanges, AfterViewInit {
  field: CheckboxToggleDef = {
    controlType: ControlType.TOGGLE,
    controlName: 'isAccepted',
    initialValue: false,
    validators: [Validators.requiredTrue],
    validationMessages: [
      { type: 'required', message: 'You must Accept this!' },
    ],
  };
  public formGroup: FormGroup;

  @Input() isInsurance: boolean | null = false;
  @Input() validate = false;
  @Input() termsAndConditionsUrl: string | undefined = '';
  @Input() privacyPolicyUrl: string | undefined = '';
  @Input() ipidUrl: string | undefined = '';

  @Output()
  form: EventEmitter<FormGroup> = new EventEmitter();

  constructor() {
    this.formGroup = new FormGroup({
      isAccepted: new FormControl(false, Validators.requiredTrue),
    });
  }

  ngAfterViewInit() {
    //todo: what if it changes dynamically
    if (this.isInsurance) {
      this.form.emit(this.formGroup);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue === true) {
      this.formGroup.markAllAsTouched();
    }
  }
}
