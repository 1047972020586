import { createSelector } from '@ngrx/store';
import { PersonalDetailsSelectors } from '@common/data-access-personal-details';
import {
  UserProfilePageSelectors,
  UserProfilePartialState,
  UserProfileSelectors,
} from '@common/data-access-user-profile';
import {
  BasketItem,
  BasketItemApplianceDetails,
  CheckoutCompletedItem,
  CheckoutConfirmationData,
  CheckoutConfirmationTaggingData,
  CheckoutPlanDetails,
  Cover,
  CoverPaymentOptions,
  CoverType,
  getDigitalIdFromUser,
  ItemType,
  PaymentType,
  Quote,
  TaggingMetadata,
} from '@common/util-models';
import { getCheckedOutItems } from './checkout.selectors';
import { ApplianceDetailsSelectors } from '@common/data-access-appliance-details';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import {
  QuotesPageSelectors,
  QuotesPartialState,
  QuotesSelectors,
} from '@common/data-access-quotes';
import { CheckoutPartialState } from './checkout.reducer';
import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';
import { selectUser } from '@domgen/dgx-fe-auth';
import {
  CardPaymentPartialState,
  CardPaymentSelectors,
} from '@common/data-access-card-payment';
import { FeatureConfigPartialState } from '@common/data-access-feature-config';

export const getFirstCheckedOutItem = createSelector(
  getCheckedOutItems,
  (quotes: CheckoutCompletedItem[]) =>
    quotes.find((q) => q.item.itemType === ItemType.Quote)
);

export const getPersonalDetails = createSelector(
  UserProfilePageSelectors.getYourDetails,
  PersonalDetailsSelectors.getPersonalDetailsWithAddress,
  (loggedInUserDetails, guestUserDetails) =>
    loggedInUserDetails?.address?.addressLine1
      ? loggedInUserDetails
      : guestUserDetails
);

export const getPreferredPaymentDay = createSelector(
  PersonalDetailsSelectors.getPreferredPaymentDay,
  UserProfileSelectors.getPreferredPaymentDay,
  UserProfileSelectors.getPersonalDetails,
  (paymentDayGuest, paymentDayUser, userProfile) => {
    return userProfile !== undefined ? paymentDayUser : paymentDayGuest;
  }
);

export const getCheckoutConfirmation = createSelector<
  CheckoutPartialState &
    UserProfilePartialState &
    QuotesPartialState &
    SelectPaymentPartialState,
  CheckoutCompletedItem | undefined,
  PaymentType | undefined,
  CoverType | undefined,
  boolean | undefined,
  number | undefined,
  CoverPaymentOptions | undefined,
  CheckoutConfirmationData
>(
  getFirstCheckedOutItem,
  SelectPaymentSelectors.getSelectedPaymentType,
  QuotesSelectors.getSelectedCoverType,
  ApplianceDetailsSelectors.isHeatingAppliance,
  getPreferredPaymentDay,
  QuotesSelectors.getPaymentOptions,
  (
    checkoutData,
    paymentType,
    coverType,
    isHeating,
    preferredPaymentDay,
    paymentOptions
  ) => {
    const heading = isHeating
      ? 'Your maintenance and support plan'
      : 'Your appliance protection';
    const applianceDetails = checkoutData
      ? ({
          ...checkoutData.item.data.applianceDetails,
        } as BasketItemApplianceDetails)
      : undefined;

    return {
      ...checkoutData?.confirmation,
      ...applianceDetails,
      heading,
      paymentType,
      coverType,
      isHeating,
      preferredPaymentDay,
      itemQuoteDetails: checkoutData?.item.data.quotes[0],
      paymentOptions,
    } as CheckoutConfirmationData;
  }
);
export const getPlanDetails = createSelector<
  CheckoutPartialState &
    UserProfilePartialState &
    QuotesPartialState &
    SelectPaymentPartialState,
  CheckoutCompletedItem | undefined,
  Cover | undefined,
  number | undefined,
  PaymentType | undefined,
  CheckoutPlanDetails
>(
  getFirstCheckedOutItem,
  QuotesSelectors.getSelectedCover,
  getPreferredPaymentDay,
  SelectPaymentSelectors.getSelectedPaymentType,
  (checkedOutItem, cover, paymentDay, paymentType) => {
    const quote = checkedOutItem?.item.data.quotes.find(
      (q) => q.quoteId === cover?.quoteId
    );
    const paymentOption = quote?.paymentOptions.find(
      (paymentOption) => paymentOption.paymentType === paymentType
    );
    const confirmation = checkedOutItem?.confirmation;
    const applianceDetails = checkedOutItem?.item.data.applianceDetails;
    return {
      paymentDay,
      paymentOption,
      confirmation,
      applianceDetails,
    };
  }
);

const getIsHeatingAndCardNumber = createSelector(
  ApplianceDetailsSelectors.isHeatingAppliance,
  CardPaymentSelectors.getCardPaymentOrderNumber,
  (isHeating, cardNumber) => ({ isHeating, cardNumber })
);

export const getPageLandingTaggingData = createSelector<
  CheckoutPartialState &
    QuotesPartialState &
    SelectPaymentPartialState &
    CardPaymentPartialState &
    FeatureConfigPartialState,
  TaggingMetadata | undefined,
  BasketItem | undefined,
  Quote | undefined,
  string,
  User | undefined,
  PaymentType | undefined,
  { isHeating: boolean; cardNumber: string | undefined },
  CheckoutCompletedItem | undefined,
  CheckoutConfirmationTaggingData
>(
  QuotesPageSelectors.getTaggingMetadata,
  QuotesSelectors.getFirstItemQuote,
  QuotesPageSelectors.getQuote,
  QuotesSelectors.getSelectedCoverName,
  selectUser,
  SelectPaymentSelectors.getSelectedPaymentType,
  getIsHeatingAndCardNumber,
  getFirstCheckedOutItem,
  (
    taggingMetadata,
    basketItem,
    quote,
    coverName,
    user,
    paymentType,
    { isHeating, cardNumber },
    checkoutItem
  ) => {
    const paymentOptions = quote?.cover.paymentOptions;
    const digitalId = getDigitalIdFromUser(user);

    return {
      ...taggingMetadata,
      user,
      coverName,
      paymentType,
      basketId: basketItem?.basketId,
      fee:
        paymentType === PaymentType.Card
          ? paymentOptions?.card?.fee
          : paymentOptions?.directDebit?.subsequentPayment,
      planNumber: checkoutItem?.confirmation.planNumber,
      digitalId,
      isHeating,
      cardNumber,
    } as CheckoutConfirmationTaggingData;
  }
);
