import { Action, createReducer, on } from '@ngrx/store';
import {
  DirectDebit,
  DirectDebitDetails,
  Person,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';
import * as UserProfileActions from './user-profile.actions';
import { SelectPaymentActions } from '@common/data-access-select-payment';
import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';

export const USERPROFILE_FEATURE_KEY = 'loggedInUserProfile';

export interface UserProfileState {
  remoteStateDirectDebitPersonalDetails: RemoteData<null>;
  person?: Person;
  directDebitDetails?: DirectDebitDetails;
  newDirectDebitDetails?: DirectDebit | null;
  paperlessPlanDocuments: boolean | undefined;
  newPaymentDay?: number | null;
}

export interface UserProfilePartialState {
  readonly [USERPROFILE_FEATURE_KEY]: UserProfileState;
}

export const initialState: UserProfileState = {
  remoteStateDirectDebitPersonalDetails: remoteDataNotFetched(),
  person: undefined,
  directDebitDetails: undefined,
  newDirectDebitDetails: undefined,
  paperlessPlanDocuments: undefined,
  newPaymentDay: undefined,
};

function getUpdatedPerson(person: Person, user: User): Person {
  let newPerson: Person = { ...person };
  if (!newPerson.contact.email) {
    newPerson = {
      ...newPerson,
      contact: { ...newPerson.contact, email: user.email },
    };
  }
  if (!newPerson.personalDetails.firstName) {
    newPerson = {
      ...newPerson,
      personalDetails: {
        ...newPerson.personalDetails,
        firstName: user.name,
      },
    };
  }
  if (!newPerson.personalDetails.surname) {
    newPerson = {
      ...newPerson,
      personalDetails: {
        ...newPerson.personalDetails,
        surname: user.family_name,
      },
    };
  }

  return newPerson;
}

const userProfileReducer = createReducer(
  initialState,
  on(UserProfileActions.loadPersonalAndDirectDebitDetails, (state) => ({
    ...state,
    remoteStateDirectDebitPersonalDetails: remoteDataLoading(),
  })),
  on(
    UserProfileActions.loadPersonalAndDirectDebitDetailsSuccess,
    (state, { person, directDebitDetails, selectUser }) => {
      const updatedPerson = getUpdatedPerson(person, selectUser);
      return {
        ...state,
        remoteStateDirectDebitPersonalDetails: remoteDataOK(null),
        person: updatedPerson,
        directDebitDetails,
      };
    }
  ),
  on(
    UserProfileActions.loadPersonalAndDirectDebitDetailsFailure,
    (state, { error }) => ({
      ...state,
      remoteStateDirectDebitPersonalDetails: remoteDataError(error),
      person: undefined,
      directDebitDetails: undefined,
    })
  ),
  on(
    UserProfileActions.updateDirectDebit,
    (state, { newDirectDebitDetails }) => {
      return {
        ...state,
        newDirectDebitDetails,
      };
    }
  ),
  on(
    UserProfileActions.updatePlanDocumentPreference,
    SelectPaymentActions.updatePlanDocumentPreferences,
    (state, { paperless: paperlessPlanDocuments }) => {
      return {
        ...state,
        paperlessPlanDocuments,
      };
    }
  ),
  on(
    UserProfileActions.changePaymentDay,
    (state, { paymentDay: newPaymentDay }) => {
      return {
        ...state,
        newPaymentDay,
      } as UserProfileState;
    }
  )
);

export function reducer(state: UserProfileState | undefined, action: Action) {
  return userProfileReducer(state, action);
}
