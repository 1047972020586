import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  ApplianceFormField,
  ApplianceFormFields,
  ApplianceFormSubmitNoCode,
} from '@common/util-models';
import {
  ControlType,
  CustomValidatorService,
  MonthYearDateDef,
  YearDateDef,
  SelectDef,
  SubmitDef,
  TextInputDef,
  TypeAheadDef,
  YesNoDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable } from 'rxjs';
import {
  getFormField,
  getValidationMessages,
} from '@domgen/dgx-fe-business-components';
import { validTypeAheadSelection } from '../validators/valid-typeahead-selection.validator';

@Injectable()
export class ApplianceDetailsFormConfigService {
  constructor(private customValidatorService: CustomValidatorService) {}

  getFormbuilderConfig(
    cmsFormData: ApplianceFormFields,
    appliancesOptions$: Observable<string[]>,
    brandsOptions$: Observable<string[]>,
    controlTypes?: Partial<Record<ApplianceFormField, string>>,
    maxAge?: number | undefined,
    prefilledForm?: ApplianceFormSubmitNoCode | undefined,
    disableBrandSelection$?: Observable<boolean>
  ) {
    return [
      this.applianceInputDef(
        cmsFormData,
        appliancesOptions$,
        controlTypes,
        prefilledForm?.appliance
      ),
      this.brandInputDef(
        cmsFormData,
        brandsOptions$,
        controlTypes,
        prefilledForm?.brand,
        disableBrandSelection$
      ),
      this.workingOrderInputDef(cmsFormData, prefilledForm?.goodWorkingOrder),
      this.purchasePriceInputDef(cmsFormData, prefilledForm?.purchasePrice),
      this.monthYearDateDef(
        cmsFormData,
        controlTypes,
        maxAge,
        prefilledForm?.monthYearDate
      ),
      this.manufacturerGuaranteeInputDef(
        cmsFormData,
        prefilledForm?.underGuarantee
      ),
      this.submitButtonDef(cmsFormData),
    ];
  }

  private applianceInputDef(
    applianceCmsData: ApplianceFormFields,
    appliancesOptions$: Observable<string[]>,
    controlTypes?: Partial<Record<ApplianceFormField, string>>,
    initialValue?: string
  ): SelectDef | TypeAheadDef {
    const applianceFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.Appliance
    );

    const applianceInput = {
      controlName: applianceFormField.controlName,
      validators: [Validators.required],
      validationMessages: getValidationMessages(applianceFormField),
      hint: applianceFormField.hint,
      label: { text: applianceFormField.label },
      placeholder: applianceFormField.placeholder,
      tooltip: applianceFormField.tooltip,
      optionsStream$: appliancesOptions$,
      initialValue,
    };

    if (controlTypes?.[ApplianceFormField.Appliance] === ControlType.SELECT) {
      return {
        ...applianceInput,
        controlType: ControlType.SELECT,
      };
    } else {
      return {
        ...applianceInput,
        controlType: ControlType.TYPEAHEAD,
        startSearchFromCharacter: 1,
        asyncValidators: [validTypeAheadSelection(appliancesOptions$)],
      };
    }
  }

  private brandInputDef(
    applianceCmsData: ApplianceFormFields,
    brandsOptions$: Observable<string[]>,
    controlTypes?: Partial<Record<ApplianceFormField, string>>,
    initialValue?: string,
    disableBrandSelection$?: Observable<boolean>
  ): SelectDef | TypeAheadDef {
    const brandFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.Brand
    );

    const brandInput = {
      controlName: brandFormField.controlName,
      validators: [Validators.required],
      validationMessages: getValidationMessages(brandFormField),
      label: { text: brandFormField.label },
      placeholder: brandFormField.placeholder,
      hint: brandFormField.hint,
      tooltip: brandFormField.tooltip,
      optionsStream$: brandsOptions$,
      initialValue,
    };

    if (controlTypes?.[ApplianceFormField.Brand] === ControlType.SELECT) {
      return {
        ...brandInput,
        disabledStream$: disableBrandSelection$,
        controlType: ControlType.SELECT,
      };
    } else {
      return {
        ...brandInput,
        controlType: ControlType.TYPEAHEAD,
        startSearchFromCharacter: 1,
        asyncValidators: [validTypeAheadSelection(brandsOptions$)],
      };
    }
  }

  private workingOrderInputDef(
    applianceCmsData: ApplianceFormFields,
    initialValue?: string
  ): YesNoDef {
    const workingOrderFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.GoodWorkingOrder
    );

    return {
      controlType: ControlType.YESNO,
      controlName: workingOrderFormField.controlName,
      label: { text: workingOrderFormField.label },
      validators: [Validators.required],
      validationMessages: getValidationMessages(workingOrderFormField),
      options: ['Yes', 'No'],
      hint: workingOrderFormField.hint,
      tooltip: workingOrderFormField.tooltip,
      initialValue,
    };
  }

  private purchasePriceInputDef(
    applianceCmsData: ApplianceFormFields,
    initialValue?: string
  ): TextInputDef {
    const purchasePriceFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.PurchasePrice
    );

    const message = 'The purchase price should be between £10 to £2,999';
    return {
      controlType: ControlType.INPUT,
      controlName: purchasePriceFormField.controlName,
      validators: [
        Validators.required,
        Validators.min(10),
        Validators.max(2999),
        Validators.pattern(/^\d+(\.\d+)?$/),
      ],
      label: { text: purchasePriceFormField.label },
      validationMessages: [
        ...getValidationMessages(purchasePriceFormField),
        {
          type: 'min',
          message,
        },
        {
          type: 'max',
          message,
        },
        {
          type: 'pattern',
          message,
        },
      ],
      placeholder: purchasePriceFormField.placeholder,
      hint: purchasePriceFormField.hint,
      tooltip: purchasePriceFormField.tooltip,
      type: 'number',
      initialValue,
    };
  }

  private manufacturerGuaranteeInputDef(
    applianceCmsData: ApplianceFormFields,
    initialValue?: string
  ): YesNoDef {
    const manufacturerGuaranteeFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.UnderGuarantee
    );

    return {
      controlType: ControlType.YESNO,
      controlName: manufacturerGuaranteeFormField.controlName,
      label: { text: manufacturerGuaranteeFormField.label },
      validators: [Validators.required],
      validationMessages: getValidationMessages(manufacturerGuaranteeFormField),
      options: ['Yes', 'No'],
      hint: manufacturerGuaranteeFormField.hint,
      tooltip: manufacturerGuaranteeFormField.tooltip,
      initialValue,
    };
  }

  private monthYearDateDef(
    applianceCmsData: ApplianceFormFields,
    controlTypes?: Partial<Record<ApplianceFormField, string>>,
    maxAge?: number | undefined,
    initialValue?: { month: number; year: number }
  ): MonthYearDateDef | YearDateDef {
    const maxAgevalue = maxAge ? maxAge + 1 : 20;
    const purchaseDateFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.PurchaseDate
    );

    const yearOptions = (() => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      // Start with the current year and go back to the minimum year a cover can be provided for
      const years = Array(maxAgevalue)
        .fill(0)
        .map((element, index) => currentYear - index);

      // adjust years array if necessary so that only years up to max age (8 years 11 months ago) are shown
      if (currentDate.getMonth() === 0) {
        years.pop();
      }

      return years;
    })();
    const monthYearDateInput = {
      controlName: 'monthYearDate',
      validators: [
        Validators.required,
        this.customValidatorService.applianceAgeValidator(
          maxAge ? maxAge * 12 : null
        ),
        this.customValidatorService.partialDateValidator,
      ],
      validationMessages: [
        ...getValidationMessages(purchaseDateFormField),
        { type: 'required', message: 'Enter the purchase date' },
        { type: 'yearNotSelected', message: 'Select a year' },
        {
          type: 'applianceTooOld',
          message: `The age of your appliance should be less than ${
            maxAge ? maxAge : 8
          } years old`,
        },
        {
          type: 'applianceTooNew',
          message:
            'Enter the purchase date. This date must be today or in the past.',
        },
      ],
      //todo: remove hardcoded copy (used for sales)
      label: {
        text:
          purchaseDateFormField?.label || 'When did you buy your appliance?',
      },
      yearLabel: { text: 'Year' },
      //todo: remove hardcoded copy (used for sales)
      hint:
        purchaseDateFormField?.hint || 'Select an approximate purchase date',
      tooltip: purchaseDateFormField?.tooltip,
    };

    if (
      controlTypes?.[ApplianceFormField.PurchaseDate] === ControlType.YEARDATE
    ) {
      const currentMonth = new Date().getMonth() + 1;
      const lastAvailableYear = yearOptions[yearOptions.length - 1];
      return {
        ...monthYearDateInput,
        controlType: ControlType.YEARDATE,
        values: yearOptions
          .map((yearLabel, index, years) => {
            let month,
              year = yearLabel;
            if (index === 0) {
              //bought this year, use january - will always mean its less than a year old, but will never be in future
              month = 1;
            } else if (index === 1) {
              //bought last year, we need to use 11 months ago for value
              if (currentMonth === 1) {
                month = 12;
                year = yearLabel - 1;
              } else {
                month = currentMonth - 1;
              }
            } else if (index === years.length - 1) {
              //bought in last year of allowed age, use december so that it is always within the window
              month = 12;
            } else {
              //default to current month so age will effectively be in whole years
              month = currentMonth;
            }
            return {
              label: yearLabel.toString(),
              month,
              year,
            };
          })
          .concat({
            label: `before ${lastAvailableYear}`,
            year: lastAvailableYear - 1,
            month: currentMonth,
          }),
        initialValue,
      };
    } else {
      return {
        ...monthYearDateInput,
        controlType: ControlType.MONTHYEARDATE,
        monthLabel: { text: 'Month' },
        monthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        yearOptions: yearOptions,
        validationMessages: [
          ...monthYearDateInput.validationMessages,
          { type: 'monthNotSelected', message: 'Select a month' },
        ],
        initialValue,
      };
    }
  }

  private submitButtonDef(applianceCmsData: ApplianceFormFields): SubmitDef {
    const getQuoteFormField = getFormField(
      applianceCmsData,
      ApplianceFormField.GetQuote
    );

    return {
      controlType: ControlType.SUBMIT,
      label: { text: getQuoteFormField.label },
      classes: 'btn btn--primary',
      excludeFromFormGroup: true,
      disabled: true,
    };
  }
}
