<div class="help text--center">
  <h3>Still looking around?</h3>
  <h2>Here are some links that you may find helpful:</h2>
  <div class="help__links">
    <div class="help__link">
      <a href="https://www.domesticandgeneral.com" target="_blank" class="link">Home</a>
    </div>
    <div class="help__link">
      <a
        href="https://www.domesticandgeneral.com/content/help-advice-section/faq"
        target="_blank" class="link"
        >Help and advice</a
      >
    </div>
    <div class="help__link">
      <a
        href="https://www.domesticandgeneral.com/shop/en/dg/content/contact-domestic-general"
        target="_blank" class="link"
        >Contact us</a
      >
    </div>
  </div>
</div>
