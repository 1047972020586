<ng-container *ngIf="vm$ | async as vm">
  <div class="form-panel">
    <dgx-dfb-form
      [fieldset]="vm.fieldDef"
      [form]="vm.formGroup"
      [controlsToHide]="vm.formControlsToHide"
      (validatedSubmit)="handleValidFormSubmission($any($event))"
      (invalidSubmit)="handleInvalidFormSubmission($event)"
      (valueChanges)="handleValueChanges($event)"
      (analytics)="handleAnalytics($event)"
    >
    </dgx-dfb-form>
    <div
      class="form__offer-notice"
      *ngIf="
        vm.applianceNotWorking ||
        vm.inWarrantyStopSubmission ||
        vm.inWarrantyByDateStopSubmission
      "
    >
      <dgx-cms-dynamic-component-outlet
        area="not_good_order"
        *ngIf="vm.applianceNotWorking"
      >
      </dgx-cms-dynamic-component-outlet>
      <!-- TODO - Once CMS has configuration for "boiler guarantee" and we should remove the below hard-coded div block-->
      <!-- <dgx-cms-dynamic-component-outlet area="stop_in_warranty" *ngIf="vm.inWarrantyStopSubmission || vm.inWarrantyByDateStopSubmission">
      </dgx-cms-dynamic-component-outlet> -->
      <div
        area="stop_in_warranty"
        class="boiler-guarantee"
        *ngIf="
          (vm.inWarrantyStopSubmission || vm.inWarrantyByDateStopSubmission) &&
          !vm.applianceNotWorking
        "
      >
        <h4 class="heading">Your boiler is still under guarantee</h4>
        <p>
          Give us a call so we can offer you a maintenance and support plan that
          suits you:
        </p>
        <h4 class="phone">0800 561 4493</h4>
        <p>Choose Option 2 then Option 1</p>
        <br />
        <p>We're available:</p>
        <p>8am to 8pm Monday - Friday</p>
        <p>9am to 5pm Saturday - Sunday</p>
      </div>
    </div>
  </div>
</ng-container>
