import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@common/ui-sales-components';
import { CmsPage } from '@common/util-models';

const routes: Routes = [
  //todo: what should the default page actually be?
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/offer',
  },
  { path: 'error', data: { title: 'Error' }, component: ErrorComponent },
  {
    path: 'offer',
    loadChildren: () =>
      import('@oren/feature-landing').then((m) => m.OrenFeatureLandingModule),
    data: {
      cmsDataPage: CmsPage.OrenLandingOffer,
    },
  },
  {
    path: 'renewal',
    loadChildren: () =>
      import('@oren/feature-landing').then((m) => m.OrenFeatureLandingModule),
    data: {
      cmsDataPage: CmsPage.OrenLandingRenewal,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class OrenFeatureShellRoutingModule {} // Main routes for application
