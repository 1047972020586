import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  CmsFormField,
  PlanDocumentsFormField,
} from '@domgen/dgx-fe-business-models';
import {
  CheckboxToggleDef,
  ControlType,
  FieldDef,
  YesNoDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import * as CmsHelpers from '../cms-form-helper/cms-form-helpers';

@Injectable()
export class PlanDocumentsFormConfigService {
  public getFormbuilderConfig(
    cmsFormData: CmsFormField[],
    prefilledForm?: boolean | null
  ): FieldDef[] {
    return [
      this.getInputDefByFieldName(
        cmsFormData,
        PlanDocumentsFormField.Paperless,
        prefilledForm
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        PlanDocumentsFormField.TermsAndConditions,
        typeof prefilledForm === 'boolean' ? true : null //ToDo: Need to set intialValue using correct way (Like using CompletedStep)
      ),
    ] as FieldDef[];
  }

  public getOnlyPlanDocumentFormConfig(
    cmsFormData: CmsFormField[],
    prefilledForm?: boolean | null,
    displayAs?: 'checkbox' | 'buttons'
  ): FieldDef[] {
    return [
      this.getInputDefByFieldName(
        cmsFormData,
        PlanDocumentsFormField.Paperless,
        prefilledForm,
        displayAs
      ),
    ] as FieldDef[];
  }

  private getInputDefByFieldName(
    cmsFormData: CmsFormField[],
    formField: PlanDocumentsFormField,
    initialValue: boolean | null | undefined,
    displayAs: 'checkbox' | 'buttons' = 'buttons'
  ): FieldDef {
    let fieldDef: FieldDef;
    const cmsFormField = CmsHelpers.getFormField<CmsFormField>(
      cmsFormData,
      formField
    );
    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      formField
    );

    switch (formField) {
      case PlanDocumentsFormField.Paperless:
        if (displayAs === 'checkbox') {
          fieldDef = {
            ...defaultFieldDef,
            controlType: ControlType.CHECKBOX,
            initialValue: initialValue ? true : false,
            label: {
              text: 'Yes, post a copy of my documents',
            },
          } as CheckboxToggleDef;
        } else {
          fieldDef = {
            ...defaultFieldDef,
            controlType: ControlType.YESNO,
            validators: [Validators.required],
            initialValue:
              typeof initialValue === 'boolean'
                ? initialValue
                  ? 'Yes'
                  : 'No'
                : '',
            options: cmsFormField.options
              ? cmsFormField.options
              : ['Yes', 'No'],
            message: cmsFormField.message
              ? cmsFormField.message
              : "Going paperless means there's less post to deal with, file or recycle.",
          } as YesNoDef;
        }
        break;
      case PlanDocumentsFormField.TermsAndConditions:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.ACCEPT,
          initialValue: initialValue ? true : false,
          validators: [Validators.requiredTrue],
        } as CheckboxToggleDef;
        break;
      default:
        fieldDef = {
          ...defaultFieldDef,
        } as FieldDef;
        break;
    }

    return fieldDef;
  }
}
