import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RadioListItemValue } from '@domgen/dgx-fe-base-components';
import { Payment } from '../payment-card/payment-card.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  BasketItemPaymentOption,
  CoverPaymentOptions,
  PaymentType,
} from '@common/util-models';

const titleMap = {
  [PaymentType.DirectDebit]: 'Direct Debit',
  [PaymentType.Card]: 'Credit or debit card',
};

@Component({
  selector: 'wl-payment-method-selection',
  templateUrl: './payment-method-selection.component.html',
  styleUrls: ['./payment-method-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PaymentMethodSelectionComponent,
    },
  ],
})
export class PaymentMethodSelectionComponent
  implements ControlValueAccessor, OnChanges {
  @Input() selected: RadioListItemValue = null;
  @Input() availablePaymentMethod!: CoverPaymentOptions | null;
  @Output()
  paymentMethodChange: EventEmitter<RadioListItemValue> = new EventEmitter();
  productOptionArray: Payment[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.availablePaymentMethod) {
      if (this.availablePaymentMethod) {
        this.mapToPaymentCardObject(this.availablePaymentMethod);
      }
    }
  }

  onTouched: () => void = () => {
    return undefined;
  };

  onChanged: (val: unknown) => void = () => {
    return undefined;
  };

  onSelectionChange(val: RadioListItemValue) {
    if (this.selected !== val) {
      this.onChanged(val);
    }
    this.selected = val;
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: RadioListItemValue) {
    if (this.selected !== val) {
      this.paymentMethodChange.emit(val);
    }
    this.selected = val;
  }

  mapToPaymentCardObject(productOption: CoverPaymentOptions) {
    const productOptionArray = Object.values(productOption);
    this.selected = productOptionArray.find(
      (e) => e.preferredPayment
    )?.paymentType;

    this.productOptionArray = productOptionArray
      .map((option: BasketItemPaymentOption) => {
        return {
          id: option.paymentType,
          paymentType: option.paymentType,
          title: titleMap[option.paymentType],
          perMonthPrice: option.subsequentPayment,
          totalPrice: option.fee,
          preferredPayment: option.preferredPayment,
          directDebitDiscount: option?.directDebitDiscount,
        };
      })
      //Direct Debit first
      .sort(({ paymentType: a }, { paymentType: b }) => {
        if (a === b) {
          return 0;
        }
        if (a === PaymentType.DirectDebit) {
          return -1;
        }
        return 1;
      });
  }
}
