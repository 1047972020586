<sales-loader [@fadeInOut] *ngIf="loading$ | async"></sales-loader>
<sales-header [links]="headerLinks"></sales-header>
<main>
  <ui-progress-tracker-route-based
    [steps]="progressSteps$ | async"
    [currentRoute]="currentRoute"
  ></ui-progress-tracker-route-based>
  <router-outlet></router-outlet>
</main>
<sales-footer></sales-footer>
