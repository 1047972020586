<ng-container *ngIf="upgradableCovers">
  <div class="upgrade-plan-container">
    <div class="choose-your-plan">
      <h3 class="text--center justify--center">
        Choose your maintenance and support plan
      </h3>

      <div class="monthly fix-height">
        <h5 class="flex">
          <span class="xs-10 m-0">
            {{ upgradableCovers?.base?.name && upgradableCovers?.base?.name }}
            (Default)
          </span>
          <em class="accept-tick xs-2 icon icon--lg icon-large-tick"></em>
        </h5>
      </div>

      <div class="monthly">
        <div class="flex line-gap">
          <h5 class="inline-flex highlight">
            {{
              upgradableCovers?.base?.paymentOptions?.directDebit
                ?.preferredPayment
                ? (upgradableCovers?.base?.paymentOptions?.directDebit
                    ?.subsequentPayment | currency: 'GBP')
                : (upgradableCovers?.base?.paymentOptions?.card
                    ?.subsequentPayment | currency: 'GBP')
            }}
          </h5>
          <p class="inline-flex">per month</p>
        </div>
        <div class="flex">
          <p class="inline-flex">
            {{ upgradableCovers?.base?.periodOfCover }}
            months for
          </p>
          <h5 class="inline-flex annualTotal">
            {{
              upgradableCovers?.base?.paymentOptions?.directDebit
                ?.preferredPayment
                ? (upgradableCovers?.base?.paymentOptions?.directDebit?.fee
                  | currency: 'GBP')
                : (upgradableCovers?.base?.paymentOptions?.card?.fee
                  | currency: 'GBP')
            }}
          </h5>
        </div>
      </div>

      <div class="choose-your-plan-desc flex">
        <em class="xs-2">
          <img
            src="./assets/images/catalogue-icons/boiler.svg"
            width="30px"
            height="30px"
          />
        </em>
        <p>
          Our Gold plan includes an annual boiler service, as well as ongoing
          maintenance and support to ensure it runs smoothly for as long as
          possible.
        </p>
      </div>
    </div>

    <div class="upgrade-to">
      <h3 class="text--center justify--center">Upgrade to</h3>
      <h5 class="toggleCoverType align--start flex fix-height">
        <div class="xs-10 p-0 m-0 upgrade-to-title">
          {{
            upgradableCovers?.upgrade?.name && upgradableCovers?.upgrade?.name
          }}
          {{ hasChecked ? 'selected' : '' }}
        </div>
        <div class="xs toggle">
          <label class="switch">
            <input
              type="checkbox"
              (click)="
                toggleUpgradable(
                  !hasChecked
                    ? upgradableCovers?.upgrade?.coverType
                    : upgradableCovers?.base?.coverType
                )
              "
            />
            <span class="slider round" [class.active]="hasChecked"></span>
          </label>
        </div>
      </h5>

      <h5
        class="flex highlight pl-1 line-gap"
        *ngIf="excessAmountPerMonth(upgradableCovers) as excessTotal"
      >
        Extra {{ excessTotal | currency: 'GBP' }} per month
      </h5>

      <div class="upgrade-to-desc flex">
        <em class="xs-2">
          <img
            src="./assets/images/catalogue-icons/radiator.svg"
            width="28px"
            height="28px"
          />
        </em>

        <p>
          Expect all the benefits of our
          {{ upgradableCovers?.base?.name }} plan, plus extra help to look after
          your central heating (including radiators and exposed pipes).
        </p>
      </div>

      <div class="monthly">
        <div class="flex line-gap">
          <h5 class="inline-flex highlight">
            {{
              upgradableCovers?.upgrade?.paymentOptions?.directDebit
                ?.preferredPayment
                ? (upgradableCovers?.upgrade?.paymentOptions?.directDebit
                    ?.subsequentPayment | currency: 'GBP')
                : (upgradableCovers?.upgrade?.paymentOptions?.card
                    ?.subsequentPayment | currency: 'GBP')
            }}
          </h5>
          <p class="inline-flex">per month</p>
        </div>
        <div class="flex">
          <p class="inline-flex">
            {{ upgradableCovers?.upgrade?.periodOfCover }}
            months for
          </p>
          <h5 class="inline-flex annualTotal">
            {{
              upgradableCovers?.upgrade?.paymentOptions?.directDebit
                ?.preferredPayment
                ? (upgradableCovers?.upgrade?.paymentOptions?.directDebit?.fee
                  | currency: 'GBP')
                : (upgradableCovers?.upgrade?.paymentOptions?.card?.fee
                  | currency: 'GBP')
            }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</ng-container>
